import { useEffect } from 'react';

export const useLocalSettings = (
  pageIndex: string,
  order: any,
  orderBy: any,
  orderId: any,
  activeFilters: any
) => {
  useEffect(() => {
    let pagesVisited = JSON.parse(localStorage.getItem('pagesVisited') || '""');
    if (pagesVisited.length === 0) {
      pagesVisited = [pageIndex];
    } else if (pagesVisited.indexOf(pageIndex) === -1) {
      pagesVisited.push(pageIndex);
    } else {
      return;
    }
    localStorage.setItem('pagesVisited', JSON.stringify(pagesVisited));
  }, []);

  useEffect(() => {
    const localSort = {
      order,
      orderBy,
      orderId,
    };
    localStorage.setItem(`${pageIndex} Sort`, JSON.stringify(localSort));
  }, [order, orderBy, orderId]);

  useEffect(() => {
    if (activeFilters) {
      localStorage.setItem(
        `${pageIndex} Filters`,
        JSON.stringify(activeFilters)
      );
    } else {
      localStorage.removeItem(`${pageIndex} Filters`);
    }
  }, [activeFilters]);
};

export default useLocalSettings;
