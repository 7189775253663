import { Typography } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import React from 'react';

interface Props extends WithStyles<typeof styles> {
  data: any[] | null;
  dataMessage: string;
  error?: string | null;
  errorMessage?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 100,
    },
    message: {
      opacity: 0.65,
    },
  });

const NoData = ({ classes, data, dataMessage, error, errorMessage }: Props) => {
  let message: string | null = null;
  if (!data || data.length <= 0) message = dataMessage;
  if (error) message = errorMessage || message;

  if (!message) return null;

  return (
    <div className={classes.container}>
      <Typography variant="body1" component="h5" className={classes.message}>
        {message}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(NoData);
