import { Button, Grid, Paper, Typography, WithStyles } from '@material-ui/core';
import { default as React, Dispatch, SetStateAction, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '@/screens/DiscoverySeeds/styles';
import TableWithCards from '@/templates/TableWithCards';
import DeleteIcon from '@material-ui/icons/Delete';
import { DataFormatProps } from '@/components/TableVirtualized';

interface Props extends WithStyles<typeof styles> {
  seedData: any[];
  handleSubmit: () => void;
  setSeedData: Dispatch<SetStateAction<any>>;
  columns: DataFormatProps[];
  title: string;
}

const SeedTable = ({
  seedData,
  handleSubmit,
  setSeedData,
  columns,
  title,
  classes,
}: Props) => {
  const [selectedRows, setSelectedRows] = useState<String[]>();

  const tableHeader = (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs>
        <Typography variant="h6" align="left">
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs
        alignItems="flex-start"
        justify="flex-end"
        direction="row"
        className={classes.seedFormItem}
      >
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );

  const handleRemove = () => {
    if (!selectedRows) return;

    selectedRows.forEach(r => {
      const i = seedData.findIndex(s => JSON.stringify(s) === r);
      if (i !== -1) {
        seedData.splice(i, 1);
      }
    });

    setSeedData([...seedData]);
  };

  const removeButton = (
    <Button
      onClick={handleRemove}
      color="primary"
      startIcon={<DeleteIcon />}
      variant="contained"
    >
      Remove
    </Button>
  );

  if (!seedData?.length) {
    return <></>;
  }

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="stretch"
      className={classes.seedTable}
    >
      <Paper elevation={1} className={classes.seedTableInner}>
        <TableWithCards
          data={seedData.map(s => {
            return { ...s, uid: JSON.stringify(s) };
          })}
          columns={columns}
          pageTitle={title}
          testId={'seedDataTable'}
          tableTitleOverride={tableHeader}
          HeaderButtons={removeButton}
          multiSelect={true}
          rowsToUpdate={rows => setSelectedRows(rows)}
        />
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(SeedTable);
