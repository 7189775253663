import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import {
  IndicatorType,
  MostQuestions,
  QuestionContext,
  TypeDisplay,
} from '@/types/discovery';
import SearchInput from '@/components/SearchInput';
import ReactivateButton from '@/components/discovery/ReactivateButton';
import { default as React } from 'react';
import { styles } from '@/components/discovery/QuestionList/styles';

interface Props extends WithStyles<typeof styles> {
  inputName?: string;
  inputType?: string;

  discoveryErrors?: boolean;

  setSelectedIndicator: (indicator: QuestionContext | undefined) => void;
  reactivate: (completed: () => void) => void;
  handleSubmitAnswers: () => void;
  setSearch: (search: string) => void;
  openFilters: () => void;
  showTypeSelect?: boolean;
}

const QuestionControls = ({
  classes,
  inputName,
  inputType,
  setSelectedIndicator,
  reactivate,
  discoveryErrors,
  handleSubmitAnswers,
  setSearch,
  openFilters,
  showTypeSelect,
}: Props) => {
  const typeSelect = (
    <TextField
      id="filled-select-type"
      select
      value={inputType || MostQuestions.mostQuestions}
      variant="outlined"
      size="small"
      onChange={event => {
        setSelectedIndicator({
          indicatorType: event.target.value as IndicatorType | MostQuestions,
        });
      }}
      inputProps={{ 'data-testid': 'seed-input-type' }}
      fullWidth
    >
      <MenuItem
        key={MostQuestions.mostQuestions}
        value={MostQuestions.mostQuestions}
      >
        {TypeDisplay[MostQuestions.mostQuestions]}
      </MenuItem>
      {Object.keys(IndicatorType)
        .filter(t => IndicatorType[t] !== IndicatorType.custom)
        .map(t => {
          const indicatorType = IndicatorType[t];
          return (
            <MenuItem key={indicatorType} value={indicatorType}>
              {TypeDisplay[indicatorType]}
            </MenuItem>
          );
        })}
    </TextField>
  );

  return (
    <Grid container alignItems="center" className={classes.header}>
      <Grid
        item
        xs={8}
        container
        justify="flex-start"
        alignItems="center"
        direction="row"
      >
        <Grid className={classes.headerLabel}>
          <Typography component="h1" variant="h6" align="left">
            {inputName}
          </Typography>
          {showTypeSelect ? (
            typeSelect
          ) : (
            <Typography component="h1" variant="h6" align="left">
              {TypeDisplay[inputType]}
            </Typography>
          )}
        </Grid>
        <SearchInput
          title="Search"
          searchHandler={e => {
            setSearch(e.target.value);
          }}
          className={classes.searchInput}
          id="search-input"
        />
      </Grid>
      <Grid
        item
        xs={4}
        alignItems="flex-start"
        justify="flex-end"
        direction="row"
        container
      >
        {discoveryErrors && <ReactivateButton reactivate={reactivate} />}
        <Button
          data-testid="filters-toggle-button"
          variant="outlined"
          color="primary"
          className={classes.filterButton}
          onClick={openFilters}
          aria-label="Open list of filters"
        >
          Filters
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmitAnswers}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(QuestionControls);
