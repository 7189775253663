import './Fonts.css';

import React, {
  createContext,
  lazy,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import {
  routeCloudConnectorDetail,
  routeCloudConnectors,
  routeDomainDiscovery,
  routeDomainDiscoveryAnswers,
  routeDomainDiscoveryErrors,
  routeDomainDiscoveryParents,
  routeDomainDiscoveryQuestions,
  routeDomainDiscoveryRedirect,
  routeDomainDiscoveryResults,
  routeDomainDiscoverySeeds,
  routeFeedback,
  routeHomepage,
  routeLoggedOut,
  routeLoginCallback,
  routeOrgLookup,
  routeRules,
  routeValueMetrics,
} from './helpers/RouteHelper';

import AuthProvider from './hooks/AuthContext';
import AutoLogout from './components/AutoLogout';
import DiscoveryAnswers from '@/screens/DiscoveryAnswers';
import DiscoveryErrors from '@/screens/DiscoveryErrors';
import DiscoveryParents from '@/screens/DiscoveryParents';
import DiscoveryQuestionsById from '@/screens/DiscoveryQuestions';
import DiscoveryResults from '@/screens/DiscoveryResults';
import DiscoverySeeds from '@/screens/DiscoverySeeds';
import DomainDiscoveryProvider from './hooks/DomainDiscoveryContext';
import Empty from './screens/Empty';
import EnforceAuthRoute from './components/EnforceAuthRoute';
import ErrorBoundary from './components/ErrorBoundary';
import Footer from './components/Footer';
import Header from './components/Header';
import LinearProgress from '@material-ui/core/LinearProgress';
import Message from '@/components/Message';
import OrgProvider from './hooks/OrgContext';
import { CloudConnectorContextProvider } from '@/hooks/CloudConnectorContext';
import { Snackbar } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import classnames from 'classnames';
import { drawerWidth } from './helpers/NavHelper';
import { getPageTitle } from '@/helpers/DataHelper';
import { init as gtagInit } from './helpers/AnalyticsHelper';
import pathToRegexp from 'path-to-regexp';

const Feedback = lazy(() => import('./screens/Feedback'));
const OrgLookUp = lazy(() => import('./screens/OrgLookUp'));
const OrgDetail = lazy(() => import('./screens/OrgDetail'));
const LoginCallback = lazy(() => import('./screens/LoginCallback'));
const DiscoveryManagement = lazy(() => import('./screens/DiscoveryManagement'));
const RulesManagement = lazy(() => import('./screens/RulesManagement'));
const ValueMetrics = lazy(() => import('./screens/ValueMetrics'));
const CloudConnectors = lazy(() => import('./screens/CloudConnectors'));
const CloudConnectorDetail = lazy(() =>
  import('./screens/CloudConnectorDetail')
);
const styles = (theme: Theme) =>
  createStyles({
    App: {
      textAlign: 'center',
    },
    Site: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundColor: '#ffffff',
    },
    SiteContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 auto',
      width: '100%',
      '&::after': {
        content: "' '",
        display: 'block',
        height: 0,
        visibility: 'hidden',
      },
    },
    backgroundDefault: {
      backgroundColor: theme.palette.background.default,
    },
    backgroundWhite: {
      [theme.breakpoints.up('xl')]: {
        backgroundColor: theme.palette.background.paper,
      },
    },
    headerContent: {
      display: 'flex',
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
    },
    hasRightDrawer: {
      [theme.breakpoints.up('lg')]: {
        marginRight: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    toolbar: theme.mixins.toolbar,
    '@media print': {
      toolbar: {
        display: 'none',
      },
    },
  });

export interface LayoutContextExports {
  leftDrawerOpen: boolean;
  rightDrawerOpen: boolean;
  setLeftDrawerOpen: (value: boolean) => void;
  setRightDrawerOpen: (value: boolean) => void;
  hasCriticalError: string | null | undefined;
  setHasCriticalError: (value: string | null) => void;
  hasError: string | null | undefined;
  setHasError: (value: string | null) => void;
  setError: (error: Error) => void;
}

export interface LayoutContextOverrideExports {
  leftDrawerOpen?: LayoutContextExports['leftDrawerOpen'];
  rightDrawerOpen?: LayoutContextExports['rightDrawerOpen'];
  setLeftDrawerOpen?: LayoutContextExports['setLeftDrawerOpen'];
  setRightDrawerOpen?: LayoutContextExports['setRightDrawerOpen'];
  hasCriticalError?: LayoutContextExports['hasCriticalError'];
  setHasCriticalError?: LayoutContextExports['setHasCriticalError'];
  hasError?: LayoutContextExports['hasError'];
  setHasError?: LayoutContextExports['setHasError'];
  setError?: LayoutContextExports['setError'];
}

export const LayoutContextDefaultExports = {
  leftDrawerOpen: false,
  rightDrawerOpen: false,
  setLeftDrawerOpen: (_value: boolean) => {},
  setRightDrawerOpen: (_value: boolean) => {},
  hasCriticalError: null,
  setHasCriticalError: (_value: string | null) => {},
  hasError: null,
  setHasError: (_value: string | null) => {},
  setError: (_error: Error) => {},
};

export const LayoutContext = createContext<LayoutContextExports | undefined>(
  undefined
);

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error(
      `useLayoutContext must be called within LayoutContextProvider`
    );
  }
  return context;
};

export const useDocumentTitle = title => {
  useEffect(() => {
    document.title = getPageTitle(title);
  }, [title]);
};

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

const App = ({ classes, ...rest }: Props) => {
  const [rightDrawerOpen, setRightDrawerOpen] = useState<boolean>(false);
  const [leftDrawerOpen, setLeftDrawerOpen] = useState<boolean>(false);
  const [hasError, setHasError] = useState();
  const [hasCriticalError, setHasCriticalError] = useState();
  const layoutContext: LayoutContextExports = {
    leftDrawerOpen,
    rightDrawerOpen,
    setLeftDrawerOpen,
    setRightDrawerOpen,
    hasCriticalError: hasCriticalError,
    setHasCriticalError: setHasCriticalError,
    hasError: hasError,
    setHasError: setHasError,
    setError: (e: Error) => setHasError(e.message),
  };
  useEffect(() => {
    gtagInit();
  }, []);

  return (
    <LayoutContext.Provider value={layoutContext}>
      <ErrorBoundary>
        <AuthProvider>
          <OrgProvider>
            <DomainDiscoveryProvider>
              <CloudConnectorContextProvider>
                <div
                  className={`${classes.App} ${classes.Site} ${
                    !leftDrawerOpen && rightDrawerOpen
                      ? classes.hasRightDrawer
                      : ''
                  }`}
                >
                  <AutoLogout />
                  <div
                    className={classnames(classes.SiteContent, {
                      [classes.backgroundWhite]: Array.isArray(
                        pathToRegexp(routeFeedback()).exec(
                          rest.location.pathname
                        )
                      ),
                    })}
                  >
                    <header className={classes.headerContent}>
                      <Header />
                    </header>
                    <Suspense fallback={<LinearProgress />}>
                      <div role="main" className={classes.mainContent}>
                        <ErrorBoundary hasError={hasCriticalError}>
                          <>
                            <Snackbar
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              open={!!hasError}
                            >
                              <Message
                                variant="error"
                                message={hasError}
                                onClose={() => {
                                  setHasError(undefined);
                                }}
                              />
                            </Snackbar>
                            <div className={classes.toolbar} />
                            <EnforceAuthRoute
                              shouldBeAuthenticated={false}
                              exact
                              path={routeLoggedOut()}
                              component={Empty}
                            />
                            <EnforceAuthRoute
                              exact
                              path={routeHomepage()}
                              component={OrgDetail}
                            />
                            <EnforceAuthRoute
                              exact
                              shouldBeConsultant
                              path={routeOrgLookup()}
                              component={OrgLookUp}
                            />
                            <EnforceAuthRoute
                              exact
                              shouldBeConsultant
                              path={routeValueMetrics()}
                              component={ValueMetrics}
                            />
                            <EnforceAuthRoute
                              exact
                              shouldBeConsultant
                              path={routeCloudConnectors()}
                              component={CloudConnectors}
                            />
                            <EnforceAuthRoute
                              exact
                              shouldBeConsultant
                              path={routeCloudConnectorDetail()}
                              component={CloudConnectorDetail}
                            />
                            <EnforceAuthRoute
                              exact
                              shouldBeConsultant
                              path={routeDomainDiscovery()}
                              component={DiscoveryManagement}
                            />
                            <EnforceAuthRoute
                              exact
                              shouldBeConsultant
                              path={routeRules()}
                              component={RulesManagement}
                            />
                            <EnforceAuthRoute
                              exact
                              shouldBeConsultant
                              path={routeDomainDiscoveryParents()}
                              component={DiscoveryParents}
                            />
                            <EnforceAuthRoute
                              exact
                              shouldBeConsultant
                              path={routeDomainDiscoveryQuestions()}
                              component={DiscoveryQuestionsById}
                            />
                            <EnforceAuthRoute
                              exact
                              shouldBeConsultant
                              path={routeDomainDiscoverySeeds()}
                              component={DiscoverySeeds}
                            />
                            <EnforceAuthRoute
                              exact
                              shouldBeConsultant
                              path={routeDomainDiscoveryAnswers()}
                              component={DiscoveryAnswers}
                            />
                            <EnforceAuthRoute
                              exact
                              shouldBeConsultant
                              path={routeDomainDiscoveryResults()}
                              component={DiscoveryResults}
                            />
                            <EnforceAuthRoute
                              exact
                              shouldBeConsultant
                              path={routeDomainDiscoveryErrors()}
                              component={DiscoveryErrors}
                            />
                            <Route
                              exact
                              path={routeDomainDiscoveryRedirect()}
                              render={() => (
                                <Redirect to={routeDomainDiscovery()} />
                              )}
                            />
                            <Route
                              exact
                              path={routeLoginCallback()}
                              component={LoginCallback}
                            />
                          </>
                        </ErrorBoundary>
                        <EnforceAuthRoute
                          exact
                          path={routeFeedback()}
                          component={Feedback}
                        />
                      </div>
                    </Suspense>
                  </div>
                  <div role="contentinfo">
                    <Footer />
                  </div>
                </div>
              </CloudConnectorContextProvider>
            </DomainDiscoveryProvider>
          </OrgProvider>
        </AuthProvider>
      </ErrorBoundary>
    </LayoutContext.Provider>
  );
};

export default withStyles(styles)(withRouter(App));
