import { createStyles, Theme } from '@material-ui/core';
import { drawerWidth } from '@/helpers/NavHelper';

export const styles = (theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(2),
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeButton: {
      marginRight: theme.spacing(),
    },
    editAnswerForm: {
      padding: theme.spacing(2),
    },
    divider: {
      margin: `${theme.spacing(2)}px 0px`,
    },
    grey: {
      color: 'rgba(0,0,0,0.3)',
      borderColor: 'rgba(0,0,0,0.3)',
      textTransform: 'uppercase',
    },
  });

export const answerFormStyles = (theme: Theme) =>
  createStyles({
    closeButton: {
      marginRight: theme.spacing(),
    },
    formControl: {
      display: 'inline-block',
      padding: theme.spacing(1),
      flexGrow: 1,
      width: '100%',
    },
    formElement: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      flexGrow: 1,
      width: '100%',
    },
    formLabelLegend: {
      textTransform: 'uppercase',
      textAlign: 'left',
      flexWrap: 'wrap',
      overflowWrap: 'break-word',
      width: '100%',
    },
    grey: {
      color: 'rgba(0,0,0,0.3)',
      borderColor: 'rgba(0,0,0,0.3)',
      textTransform: 'uppercase',
    },
    button: {
      margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
      float: 'right',
    },
  });
