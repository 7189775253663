import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props extends WithStyles<typeof styles> {
  value: number | string | ReactNode;
  label: string;
  isFirst?: boolean;
  isLast?: boolean;
  lightMode?: boolean;
  sourceDataPopulated?: boolean;
  className?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: 'transparent',
      color: 'inherit',
      minWidth: 110,
    },
    cardContent: {
      padding: theme.spacing(),
    },
    cardContentFirst: {
      paddingLeft: 0,
    },
    cardContentLast: {
      paddingRight: 0,
    },
    icon: {
      fontSize: 48,
      marginRight: theme.spacing(),
    },
    text: {
      color: 'inherit',
    },
    labelText: {
      display: 'block',
      paddingTop: theme.spacing(0.5),
      color: theme.palette.common.black,
    },
    labelTextLight: {
      display: 'block',
      paddingTop: theme.spacing(0.5),
      color: theme.palette.common.white,
    },
    loaderContainer: {
      justifyContent: 'flex-start',
      display: 'flex',
      width: '100%',
    },
    loader: {
      margin: '30px 0',
    },
  });

const TopCardSimple = ({
  classes,
  value,
  label,
  isFirst,
  isLast,
  lightMode = false,
  sourceDataPopulated = true,
  className,
}: Props) => {
  const secondaryComponent = (
    <Grid item xs zeroMinWidth>
      <Typography
        component="h2"
        variant="h5"
        align="left"
        className={classes.text}
      >
        {sourceDataPopulated ? value : '--'}
        <Typography
          component="span"
          variant="overline"
          className={lightMode ? classes.labelTextLight : classes.labelText}
        >
          {label}
        </Typography>
      </Typography>
    </Grid>
  );
  return (
    <Card
      classes={{
        root: classes.card,
      }}
      className={className}
      raised={false}
      elevation={0}
      data-testid="top-card-simple"
    >
      <CardContent
        classes={{
          root: classes.cardContent,
        }}
        className={classNames(
          {
            [classes.cardContentFirst]: !!isFirst,
          },
          {
            [classes.cardContentLast]: !!isLast,
          }
        )}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {secondaryComponent}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(TopCardSimple);
