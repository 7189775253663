import {
  Button,
  CircularProgress,
  Grid,
  TablePagination,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { OrderOptions, useSorting } from '@/hooks/UseSorting';
import React, {
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';
import { SearchOptions, useSearch } from '@/hooks/UseSearch';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';

import AddIcon from '@material-ui/icons/Add';
import ColLabel from './ColLabel';
import { GetDataForPage } from '@/helpers/PaginationHelper';
import OverflowTooltip from '@/components/OverflowTooltip';
import SearchInput from '@/components/SearchInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Variant } from '@material-ui/core/styles/createTypography';
import styles from '@/components/Table/styles';
import { usePagination } from '@/hooks/UsePagination';

export type DataFormatProps = {
  centered?: boolean;
  field: string;
  includeRowColor?: any;
  onClick?: (event: any) => void;
  render?: (rowData: any) => string | React.ReactNode;
  searchable?: boolean;
  sortable?: boolean;
  title: string | ReactElement;
  variant?: Variant;
  width?: string | number;
};

export type DataRowProps = object;

export interface TableProps extends WithStyles<typeof styles> {
  columnLabels?: boolean;
  dataFormat: DataFormatProps[];
  data?: Array<any>;
  getDataForPage?: GetDataForPage;
  headerEnabled?: boolean;
  handleAddButton?: () => void;
  onRowClick?: (event: MouseEvent, rowData: any) => void;
  rowsPerPage?: number;
  rowHeight?: number;
  fillEmptyRows?: boolean;
  renderOnNoData?: JSX.Element;
  headerActions?: JSX.Element;
  search?: SearchOptions;
  searchFormat?: DataFormatProps[];
  sortEnabled?: boolean;
  defaultSortOrder?: OrderOptions;
  paginationEnabled?: boolean;
  title?: string;
  theme: Theme;
  onSearch?: (searchTerm: string | null) => void;
}

const TableComponent = (props: TableProps) => {
  const {
    columnLabels = true,
    classes,
    dataFormat,
    data: sourceData,
    defaultSortOrder,
    getDataForPage,
    handleAddButton,
    headerEnabled = true,
    onRowClick,
    fillEmptyRows = false,
    rowsPerPage: overrideRowsPerPage = 10,
    rowHeight = 52,
    headerActions = null,
    renderOnNoData = null,
    paginationEnabled = false,
    search = { enabled: false, title: 'Search' },
    searchFormat = [],
    sortEnabled = false,
    theme,
    title,
    onSearch,
  } = props;

  const { searchHandler, searchedData, searchTerm } = useSearch(
    sourceData,
    searchFormat,
    search.enabled,
    '',
    paginationEnabled && !!getDataForPage
  );

  useEffect(() => {
    if (!onSearch) return;

    onSearch(searchTerm);
  }, [searchTerm]);

  const {
    createSortHandler,
    sortedData,
    order,
    orderId,
    orderOptions,
  } = useSorting(searchedData, defaultSortOrder);

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    isLoading,
    page,
    paginatedData,
    rowsPerPage,
    totalRecords,
  } = usePagination({
    data: sortedData || undefined,
    defaultRowsPerPage: overrideRowsPerPage,
    getDataForPage,
    searchTerm: searchTerm || '',
    orderOptions,
  });

  const createRowClickHandler = (rowData: any) => (event: MouseEvent) => {
    if (!onRowClick) return () => {};
    onRowClick(event, rowData);
  };

  const createColClickHandler = (onColClick: any, rowData: any) => (
    event: MouseEvent
  ) => {
    if (!onColClick) return () => {};
    onColClick(event, rowData);
  };

  const emptyRows = useMemo(() => {
    return rowsPerPage - Math.min(rowsPerPage, +paginatedData?.length);
  }, [rowsPerPage, paginatedData]);

  const SearchComponent = useMemo(
    () => () => {
      if (!search || !search.enabled) return null;
      return (
        <Grid
          item
          className={`${classes.headerItem} ${classes.headerSearchBar}`}
        >
          <SearchInput
            title={search.title}
            searchHandler={searchHandler}
            className={classes.searchInput}
          />
        </Grid>
      );
    },
    []
  );

  const noData =
    (!sourceData || sourceData.length === 0) &&
    (!paginatedData || paginatedData.length === 0) &&
    !isLoading;

  const HeaderComponent = (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item xs={12} md={6} className={classes.tableUtilsLeft}>
        <Typography variant="h6" component="h6" className={classes.headerTitle}>
          {title}
        </Typography>
        <SearchComponent />
      </Grid>
      {!noData && (
        <Grid item xs={12} md={6} className={classes.filterButtonWrapper}>
          {!!handleAddButton && (
            <Button
              data-testid="filters-toggle-button"
              variant="outlined"
              color="primary"
              size="small"
              className={classes.filterButton}
              onClick={handleAddButton}
              aria-label="Open list of filters"
              endIcon={<AddIcon />}
            >
              Add
            </Button>
          )}
          {headerActions}
        </Grid>
      )}
    </Grid>
  );

  if (noData)
    return (
      <>
        {HeaderComponent}
        {renderOnNoData}
      </>
    );

  const LoadingComponent: ReactNode = (
    <div
      className={classes.loaderContainer}
      style={{ height: rowHeight * rowsPerPage }}
    >
      <CircularProgress className={classes.loader} />
    </div>
  );

  const PaginationComponent = (
    <Grid>
      <TablePagination
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        SelectProps={{ className: classes.hidden }}
        labelRowsPerPage={''}
      />
    </Grid>
  );

  const ColumnLabels = (
    <TableHead>
      <TableRow>
        {dataFormat.map((formatRow: DataFormatProps, index) => {
          return (
            <ColLabel
              key={`table-header-col-${index}-${formatRow.field}`}
              createSortHandler={createSortHandler}
              data={formatRow}
              index={index}
              orderId={orderId}
              order={order}
              sortEnabled={
                typeof formatRow.sortable === 'boolean'
                  ? formatRow.sortable
                  : sortEnabled
              }
              style={{
                borderBottom: '2px solid #4a4a4a',
                ...(formatRow.width
                  ? {
                      width: formatRow.width,
                      paddingRight: theme.spacing(3),
                    }
                  : {}),
                ...(formatRow.centered ? { textAlign: 'center' } : {}),
              }}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );

  const tableRow = (dataRow: DataFormatProps, index: number) => {
    return (
      <TableRow
        className={!!onRowClick ? classes.tableRowHasHover : ''}
        key={`table-row-${index}`}
        onClick={createRowClickHandler(dataRow)}
        hover={!!onRowClick}
        data-testid={`table-row-${index}`}
        style={{
          height: rowHeight,
        }}
      >
        {dataFormat.map((formatRow: DataFormatProps, i: number) => {
          const key = `table-row-${index}-col-${i}-${formatRow.field}`;
          const { render } = formatRow;
          let value: ReactNode | string = `${dataRow[formatRow.field]}`;
          if (render) {
            value = render(dataRow);
          }
          if (formatRow.includeRowColor) {
            value = (
              <>
                <div
                  className={classes.colorBlock}
                  style={{
                    backgroundColor: formatRow.includeRowColor[index],
                  }}
                />
                {value}
              </>
            );
          }

          let props: TableCellProps = {
            className: '',
          };

          if (formatRow.onClick) {
            props = {
              onClick: createColClickHandler(formatRow.onClick, dataRow),
              className: `${props.className} ${classes.colCanHover}`,
            };
          }
          const variant: Variant = formatRow.variant
            ? formatRow.variant
            : 'subtitle1';

          if (variant === 'caption') {
            props = {
              className: `${props.className} ${classes.colCaption}`,
            };
          }

          if (formatRow.width) {
            props.style = {
              width: formatRow.width,
              paddingRight: theme.spacing(3),
            };
          }

          return (
            <TableCell
              key={key}
              {...props}
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                ...(formatRow.centered ? { textAlign: 'center' } : {}),
              }}
            >
              <Typography component="span" variant={variant} noWrap>
                <OverflowTooltip value={value} />
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const EmptyRowComponent = (
    <TableRow style={{ height: rowHeight * emptyRows }} />
  );

  return (
    <>
      {headerEnabled && HeaderComponent}
      <>
        <Table className={classes.tableLayoutFixed}>
          {columnLabels && ColumnLabels}
          {!isLoading && (
            <TableBody>
              {paginatedData?.map(tableRow)}
              {fillEmptyRows && emptyRows > 0 && EmptyRowComponent}
            </TableBody>
          )}
        </Table>
        {isLoading && LoadingComponent}
        {paginationEnabled && PaginationComponent}
      </>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(TableComponent);
