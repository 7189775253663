import { AnsweredQuestion, Status } from '@/types/discovery';

export const defaultDiscovery = {
  name: 'Click Add to create a discovery',
  createdAt: '',
  ownerEmail: '',
  uid: '',
  public: false,
};

export const defaultAnswer: AnsweredQuestion = {
  index: -1,
  uid: '',
  questionType: '',
  status: Status.pending,
  statusReason: '',
  name: 'There are no answers at this time',
};

export const defaultRule = {
  index: -1,
  uid: '',
  pattern: '',
  ruleType: 'blacklist',
  vertexType: 'email',
};
