import { Card, CardActionArea, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import TopCardSimple from '@/components/TopCardSimple';
import { addCommas } from '@/helpers/DataHelper';
import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { styles } from '@/components/discovery/Summary/styles';

interface Props extends WithStyles<typeof styles> {
  cardTitle: string;
  className: string;
  stats: Stat[];
  onClick?: () => void;
}

interface Stat {
  label: string;
  value: number;
  className?: string;
}

const SummaryCard = ({
  cardTitle,
  stats,
  onClick,
  className,
  classes,
}: Props) => {
  const CardTitle = () => {
    return (
      <Typography
        component="h6"
        variant="h6"
        align="center"
        className={classes.cardGroupTitle}
      >
        {cardTitle}
      </Typography>
    );
  };

  return (
    <Card
      className={classNames(classes.cardGroup, className)}
      raised={false}
      elevation={0}
      onClick={onClick}
    >
      <CardActionArea>
        <Grid container direction="row" justify="center">
          <CardTitle />
          {stats.map(c => (
            <TopCardSimple
              className={c.className}
              value={addCommas(c.value || 0)}
              label={c.label}
              key={c.label}
            />
          ))}
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default withStyles(styles)(SummaryCard);
