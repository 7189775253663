import { MostQuestions, Status } from '@/types/discovery';
import { default as React, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import QuestionList from '@/components/discovery/QuestionList';
import { buildQueryParams } from '@/helpers/RouteHelper';
import capitalize from '@material-ui/core/utils/capitalize';
import { styles } from '@/screens/DiscoveryQuestions/styles';
import { useApiData } from '@/hooks/UseApiData';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import { useLayoutContext } from '@/App';
import { useUnsetOrg } from '@/hooks/OrgContext';
import { WithStyles, withStyles } from '@material-ui/core';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

const DiscoveryQuestions = (props: Props) => {
  const { history } = props;
  const {
    reverseLookup,
    fetchIndicatorDetails,
    selectedDiscovery,
    reactivate,
    summary,
    selectedIndicator,
    setSelectedIndicator,
  } = useDomainDiscoveryContext();
  const { setError } = useLayoutContext();
  const [questions, setQuestions] = useState();

  const sortQuestions = (q1, q2) => {
    return (
      q1.questionType
        .toLowerCase()
        .localeCompare(q2.questionType.toLowerCase()) ||
      q1.name.toLowerCase().localeCompare(q2.name.toLowerCase())
    );
  };

  const refreshData = useApiData({
    apiType: 'indicators',
    setData: setQuestions,
    setError,

    queryParams: useMemo(() => {
      if (!selectedIndicator) return '';

      const params = selectedIndicator.uid
        ? {
            inputUid: selectedIndicator.uid,
          }
        : {
            type: selectedIndicator.indicatorType,
            status: Status.pending,
          };

      return buildQueryParams(params);
    }, [selectedIndicator]),
    sourceDataPopulated: !!(
      selectedDiscovery?.uid &&
      (selectedIndicator?.uid ||
        selectedIndicator?.indicatorType !== MostQuestions.mostQuestions)
    ),
  });

  useEffect(() => {
    if (!!selectedIndicator || !selectedDiscovery) return;
    setSelectedIndicator({ indicatorType: MostQuestions.mostQuestions });
  }, [selectedIndicator, selectedDiscovery]);

  // Always unselect org since it doesn't relate to this screen
  useUnsetOrg();

  const pageTitle = useMemo(() => {
    if (
      !selectedIndicator ||
      selectedIndicator.indicatorType === MostQuestions.mostQuestions
    )
      return 'Discovery Questions';

    return selectedIndicator.indicatorType
      ? `${capitalize(selectedIndicator.indicatorType)} Questions`
      : 'Review Domain';
  }, [selectedIndicator]);

  useEffect(() => {
    let params = history.location.search;
    if (params) {
      params = params.slice(1);
      params = JSON.parse(
        '{"' +
          decodeURI(params)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      if (
        params.indicatorUid &&
        params.indicatorUid !== selectedIndicator?.uid
      ) {
        setSelectedIndicator({
          uid: params.indicatorUid,
          name: params.indicatorName,
          indicatorType: params.indicatorType,
        });
      }
    }
  }, [history.location]);

  return (
    <QuestionList
      pageTitle={pageTitle}
      questions={questions}
      inputName={selectedIndicator?.name}
      inputType={selectedIndicator?.indicatorType}
      reverseLookup={reverseLookup}
      fetchIndicatorDetails={fetchIndicatorDetails}
      reactivate={reactivate}
      setAnswersRecorded={() => {
        setQuestions(undefined);
        if (selectedIndicator?.indicatorType === MostQuestions.mostQuestions) {
          setSelectedIndicator({ indicatorType: MostQuestions.mostQuestions });
        }
        refreshData();
      }}
      questionSortFunc={selectedIndicator?.uid ? undefined : sortQuestions}
      autoExpandFirst={
        selectedIndicator?.indicatorType === MostQuestions.mostQuestions ||
        !selectedIndicator?.uid
      }
      maxQuestions={
        selectedIndicator?.indicatorType === MostQuestions.mostQuestions
          ? undefined
          : 50
      }
      discoveryErrors={!!summary?.errorStatus}
      setSelectedIndicator={setSelectedIndicator}
      defaultAnswer={
        selectedIndicator?.indicatorType === MostQuestions.mostQuestions
          ? 'denied'
          : undefined
      }
      showTypeSelect
    />
  );
};

export default withStyles(styles, { withTheme: true })(
  withRouter(DiscoveryQuestions)
);
