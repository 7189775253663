import get from 'lodash/get';
import { useMemo, useState, useContext, useEffect } from 'react';
import {
  ActiveFilter,
  ActiveFilterProps,
  FilterConfig,
} from '@/helpers/FilterHelpers';
import { useApiData } from './UseApiData';
import { RootAuthContext } from '@/hooks/AuthContext';
import { EnvVars } from './EnvVars';

interface InputOptions {
  data: Array<object> | undefined;
  filters: FilterConfig;
  initialActiveFilters?: ActiveFilter;
  defaultActiveFilters?: ActiveFilter;
  name?: string;
}

const useFilters = (options: InputOptions) => {
  const {
    data,
    defaultActiveFilters,
    filters,
    initialActiveFilters,
    name,
  } = options;

  const authContext = useContext(RootAuthContext);
  const { userInfo } = authContext;

  const [userPreferences, setUserPreferences] = useState();
  const [activeFilters, setActiveFilters] = useState<ActiveFilter>(
    initialActiveFilters
  );

  useApiData({
    apiType: 'userPreferences',
    apiHost: EnvVars['REACT_APP_LOGAN_HOST'],
    setData: setUserPreferences,
    sourceDataPopulated: !!userInfo?.sub,
  });

  useEffect(() => {
    if (!userPreferences?.filters || !name || !userPreferences?.filters[name])
      return;

    setActiveFilters(userPreferences?.filters[name]);
  }, [userPreferences]);

  const updateActiveFilters = (data, options) => {
    if (!data) {
      setActiveFilters(defaultActiveFilters);
      return;
    }
    const groupDataKey = get(data, '[0].groupDataKey', '');
    const optionDataKey = get(data, '[0].optionDataKey', '');

    let selectedOptionData: string | null = null;
    let existingFilters: ActiveFilterProps[] = [];
    if (activeFilters) {
      selectedOptionData = get(
        activeFilters.filter(item => item.groupDataKey === groupDataKey),
        '[0].optionDataKey'
      );
      existingFilters = activeFilters.filter(
        item => item.groupDataKey !== groupDataKey
      );
    }

    let newFilter = data;
    if (options && options.multiSelect && selectedOptionData) {
      let selectedDataArray = selectedOptionData.split('|');
      const newDataIndex = selectedDataArray.indexOf(optionDataKey);
      if (newDataIndex >= 0) {
        selectedDataArray.splice(newDataIndex, 1);
      } else {
        selectedDataArray = [...selectedDataArray, optionDataKey];
      }
      if (selectedDataArray.length > 0) {
        newFilter = [
          {
            groupDataKey: groupDataKey,
            optionDataKey: selectedDataArray.join('|'),
          },
        ];
      } else {
        newFilter = [];
      }
    }
    setActiveFilters([...existingFilters, ...newFilter]);
  };

  const activeDisplayedFilters: ActiveFilterProps[] | null = useMemo(() => {
    if (!activeFilters || !filters) return [];
    return activeFilters.reduce(
      (
        filterList: Array<ActiveFilterProps>,
        activeFilter: ActiveFilterProps
      ) => {
        const isDisplayed = filters.find(
          item => activeFilter.groupDataKey === item.dataKey && item.display
        );
        return [...filterList, ...(isDisplayed ? [activeFilter] : [])];
      },
      []
    );
  }, [activeFilters, filters]);

  const allFilterOptions = useMemo(() => {
    if (!activeDisplayedFilters) return [];
    return activeDisplayedFilters.map(filter => ({
      group: filter.groupDataKey,
      options: filter.optionDataKey
        .replace(/[\s-]/g, '')
        .toLowerCase()
        .split('|'),
    }));
  }, [activeDisplayedFilters]);

  const filteredData = useMemo(() => {
    if (!data) return [];
    if (!activeFilters || !filters) return data;

    return activeFilters.reduce(
      (currentData: any[], { optionDataKey, groupDataKey }) => {
        const optionDataKeyList = optionDataKey.split('|');
        const configGroup: Array<any> = filters.filter(
          group => group.dataKey === groupDataKey
        );

        const configOptions: Array<any> = get(
          configGroup || [],
          '[0].options',
          []
        ).filter(option => optionDataKeyList.indexOf(option.dataKey) >= 0);

        const predicateChecks = item => {
          return configOptions.reduce((currentTruthy: boolean, option) => {
            const predicate = get(option, 'predicate', () => true);
            return currentTruthy || predicate(item);
          }, false);
        };

        if (!currentData) return [];
        if (!configOptions.length) return currentData;
        return currentData.filter(predicateChecks);
      },
      data
    );
  }, [data, activeFilters, initialActiveFilters]);

  return {
    filteredData,
    activeFilters,
    activeDisplayedFilters,
    allFilterOptions,
    updateActiveFilters,
  };
};
export default useFilters;
