import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  generateDiscoveryIdPath,
  routeDomainDiscoveryAnswers,
  routeDomainDiscoveryErrors,
  routeDomainDiscoveryQuestions,
  routeDomainDiscoveryResults,
} from '@/helpers/RouteHelper';

import { Grid } from '@material-ui/core';
import PageHeader from '@/components/PageHeader';
import React from 'react';
import { MostQuestions } from '@/types/discovery';
import SummaryCard from '@/components/discovery/Summary/SummaryCard';
import { styles } from '@/components/discovery/Summary/styles';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import { useRouteToPage } from '@/hooks/UseRouteToPage';

interface Props extends WithStyles<typeof styles> {
  pageTitle: string;
}

const Summary = ({ pageTitle, classes }: Props) => {
  const { summary, selectedDiscovery } = useDomainDiscoveryContext();
  const [routeToPage] = useRouteToPage();
  const { setSelectedIndicator } = useDomainDiscoveryContext();

  const sum = summary || {
    questionStatus: {
      new: 0,
      approved: 0,
      denied: 0,
      pending: 0,
      total: 0,
      pending_screenshot: 0,
    },
    processingStatus: {
      total: 0,
    },
    errorStatus: 0,
    domainStatus: {
      none: 0,
      low: 0,
      high: 0,
    },
  };

  return (
    <div className={classes.cardContainer}>
      <PageHeader pageTitle={pageTitle} />
      <Grid
        container
        item
        justify="flex-end"
        className={classes.cardInnerContainer}
      >
        <SummaryCard
          cardTitle="Status"
          className={classes.cardStatus}
          stats={[
            { value: sum.processingStatus.total, label: 'Processing' },
            {
              value: sum.errorStatus || 0,
              label: 'Errors',
              className: classes.cardHighlightError,
            },
          ]}
          onClick={() => {
            routeToPage(
              generateDiscoveryIdPath(
                routeDomainDiscoveryErrors(),
                selectedDiscovery?.uid
              )
            );
          }}
        />
        <SummaryCard
          cardTitle="Answers"
          className={classes.cardAnswers}
          stats={[
            {
              value:
                (sum.questionStatus.approved || 0) +
                (sum.questionStatus.denied || 0),
              label: 'Answered',
              className: classes.cardHighlightAnswered,
            },
          ]}
          onClick={() => {
            routeToPage(
              generateDiscoveryIdPath(
                routeDomainDiscoveryAnswers(),
                selectedDiscovery?.uid
              )
            );
          }}
        />
        <SummaryCard
          cardTitle="Questions"
          className={classes.cardQuestions}
          stats={[
            {
              value: sum.questionStatus.pending_screenshot || 0,
              label: 'Screenshots',
              className: classes.cardHighlightUnanswered,
            },
            {
              value: sum.questionStatus.pending || 0,
              label: 'Unanswered',
              className: classes.cardHighlightUnanswered,
            },
            { value: sum.questionStatus.total || 0, label: 'Total' },
          ]}
          onClick={() => {
            setSelectedIndicator({
              indicatorType: MostQuestions.mostQuestions,
            });
            routeToPage(
              generateDiscoveryIdPath(
                routeDomainDiscoveryQuestions(),
                selectedDiscovery?.uid
              )
            );
          }}
        />
        <SummaryCard
          cardTitle="Domain Confidence"
          className={classes.cardQuestions}
          stats={[
            {
              value: sum.domainStatus.high || 0,
              label: 'High',
              className: classes.cardDomainsHigh,
            },
            {
              value: sum.domainStatus.low || 0,
              label: 'Low',
              className: classes.cardDomainsLow,
            },
            {
              value: sum.domainStatus.none || 0,
              label: 'None',
              className: classes.cardDomainsNone,
            },
          ]}
          onClick={() => {
            routeToPage(
              generateDiscoveryIdPath(
                routeDomainDiscoveryResults(),
                selectedDiscovery?.uid
              )
            );
          }}
        />
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Summary);
