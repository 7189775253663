import { CloseIcon, MenuIcon } from '@/images/Icons';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Login from '@/components/Login';
import NavPrimary from '@/components/NavPrimary';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Toolbar from '@material-ui/core/Toolbar';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { drawerWidth } from '@/helpers/NavHelper';
import get from 'lodash/get';
import { onClickHandler as onClickHandlerGtag } from '@/helpers/AnalyticsHelper';
import { useAuthContext } from '@/hooks/AuthContext';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import { useLayoutContext } from '@/App';
import { useOrgContext } from '@/hooks/OrgContext';

interface Props extends WithStyles<typeof styles> {}

const styles = (theme: Theme) =>
  createStyles({
    headerContainer: {},
    appBar: {
      overflow: 'hidden',
      marginLeft: drawerWidth,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    menuButton: {
      marginRight: theme.spacing(0.5),
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(2),
      },
    },
    logotype: {
      flexGrow: 1,
      display: 'flex',
    },
    logotypeWrapper: {
      width: 220,
      paddingTop: 3,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    logomarkWrapper: {
      width: 55,
      paddingTop: 3,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    contextName: {
      color: theme.palette.text.primary,
      textTransform: 'uppercase',
      lineHeight: '2em',
      marginLeft: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      borderLeft: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        marginLeft: theme.spacing(3),
      },
    },
    hasRightDrawer: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
      transition:
        'margin 0.225s cubic-bezier(0, 0, 0.2, 1), width 0.225s cubic-bezier(0, 0, 0.2, 1)',
    },
    '@media print': {
      headerContainer: {
        display: 'none',
      },
    },
  });

const Header = (props: Props) => {
  const { classes } = props;
  const { isAuthenticated } = useAuthContext();
  const {
    leftDrawerOpen,
    setLeftDrawerOpen,
    rightDrawerOpen,
    setRightDrawerOpen,
  } = useLayoutContext();
  const { selectedOrgInfo } = useOrgContext();
  const { selectedDiscovery } = useDomainDiscoveryContext();
  const orgName = get(selectedOrgInfo, 'name');
  const { pathname } = useLocation();
  let contextName = orgName ? orgName : selectedDiscovery?.name;

  if (pathname.includes('cloud-connectors')) {
    contextName = undefined;
  }

  const handleDrawerToggle = () => {
    setLeftDrawerOpen(!leftDrawerOpen);
    onClickHandlerGtag({
      event_category: `toggle_primary_nav_drawer`,
      event_label: !leftDrawerOpen ? 'open' : 'close',
    });
  };
  const closeDrawer = () => {
    setLeftDrawerOpen(false);
    setRightDrawerOpen(false);
  };

  return (
    <div className={classes.headerContainer}>
      <AppBar
        position="fixed"
        className={classNames(
          isAuthenticated ? classes.isAuthenticated : null,
          classes.appBar,
          !leftDrawerOpen && rightDrawerOpen ? classes.hasRightDrawer : ''
        )}
        elevation={0}
        color="default"
        component="div"
      >
        <Toolbar>
          {isAuthenticated && (
            <IconButton
              color="inherit"
              aria-label="Open navigation drawer"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              {leftDrawerOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          )}
          <div className={classes.logotype}>
            <div className={classes.logomarkWrapper}>
              <img
                alt="Logomark"
                src={window.location.origin + '/assets/Logomark.svg'}
              />
            </div>
            <div className={classes.logotypeWrapper}>
              <img
                alt="Logomark Full"
                src={window.location.origin + '/assets/LogotypePrimaryFull.svg'}
              />
            </div>
            {contextName && (
              <Typography
                component="h6"
                variant="h6"
                className={classes.contextName}
                noWrap
              >
                {contextName}
              </Typography>
            )}
          </div>
          <Login />
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <NavPrimary drawerOpen={leftDrawerOpen} closeDrawer={closeDrawer} />
      )}
    </div>
  );
};

export default withStyles(styles)(Header);
