import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import Cancel from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MenuIcon from '@material-ui/icons/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NoteIcon from '@material-ui/icons/Note';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PollIcon from '@material-ui/icons/Poll';
import SaveAlt from '@material-ui/icons/SaveAlt';
import SearchIcon from '@material-ui/icons/Search';
import StopIcon from '@material-ui/icons/Stop';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WhatsHotIcon from '@material-ui/icons/Whatshot';
import CloudIcon from '@material-ui/icons/Cloud';

export {
  AssessmentIcon,
  AssignmentIcon,
  ArrowDownwardIcon,
  ArrowUpwardIcon,
  BuildIcon,
  Cancel,
  CloseIcon,
  DescriptionIcon,
  HelpOutlineIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  KeyboardArrowUpIcon,
  LocalLibraryIcon,
  LocalParkingIcon,
  MailOutlineIcon,
  MenuIcon,
  MoreVertIcon,
  NoteIcon,
  OpenInBrowserIcon,
  OpenInNewIcon,
  PermIdentityIcon,
  PollIcon,
  SaveAlt,
  SearchIcon,
  StopIcon,
  VisibilityIcon,
  WhatsHotIcon,
  CloudIcon,
};
