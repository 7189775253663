import { ApiCallback, ApiTypes, apiUrls } from '@/helpers/ApiHelper';
import { Dispatch, SetStateAction } from 'react';

import { EnvVars } from './EnvVars';
import { useAuthContext } from './AuthContext';
import { useOrgContext } from './OrgContext';

interface Props {
  apiType: ApiTypes;
  setData: Dispatch<SetStateAction<any>>;
  setError?: Dispatch<SetStateAction<any>>;
}

export const useApiDelete = (props: Props) => {
  const { apiType, setData, setError } = props;
  const { accessToken } = useAuthContext();
  const { selectedOrg, selectedOrgInfo } = useOrgContext();

  async function deleteApiData(params) {
    const getUrl: ApiCallback = apiUrls[apiType];
    try {
      const apiHost = EnvVars['REACT_APP_API_HOST'];
      const response = await fetch(
        getUrl({ apiHost, selectedOrg, selectedOrgInfo, ...params }),
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status !== 204) throw new Error('API Error');
    } catch (e) {
      if (setError) setError(e);
    }
    return;
  }

  const submitDeleteCall = params => {
    if (!accessToken) return;
    deleteApiData(params).then(() => {
      setData(params);
    });
  };

  return [submitDeleteCall];
};
