import { useAuthContext } from './AuthContext';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import { Dispatch, useCallback, useEffect, useMemo } from 'react';
import { EnvVars } from '@/hooks/EnvVars';
import { Detail, Indicator } from '@/types/discovery';

const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  const bytes = [].slice.call(new Uint8Array(buffer));
  let binary = '';

  bytes.forEach((b: any) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

const fetchImage = async (screenshotPath: string, accessToken: any) => {
  const response = await fetch(
    `${EnvVars['REACT_APP_API_HOST']}${screenshotPath}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (response.status === 200) {
    return (
      'data:image/jpeg;base64,' +
      arrayBufferToBase64(await response.arrayBuffer())
    );
  }

  const noImage = await fetch(`/noimage.jpg`, {
    method: 'GET',
  });

  return (
    'data:image/jpeg;base64,' + arrayBufferToBase64(await noImage.arrayBuffer())
  );
};

export const useScreenshotBatch = (
  questions: Indicator[] | undefined,
  details: Map<string, Detail[]>,
  screenshots: Map<string, any>,
  setScreenshots: Dispatch<Map<string, any>>
) => {
  const { accessToken } = useAuthContext();
  const { selectedDiscovery } = useDomainDiscoveryContext();

  const screenshotMeta = useMemo(() => {
    return questions
      ?.filter(q => q.questionType === 'screenshot' && details.get(q.uid))
      .map(q =>
        details
          .get(q.uid)
          ?.filter(d => d.data && d.data['uid'])
          .map(d => d.data)
      )
      .flat()
      .filter(meta => {
        return !screenshots.has(meta.dHash);
      });
  }, [questions, details]);

  const getScreenshots = useCallback(() => {
    if (!screenshotMeta) return [];

    const uniqScreenshotMeta = screenshotMeta.filter(
      (meta, idx) =>
        screenshotMeta.findIndex(meta2 => meta2.dHash === meta.dHash) === idx
    );

    return uniqScreenshotMeta.map((meta: any) => {
      return fetchImage(
        `/v1/discoveries/${selectedDiscovery?.uid}/screenshots/${meta.uid}/image`,
        accessToken
      ).then(screenshot => {
        return new Promise<any>((resolve, _) => {
          resolve([meta.dHash, screenshot]);
        });
      });
    });
  }, [accessToken, screenshotMeta]);

  useEffect(() => {
    let isSubscribed = true;
    if (!accessToken || !questions) return;
    Promise.all(getScreenshots()).then(data => {
      if (isSubscribed) {
        const newScreenshots = data.reduce((acc, d) => {
          const [dHash, image] = d;
          acc.set(dHash, image);
          return acc;
        }, new Map<string, any>(screenshots));

        setScreenshots(newScreenshots);
      }
    });
    return function cleanup() {
      isSubscribed = false;
    };
  }, [getScreenshots]);
};
