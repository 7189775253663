import {
  Answer,
  Detail,
  Indicator,
  QuestionContext,
  Status,
} from '@/types/discovery';
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListSubheader,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import {
  default as React,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { AutoSizer } from 'react-virtualized';
import DomainDiscoverySummary from '@/components/discovery/Summary';
import Filters from '@/components/Filters';
import PageLoader from '@/components/PageLoader';
import QuestionCard from '@/components/discovery/QuestionCard';
import QuestionControls from '@/components/discovery/QuestionList/QuestionControls';
import RulesDrawer from '@/components/discovery/RuleDrawer';
import { defaultRule } from '@/helpers/DiscoveryHelper';
import { styles } from '@/components/discovery/QuestionList/styles';
import { useApiPost } from '@/hooks/UseApiPost';
import { useDrawer } from '@/hooks/UseDrawer';
import useFilters from '@/hooks/UseFilters';
import { useLayoutContext } from '@/App';
import { useScreenshotBatch } from '@/hooks/UseScreenshotBatch';

interface Props extends WithStyles<typeof styles> {
  pageTitle: string;
  questions: Indicator[] | undefined;
  inputName?: string;
  inputType?: string;

  autoExpandFirst: boolean;
  maxQuestions?: number;
  discoveryErrors?: boolean;
  defaultAnswer?: Answer;

  setAnswersRecorded: () => void;
  setSelectedIndicator: (indicator: QuestionContext | undefined) => void;
  reverseLookup: (
    questionUid: string,
    setData: (ReverseLookupResult) => void,
    setError: (value: boolean) => void
  ) => void;
  fetchIndicatorDetails: (
    questionUid: string,
    setData: (evidence: Detail[]) => void
  ) => void;
  reactivate: (completed: () => void) => void;
  questionSortFunc?: (q1, q2) => number;
  showTypeSelect?: boolean;
}

const QuestionList = ({
  classes,
  pageTitle,
  questions,
  inputName,
  inputType,
  reverseLookup,
  fetchIndicatorDetails,
  setAnswersRecorded,
  setSelectedIndicator,
  reactivate,
  autoExpandFirst,
  maxQuestions,
  questionSortFunc,
  discoveryErrors,
  defaultAnswer,
  showTypeSelect,
}: Props) => {
  const { setError } = useLayoutContext();

  const [answers, setAnswers] = useState<Map<string, Answer>>(
    new Map<string, Answer>()
  );
  const [search, setSearch] = useState('');
  const [postData, setPostData] = useState();
  const [currentRule, setCurrentRule] = useState(defaultRule);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [questionDetails, setQuestionDetails] = useState<Map<string, Detail[]>>(
    new Map<string, Detail[]>()
  );
  const [screenshots, setScreenshots] = useState<Map<string, any>>(
    new Map<string, any>()
  );
  const [questionCount, setQuestionCount] = useState<number>(
    maxQuestions || questions?.length || 0
  );

  const [submitAnswers] = useApiPost('answers', setPostData, setError);

  const [isRuleDrawerOpen, setIsRuleDrawerOpen] = useDrawer();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useDrawer({
    otherDrawerOpen: isRuleDrawerOpen,
    setOtherDrawerOpen: setIsRuleDrawerOpen,
  });

  const openFilters = () => {
    setIsFilterDrawerOpen(true);
  };

  const addRule = question => {
    setCurrentRule({
      index: -1,
      uid: '',
      pattern: question.name,
      ruleType: 'blacklist',
      vertexType: question.questionType,
    });
    setIsRuleDrawerOpen(true);
  };

  useScreenshotBatch(questions, questionDetails, screenshots, setScreenshots);

  // Update after successful answers action
  useEffect(() => {
    if (!postData) {
      return;
    }

    setAnswers(new Map());
    setAnswersRecorded();
    setSubmitInProgress(false);
  }, [postData]);

  useEffect(() => {
    if (!questions) {
      return;
    }

    setQuestionCount(questionCount || questions.length);
    setAnswers(new Map(answers));
  }, [questions]);

  const handleSubmitAnswers = useCallback(() => {
    if (!questions) {
      return;
    }

    const newAnswers = new Map(answers);
    questions.forEach(q => {
      if (answers.has(q.uid) && !!answers.get(q.uid)) return;

      if (q.status === Status.approved || q.status === Status.denied) {
        newAnswers.set(q.uid, undefined);
      } else {
        newAnswers.set(q.uid, defaultAnswer);
      }
    });

    const answerData: Array<object> = [];
    newAnswers.forEach((answer, uid) => {
      !!answer && answerData.push({ uid, answer: answer });
    });
    submitAnswers(answerData, 'json', 'POST');
    setSubmitInProgress(true);
  }, [answers, questions]);

  const fetchDetails = uid => {
    fetchIndicatorDetails(uid, (details: Detail[]) => {
      const newDetails = new Map(questionDetails);
      newDetails.set(uid, details);

      setQuestionDetails(newDetails);
    });
  };

  const defaultSortFunc = (q1, q2) => {
    const q1Answered = answered(q1);
    const q2Answered = answered(q2);
    if (q1Answered !== q2Answered) {
      return q1Answered ? 1 : -1;
    }

    return (
      q1.questionType
        .toLowerCase()
        .localeCompare(q2.questionType.toLowerCase()) ||
      q1.name.toLowerCase().localeCompare(q2.name.toLowerCase())
    );
  };

  const answered = (q: Indicator) => {
    return answers.get(q.uid) !== undefined || q.status !== Status.pending;
  };

  const FilterConfig = [
    {
      dataKey: 'status',
      groupLabel: 'Status',
      options: [
        {
          label: 'All',
          dataKey: 'all',
          predicate: () => true,
        },
        {
          label: 'Answered',
          dataKey: 'answered',
          predicate: q => answered(q),
        },
        {
          label: 'Unanswered',
          dataKey: 'unanswered',
          predicate: q => !answered(q),
        },
      ],
      display: true,
      multiSelect: false,
    },
  ];

  const defaultActiveFilters = [
    { groupDataKey: 'status', optionDataKey: 'all' },
  ];
  const { filteredData, activeFilters, updateActiveFilters } = useFilters({
    data: questions || [],
    defaultActiveFilters: defaultActiveFilters,
    filters: FilterConfig,
    initialActiveFilters: defaultActiveFilters,
    name: 'questions',
  });

  // Apply search to questions
  const sortedFilteredQuestions = useMemo(() => {
    if (!filteredData) {
      return;
    }

    const questionMap = filteredData.reduce(function(map, q) {
      map.set(q.uid, q);
      return map;
    }, new Map<string, Indicator>());
    const uniqueQuestions = [...questionMap.values()];

    return uniqueQuestions
      .filter(q =>
        (q.name + q.questionType).toLowerCase().includes(search.toLowerCase())
      )
      .sort((q1, q2) => {
        return questionSortFunc
          ? questionSortFunc(q1, q2)
          : defaultSortFunc(q1, q2);
      });
  }, [search, answers, filteredData]);

  const domainDiscoverySummary = (
    <DomainDiscoverySummary pageTitle={pageTitle} />
  );

  const questionCards = (
    <Grid className={classes.questions}>
      {sortedFilteredQuestions &&
        sortedFilteredQuestions
          .slice(0, questionCount)
          .map((question: Indicator, i: number) => {
            return (
              <QuestionCard
                key={question.uid}
                question={question}
                details={questionDetails.get(question.uid)}
                answer={answers.get(question.uid)}
                setAnswer={(a: Answer) =>
                  setAnswers(new Map(answers.set(question.uid, a)))
                }
                first={i === 0 && autoExpandFirst}
                reverseLookup={reverseLookup}
                fetchDetails={fetchDetails}
                addRule={addRule}
                media={screenshots && screenshots.get(question.name)}
              />
            );
          })}
    </Grid>
  );

  return (
    <>
      <div className={classes.root}>
        {(submitInProgress || !questions) && (
          <PageLoader pageTitle={pageTitle} />
        )}
        <AutoSizer disableWidth>
          {({ height, width }) => (
            <Box height={height} width={width} overflow="hidden">
              <List className={classes.questionList} subheader={<li />}>
                <ListItem>{domainDiscoverySummary}</ListItem>
                <ListSubheader>
                  <QuestionControls
                    inputName={inputName}
                    inputType={inputType}
                    setSelectedIndicator={setSelectedIndicator}
                    reactivate={reactivate}
                    discoveryErrors={discoveryErrors}
                    handleSubmitAnswers={handleSubmitAnswers}
                    setSearch={setSearch}
                    openFilters={openFilters}
                    showTypeSelect={showTypeSelect}
                  />
                  <Divider />
                </ListSubheader>
                {!submitInProgress && questions && (
                  <>
                    <ListItem>{questionCards}</ListItem>
                    {maxQuestions && questionCount < questions.length && (
                      <ListItem className={classes.loadMore}>
                        <Button
                          color="primary"
                          onClick={() => {
                            setQuestionCount(
                              Math.min(
                                questionCount + maxQuestions,
                                questions.length
                              )
                            );
                          }}
                        >
                          Load More
                        </Button>
                      </ListItem>
                    )}
                  </>
                )}
              </List>
            </Box>
          )}
        </AutoSizer>
      </div>
      <Filters
        filters={FilterConfig}
        isOpen={isFilterDrawerOpen}
        setIsOpen={setIsFilterDrawerOpen}
        activeFilter={activeFilters}
        handleChangeFilters={updateActiveFilters}
        tableName={'pageTitle'}
        disable={false}
      />
      <RulesDrawer
        currentRule={currentRule}
        isOpen={isRuleDrawerOpen}
        setIsOpen={(isOpen: boolean) => setIsRuleDrawerOpen(isOpen)}
      />
    </>
  );
};

export default withStyles(styles)(QuestionList);
