import React from 'react';
import { Chip as MuiChip } from '@material-ui/core';

import useStyles from './styles';

interface Props {
  label: string;
}

const Chip = (props: Props) => {
  const classes = useStyles(props);
  const { label } = props;

  return <MuiChip className={classes.chip} color="primary" label={label} />;
};

export default Chip;
