import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Link,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { default as React, useEffect, useState } from 'react';
import TableComponent, { DataFormatProps } from '@/components/Table';

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddIcon from '@material-ui/icons/Add';
import BlockIcon from '@material-ui/icons/Block';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import LaunchIcon from '@material-ui/icons/Launch';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import Paper from '@material-ui/core/Paper';
import ReverseLookupTable from './ReverseLookupTable';
import clsx from 'clsx';
import { styles } from '@/components/discovery/QuestionCard/styles';
import ScreenshotContent from '@/components/discovery/QuestionCard/ScreenshotContent';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import {
  Answer,
  Detail,
  Indicator,
  IndicatorType,
  ReverseLookupResult,
  Status,
  SubsidiaryData,
  TypeDisplay,
} from '@/types/discovery';
import CircularProgress from '@material-ui/core/CircularProgress';

const defaultDataFormat: DataFormatProps[] = [
  {
    title: 'Evidence',
    field: 'name',
  },
  {
    title: 'Domain',
    field: 'originator',
  },
  {
    title: 'Details',
    field: 'alias',
  },
  {
    title: 'Date',
    field: 'Date',
    render: rowData => {
      const { retrievalTime } = rowData;
      return new Date(retrievalTime).toLocaleDateString();
    },
  },
];

const subsidiaryDataFormat: DataFormatProps[] = [
  {
    title: 'Title',
    field: 'title',
  },
  {
    title: 'Link',
    field: 'url',
    render: rowData => {
      const { url } = rowData;
      return (
        <Link href={url} target="_blank" rel="noopener">
          {url}
        </Link>
      );
    },
  },
];

const tlsOrgFormat: DataFormatProps[] = [
  {
    title: 'Source',
    field: 'name',
    render: rowData => {
      const { name } = rowData;
      return name === 'tls-current' ? 'SSL via HTTP' : 'SSL via cert.sh';
    },
  },
  {
    title: 'Subject Common Name',
    field: 'data',
    render: rowData => {
      const { data } = rowData;
      return data?.subjectCommonName || 'none';
    },
  },
  {
    title: 'DNS Names',
    field: 'data',
    render: rowData => {
      const { data } = rowData;
      if (!data?.dnsNames) {
        return 'none';
      }
      return `( ${data.dnsNames.length} ) ${data.dnsNames.join(',')}`;
    },
  },
  {
    title: 'Not After',
    field: 'Date',
    render: rowData => {
      const { data } = rowData;
      return data?.notAfter
        ? new Date(data?.notAfter).toLocaleDateString()
        : 'none';
    },
  },
  {
    title: 'Reference',
    field: 'originator',
    render: rowData => {
      const { name, originator } = rowData;

      // if originator is just a domain, we have to prefix the protocol
      const linkLocation =
        originator.indexOf('http') === 0 ? originator : `https://${originator}`;
      const linkText =
        name === 'tls-current' ? `${linkLocation}` : 'view certificate';

      return (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<LaunchIcon />}
          onClick={() => window.open(linkLocation)}
        >
          {linkText}
        </Button>
      );
    },
  },
];

const defaultSummaryHeader = sortedEvidence => {
  return sortedEvidence?.length > 0
    ? `( ${sortedEvidence.length} ) ${sortedEvidence[0].alias}${
        sortedEvidence.length > 1 ? ' ...' : ''
      }`
    : '';
};

const tlsOrgSummaryHeader = sortedEvidence => {
  return sortedEvidence?.length > 0
    ? `( ${sortedEvidence.length} ) ${
        sortedEvidence[0].data?.subjectCommonName
      }${sortedEvidence.length > 1 ? ' ...' : ''}`
    : '';
};

const summaryHeader = {
  email: defaultSummaryHeader,
  tlsorganization: tlsOrgSummaryHeader,
};

const calculateSummaryHeader = (questionType, sortedEvidence) => {
  if (!summaryHeader[questionType]) {
    return defaultSummaryHeader(sortedEvidence);
  }
  return summaryHeader[questionType](sortedEvidence);
};

// map of questionType to evidence formats
const evidenceDataFormats = {
  email: defaultDataFormat,
  tlsorganization: tlsOrgFormat,
};

interface Props extends WithStyles<typeof styles> {
  question: Indicator;
  details?: Detail[];
  answer: Answer;
  setAnswer: (Answer) => void;
  first?: boolean;
  reverseLookup: (
    questionUid: string,
    setData: (ReverseLookupResult) => void,
    setError: (value: boolean) => void
  ) => void;
  fetchDetails: (indicatorUid: string) => void;
  addRule: (question: Indicator) => void;
  media?: any;
}

const QuestionCard = ({
  question,
  details,
  fetchDetails,
  answer,
  setAnswer,
  first,
  reverseLookup,
  classes,
  addRule,
  media,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(!!first);
  const [showReverseLookup, setShowReverseLookup] = useState<boolean>(false);
  const [reverseLookupError, setReverseLookupError] = useState<boolean>(false);
  const [reverseLookupResult, setReverseLookupResult] = useState<
    ReverseLookupResult
  >();
  const { setSelectedIndicator } = useDomainDiscoveryContext();

  // expand if first is set
  useEffect(() => {
    setExpanded(!!first);
  }, [first, answer, setExpanded]);

  // fetch details if not present
  useEffect(() => {
    if (expanded && !details) {
      fetchDetails(question.uid);
    }
  }, [expanded]);

  useEffect(() => {
    if (reverseLookupError) {
      setShowReverseLookup(false);
      setReverseLookupError(false);
    }
  }, [reverseLookupError]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleAnswer = (answer: Answer) => {
    setAnswer(answer);
  };

  const handleDomainLookup = () => {
    setShowReverseLookup(true);
    reverseLookup(question.uid, setReverseLookupResult, setReverseLookupError);
  };

  const evidenceDataFormat =
    evidenceDataFormats[question.questionType] || defaultDataFormat;

  const handleAddRule = () => {
    addRule(question);
  };

  const handleShowAnswers = () => {
    if (!question) return;

    setSelectedIndicator({
      uid: question.uid,
      name: question.name,
      indicatorType: question.questionType,
      uidType: 'output',
    });
  };

  const subsidiaryDetails =
    details && details.filter(detail => detail.name === 'subsidiary');

  const notSubsidiaryDetails =
    details && details.filter(detail => detail.name !== 'subsidiary');

  const title = (
    <Grid container direction="row" alignItems="center" justify="space-between">
      <Grid item xs={6}>
        <Typography
          className={classes.questionTitle}
          variant={'h6'}
          align={'left'}
        >
          {question.name}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={'subtitle2'} align={'left'}>
          {calculateSummaryHeader(question.questionType, details)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        {question.statusReason && question.status === Status.denied && (
          <Chip
            color="primary"
            label={`${question.statusReason}`}
            icon={<BlockIcon />}
            size="small"
          />
        )}
      </Grid>
    </Grid>
  );

  const statusAvatar =
    question.status === Status.approved ? (
      <CheckCircleIcon
        className={classes.acceptedIcon}
        data-testid="accepted-icon"
      />
    ) : question.status === Status.denied ? (
      <CancelIcon className={classes.deniedIcon} data-testid="denied-icon" />
    ) : (
      <HelpIcon color="action" data-testid="help-icon" />
    );

  const headerAction = (
    <>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button
          variant={
            (answer || question.status) === Status.approved
              ? 'contained'
              : 'outlined'
          }
          color="primary"
          size="small"
          onClick={() => handleAnswer(Status.approved)}
        >
          Accept
        </Button>
        <Button
          variant={
            (answer || question.status) === Status.denied
              ? 'contained'
              : 'outlined'
          }
          color="primary"
          size="small"
          onClick={() => handleAnswer('denied')}
        >
          Deny
        </Button>
      </ButtonGroup>

      <IconButton
        className={clsx(classes.expand, {
          [classes.expandOpen]: expanded,
        })}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show evidence"
        data-testid="show-evidence-button"
      >
        <ExpandMoreIcon />
      </IconButton>
    </>
  );

  const subsidiaryContent = subsidiaryDetails?.map(value => {
    const data = value.data as SubsidiaryData;

    const googleSearch = `https://www.google.com/${data?.googleSearch}`;
    const searchButton = (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<LaunchIcon />}
        onClick={() => window.open(googleSearch)}
      >
        Open Search
      </Button>
    );
    return (
      <Paper
        elevation={1}
        className={classes.cardTable}
        key={value.evidenceUid}
      >
        <TableComponent
          data={data?.sites}
          dataFormat={subsidiaryDataFormat}
          headerActions={searchButton}
          paginationEnabled
          title="Subsidiary Evidence"
          renderOnNoData={
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          }
        />
      </Paper>
    );
  });

  const EvidenceContent = (
    <>
      <CardActions className={classes.cardActions}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<AccountTreeIcon />}
          onClick={handleShowAnswers}
        >
          Show Parent Questions
        </Button>
        {question.questionType !== 'domain' && !showReverseLookup && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<LocationSearchingIcon />}
            onClick={handleDomainLookup}
            data-testid="domain-lookup-button"
          >
            Domain Lookup
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={handleAddRule}
        >
          Add Blacklist
        </Button>
      </CardActions>
      <CardContent>
        {showReverseLookup && (
          <ReverseLookupTable reverseLookupResult={reverseLookupResult} />
        )}
        {notSubsidiaryDetails && notSubsidiaryDetails.length > 0 && (
          <Paper elevation={1} className={classes.cardTable}>
            <TableComponent
              data={notSubsidiaryDetails}
              dataFormat={evidenceDataFormat}
              paginationEnabled
              title={
                question.questionType === 'name'
                  ? ' WhoIs Evidence'
                  : 'Evidence'
              }
              renderOnNoData={
                <div className={classes.loader}>
                  <CircularProgress />
                </div>
              }
            />
          </Paper>
        )}
        {question.questionType === 'name' &&
          subsidiaryDetails &&
          subsidiaryContent}
      </CardContent>
    </>
  );

  return (
    <Card
      className={classes.root}
      variant="outlined"
      data-testid="question-card"
    >
      <CardHeader
        title={title}
        subheader={
          <Typography variant={'subtitle2'} align={'left'}>
            {TypeDisplay[question.questionType]}
          </Typography>
        }
        avatar={statusAvatar}
        action={headerAction}
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {IndicatorType[question.questionType] === IndicatorType.screenshot ? (
          <ScreenshotContent details={details} media={media} />
        ) : (
          EvidenceContent
        )}
      </Collapse>
    </Card>
  );
};

export default withStyles(styles)(QuestionCard);
