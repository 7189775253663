import { createStyles, Theme } from '@material-ui/core';
import { palette } from '@/helpers/MuiThemeHelper';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      marginBottom: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    acceptedIcon: {
      color: theme.palette.secondary['700'],
    },
    deniedIcon: {
      color: theme.palette.error.main,
    },
    reverseLookup: {
      marginBottom: theme.spacing(4),
    },
    cardTable: {
      padding: theme.spacing(2),
    },
    cardActions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    reverseLookupHeader: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    reverseLookupHeaderCount: {
      color: palette.tertiary['700'],
      paddingRight: theme.spacing(),
    },
    progress: {
      padding: theme.spacing(2),
    },
    screenshotContent: {
      paddingBottom: theme.spacing(2),
    },
    fullyCentered: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      minHeight: '100%',
      justifyContent: 'center',
    },
    screenshotImage: {
      maxWidth: 800,
      padding: 0,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    domainList: {
      overflow: 'auto',
    },
    loader: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      width: '100%',
    },
    questionTitle: {
      wordBreak: 'break-all',
    },
  });
