import React, {
  ReactChild,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { EnvVars } from './EnvVars';
import { clearLocalSettings } from '@/helpers/LocalSettingsHelper';
import find from 'lodash/find';
import { useAuthContext } from './AuthContext';
import { useLayoutContext } from '@/App';

export interface OrgProps {
  active: boolean;
  cast_customer: boolean;
  contact_email_addresses?: string[];
  continuous?: boolean;
  createdAt?: string;
  id?: string;
  name?: string;
  note?: string;
  notification_preference?: string;
  organizationId?: string;
  uid?: string | null;
  updatedAt?: string;
  scanLevel?: string;
  serviceLevel?: string;
  stockSymbol?: string;
}

export enum ScanLevel {
  Inactive = 'inactive',
  Passive = 'passive',
  SafeActive = 'safeactive',
  Active = 'active',
}

export enum ServiceLevel {
  Premium = 'CAST - Premium',
  Enhanced = 'CAST - Enhanced',
  Standard = 'CAST - Standard',
  PoV = 'CAST - PoV',
  Demo = 'Sales Demo',
  Consulting = 'Consulting',
  Other = 'Other',
}

export enum ScanMode {
  Continuous = 'continuous',
  NonContinuous = 'non-continuous',
}

export enum NotificationPreference {
  None = 'none',
  Email = 'email',
}

export enum CastCustomer {
  Yes = 'yes',
  No = 'no',
}

export enum Active {
  Yes = 'yes',
  No = 'no',
}

export interface OrgContextExports {
  forceUpdateScanLevel: (scanLevel: ScanLevel) => void;
  forceUpdateSelectedOrg: (org: OrgProps) => void;
  forceUpdateOrgList: (org: OrgProps) => void;
  getOrgIdFromUrlParam: (orgId: string | null) => string | null;
  orgList?: Array<OrgProps> | undefined;
  selectedOrgInfo?: OrgProps | null | undefined;
  selectedOrg?: string | null | undefined;
  setSelectedOrg: (org: string) => void;
  urlPathOrg: string | null | undefined;
}

export interface OrgContextOverrideExports {
  orgList?: OrgContextExports['orgList'];
  selectedOrg?: OrgContextExports['selectedOrg'];
  setSelectedOrg?: OrgContextExports['setSelectedOrg'];
  urlPathOrg?: OrgContextExports['urlPathOrg'];
  getOrgIdFromUrlParam?: OrgContextExports['getOrgIdFromUrlParam'];
}

export const defaultOrgContext = {
  selectedOrg: '123',
  setSelectedOrg: () => {},
  urlPathOrg: undefined,
  getOrgIdFromUrlParam: () => null,
};

export const RootOrgContext = createContext<OrgContextExports | undefined>(
  undefined
);

interface Props extends RouteComponentProps {
  children: ReactChild;
  overrideOrgContextCalls?: OrgContextExports;
}

const OrgContext = (props: Props) => {
  const { children, overrideOrgContextCalls = {} } = props;
  const { accessToken, isAuthenticated, userInfo } = useAuthContext();
  const { setHasCriticalError } = useLayoutContext();

  const [orgList, setOrgList] = useState<OrgContextExports['orgList']>();
  const [selectedOrg, setSelectedOrg] = useState<
    OrgContextExports['selectedOrg']
  >(localStorage.getItem('selectedUid'));
  const [urlPathOrg, setUrlPathOrg] = useState<
    OrgContextExports['urlPathOrg']
  >();
  const [scanLevel, setScanLevel] = useState('');

  async function getOrgs() {
    const response = await fetch(`${EnvVars['REACT_APP_API_HOST']}/orgs`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response?.ok) {
      setHasCriticalError(
        'API Error encountered while fetching from the /orgs endpoint'
      );
      return [];
    }
    const data = await response.json();
    return data;
  }

  async function getScanLevel(orgId) {
    const response = await fetch(
      `${EnvVars['REACT_APP_API_HOST']}/v1/orgs/${orgId}/scan-levels`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response?.ok) {
      setHasCriticalError(
        'API Error encountered while fetching from the /scan-levels endpoint'
      );
      return [];
    }
    const data = await response.json();
    return data;
  }

  useEffect(() => {
    if (!accessToken) return;

    getOrgs().then(data => {
      setOrgList(data);
      const fallbackUid = data && data[0] && data[0].uid;
      const selectedOrg = localStorage.getItem('selectedUid') || fallbackUid;
      setSelectedOrg(selectedOrg);
    });
  }, [accessToken]);

  useEffect(() => {
    if (!isAuthenticated || !accessToken) {
      localStorage.removeItem('selectedUid');
      setUrlPathOrg(undefined);
    } else if (selectedOrg) {
      const oldSelectedOrg = localStorage.getItem('selectedUid');
      if (selectedOrg !== oldSelectedOrg) {
        clearLocalSettings();
      }
      localStorage.setItem('selectedUid', selectedOrg);
      setUrlPathOrg(selectedOrg);
      getScanLevel(selectedOrg).then(data => setScanLevel(data.scanLevel));
    }
  }, [isAuthenticated, accessToken, userInfo, selectedOrg]);

  const selectedOrgInfo = useMemo(() => {
    if (!orgList || !selectedOrg) return null;
    const orgInfo: OrgProps | undefined = orgList.find(value => {
      return value.uid === selectedOrg;
    });
    return { ...orgInfo, scanLevel };
  }, [orgList, selectedOrg, scanLevel]);

  const getOrgIdFromUrlParam = (orgId: string | null | undefined) => {
    const orgData = find(orgList, { uid: orgId });
    if (!orgData || !orgId) return null;
    return orgId;
  };

  const forceUpdateSelectedOrg = (org: OrgProps) => {
    const orgIndex = orgList?.findIndex(o => o.uid === org.uid);

    if (orgIndex && orgIndex > -1) {
      const newOrgList = [...(orgList || [])];
      newOrgList[orgIndex] = { ...newOrgList[orgIndex], ...org };

      setOrgList(newOrgList);
    }
  };

  const forceUpdateOrgList = (org: OrgProps) => {
    setOrgList([...(orgList || []), org]);
  };

  const forceUpdateScanLevel = (scanLevel: ScanLevel) => {
    setScanLevel(scanLevel);
  };

  const defaultContext = {
    orgList,
    selectedOrgInfo,
    selectedOrg,
    setSelectedOrg,
    forceUpdateSelectedOrg,
    forceUpdateScanLevel,
    forceUpdateOrgList,
    urlPathOrg,
    getOrgIdFromUrlParam,
    ...overrideOrgContextCalls,
  };

  return (
    <RootOrgContext.Provider value={defaultContext}>
      {children}
    </RootOrgContext.Provider>
  );
};

export const useOrgContext = () => {
  const context = useContext(RootOrgContext);
  if (!context) {
    throw new Error(`useOrgContext must be called within OrgContextProvider`);
  }
  return context;
};

export const useUnsetOrg = () => {
  const { selectedOrg, setSelectedOrg } = useOrgContext();

  useEffect(() => {
    setSelectedOrg('');
  }, [selectedOrg]);
};

export default withRouter(OrgContext);
