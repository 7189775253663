import { matchPath } from 'react-router';
export const drawerWidth = 360;
export const isSelected = (path: string = '/', currentLocation: string) => {
  const match = matchPath(currentLocation, {
    path,
    exact: true,
    strict: false,
  });

  return !!match?.isExact;
};
