import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
    },
    questionList: {
      overflow: 'auto',
      height: '100%',
      marginBottom: theme.spacing(4),
    },
    header: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      backgroundColor: theme.palette.background.paper,
    },
    headerLabel: {
      paddingRight: theme.spacing(4),
    },
    questions: {
      padding: `0 ${theme.spacing(2)}px`,
      width: '100%',
    },
    searchInput: {
      width: '100%',
      maxWidth: '480px',
    },
    filterButton: {
      marginRight: theme.spacing(),
    },
    reactivateButton: {
      marginRight: theme.spacing(),
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
    loadMore: {
      justifyContent: 'center',
    },
  });
