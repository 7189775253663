import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    tableLayoutFixed: {
      tableLayout: 'fixed',
    },
    colorBlock: {
      display: 'inline-block',
      marginRight: theme.spacing(2),
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    tableHeader: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      flex: '1 0 auto',
    },
    headerItem: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    headerSearchBar: {
      flexGrow: 1,
    },
    headerTitle: {
      textAlign: 'left',
    },
    hidden: {
      display: 'none',
    },
    tableRowHasHover: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    colCanHover: {
      '&:hover': {
        backgroundColor: theme.palette.primary['800'],
        color: '#ffffff',
        borderRadius: '4px',
      },
    },
    colCaption: {
      textAlign: 'right',
    },
    searchInput: {
      width: '100%',
    },
    filterButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
      },
    },
    filterButton: {
      marginLeft: 10,
    },
    tableUtilsLeft: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    loaderContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      width: '100%',
    },
    loader: {
      margin: '30px 0',
    },
  });
