import {
  Detail,
  Discovery,
  DiscoverySummary,
  MostQuestions,
  QuestionContext,
  Rule,
} from '@/types/discovery';
import React, {
  ReactChild,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  generateDiscoveryIdPath,
  routeDomainDiscoveryParents,
  routeDomainDiscoveryQuestions,
} from '@/helpers/RouteHelper';

import { EnvVars } from '@/hooks/EnvVars';
import { useAuthContext } from './AuthContext';
import { useLayoutContext } from '@/App';
import { useRouteToPage } from '@/hooks/UseRouteToPage';

export interface DomainDiscoveryContextExports {
  summary?: DiscoverySummary | undefined;

  discoveryList?: Array<Discovery> | undefined;
  selectedDiscovery?: Discovery | undefined;
  setSelectedDiscoveryFromUid: (uid: String) => void;
  setUpdatedDiscovery: (discovery: Discovery) => void;

  selectedIndicator?: QuestionContext | undefined;
  setSelectedIndicator: (indicator: QuestionContext | undefined) => void;

  reverseLookup: (
    questionUid: string,
    setData: (ReverseLookupResult) => void,
    setError: (value: boolean) => void
  ) => void;

  fetchIndicatorDetails: (
    questionUid: string,
    setData: (details: Detail[]) => void
  ) => void;

  ruleMatches: (rule: Rule, setData: (matches: string[]) => void) => void;
  reactivate: (completed: () => void, category?: string) => void;
}

export interface DomainDiscoveryContextOverrideExports {
  summary?: DiscoverySummary | undefined;

  discoveryList?: DomainDiscoveryContextExports['discoveryList'];
  selectedDiscovery?: DomainDiscoveryContextExports['selectedDiscovery'];
  setSelectedDiscoveryFromUid?: DomainDiscoveryContextExports['setSelectedDiscoveryFromUid'];
  setUpdatedDiscovery?: DomainDiscoveryContextExports['setUpdatedDiscovery'];

  selectedIndicator?: DomainDiscoveryContextExports['selectedIndicator'];
  setSelectedIndicator?: DomainDiscoveryContextExports['setSelectedIndicator'];

  reverseLookup?: DomainDiscoveryContextExports['reverseLookup'];
  fetchIndicatorDetails?: DomainDiscoveryContextExports['fetchIndicatorDetails'];
  ruleMatches?: DomainDiscoveryContextExports['ruleMatches'];
  reactivate?: DomainDiscoveryContextExports['reactivate'];
}

interface GetRequest {
  path: string;
  query?: object;
  setError?: (value: string | null) => void;
  timeout?: number;
}

export const RootDomainDiscoveryContext = createContext<
  DomainDiscoveryContextExports | undefined
>(undefined);

interface Props {
  children: ReactChild;
  overrideDomainDiscoveryContextCalls?: DomainDiscoveryContextExports;
}

const DomainDiscoveryContext = (props: Props) => {
  const { children, overrideDomainDiscoveryContextCalls = {} } = props;
  const { accessToken } = useAuthContext();
  const { setHasCriticalError, setHasError } = useLayoutContext();
  const [routeToPage] = useRouteToPage();

  const [selectedDiscovery, setSelectedDiscovery] = useState();
  const [updatedDiscovery, setUpdatedDiscovery] = useState();
  const [discoveryList, setDiscoveryList] = useState<
    DomainDiscoveryContextExports['discoveryList']
  >();

  const [summary, setSummary] = useState<DiscoverySummary>();
  const [selectedIndicator, setSelectedIndicator] = useState<QuestionContext>();

  const setSelectedDiscoveryFromUid = (uid: String) => {
    if (!discoveryList) return;

    const discovery: Discovery | undefined = discoveryList.find(value => {
      return value.uid === uid;
    });

    setSelectedDiscovery(discovery);

    if (!discovery) {
      setSummary(undefined);
      setSelectedIndicator(undefined);
    }
  };

  const handleIndicatorSelect = (indicator: QuestionContext | undefined) => {
    setSelectedIndicator(indicator);
  };

  useEffect(() => {
    if (!selectedDiscovery || !selectedIndicator) return;

    if (
      selectedIndicator.indicatorType === MostQuestions.mostQuestions &&
      !selectedIndicator.uid
    ) {
      get({
        path: `/v1/discoveries/${selectedDiscovery.uid}/indicators`,
        query: {
          flags: 'mostQuestions',
        },
      }).then(indicators => {
        if (!indicators || indicators.length === 0) return;

        setSelectedIndicator({
          indicatorType: MostQuestions.mostQuestions,
          name: indicators[0].name,
          uid: indicators[0].uid,
        });
      });
    }

    const selectedRoute =
      selectedIndicator.uidType === 'output'
        ? routeDomainDiscoveryParents()
        : routeDomainDiscoveryQuestions();

    let path = generateDiscoveryIdPath(selectedRoute, selectedDiscovery.uid);

    if (selectedIndicator.uid) {
      path = `${path}?indicatorUid=${selectedIndicator.uid}`;
      path = `${path}&indicatorName=${selectedIndicator.name}`;
      path = `${path}&indicatorType=${selectedIndicator.indicatorType}`;
    }

    routeToPage(path);
  }, [selectedIndicator]);

  const encodeQuery = data => {
    if (!data) return '';

    return (
      '?' +
      Object.keys(data)
        .map(function(key) {
          return [key, data[key]].map(encodeURIComponent).join('=');
        })
        .join('&')
    );
  };

  async function get(req: GetRequest) {
    const controller = new AbortController();
    const signal = controller.signal;

    if (req.timeout) {
      setTimeout(() => controller.abort(), req.timeout);
    }

    const response = await fetch(
      `${EnvVars['REACT_APP_API_HOST']}${req.path}${encodeQuery(req.query)}`,
      {
        signal,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response?.ok) {
      const data = await response?.json();
      const errorMessage = `Error fetching from ${req.path}. ${
        data?.error ? `Reason: ${data?.error}` : ''
      }`;
      req.setError
        ? req.setError(errorMessage)
        : setHasCriticalError(errorMessage);

      return undefined;
    }
    return await response.json();
  }

  async function getDiscoveryList() {
    return get({ path: '/v1/discoveries' });
  }

  async function getDiscoverySummary() {
    if (!selectedDiscovery) {
      return {};
    }
    return get({
      path: `/v1/discoveries/${selectedDiscovery.uid}/status`,
      setError: () => {},
      timeout: 10000,
    });
  }

  function reverseLookup(
    questionUid: string,
    setData: (ReverseLookupResult) => void,
    setError: (value: boolean) => void
  ) {
    if (!questionUid) {
      return;
    }

    get({
      path: `/v1/discoveries/${selectedDiscovery.uid}/questions/${questionUid}/domains`,
      setError: (value: string | null) => {
        setHasError(value);
        setError(!!value);
      },
    }).then(data => {
      return setData(data);
    });
  }

  function fetchIndicatorDetails(
    indicatorUid: string,
    setData: (details: Detail[]) => void
  ) {
    if (!indicatorUid) {
      return;
    }

    get({
      path: `/v1/discoveries/${selectedDiscovery.uid}/indicators/${indicatorUid}/details`,
      setError: (value: string | null) => {
        setHasError(value);
      },
    }).then(data => {
      return setData(data);
    });
  }

  function ruleMatches(rule: Rule, setData: (matches: string[]) => void) {
    get({
      path: `/v1/discoveries/${selectedDiscovery.uid}/indicators`,
      query: {
        name: rule.pattern,
        type: rule.vertexType,
      },
      setError: setHasError,
    }).then(data => {
      return setData(data?.map(i => i.name));
    });
  }

  function reactivate(completed: () => void, category?: string) {
    const query = category ? { category } : undefined;

    get({
      path: `/v1/discoveries/${selectedDiscovery.uid}/reactivate`,
      setError: setHasError,
      query,
    }).then(() => {
      completed();
    });
  }

  useEffect(() => {
    if (!accessToken) return;

    getDiscoveryList().then(data => {
      setDiscoveryList(data);
    });
  }, [accessToken, updatedDiscovery]);

  useEffect(() => {
    if (!accessToken || !selectedDiscovery) return;

    getDiscoverySummary().then(data => {
      setSummary(data);
    });
  }, [accessToken, selectedDiscovery]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!accessToken || !selectedDiscovery) return;

      getDiscoverySummary().then(data => {
        if (JSON.stringify(data) !== JSON.stringify(summary)) {
          setSummary(data);
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [accessToken, selectedDiscovery, summary]);

  const defaultContext = {
    summary,

    discoveryList,
    selectedDiscovery,
    setSelectedDiscoveryFromUid,
    setUpdatedDiscovery,

    selectedIndicator,
    setSelectedIndicator: handleIndicatorSelect,

    reverseLookup,
    fetchIndicatorDetails,
    ruleMatches,
    reactivate,
    ...overrideDomainDiscoveryContextCalls,
  };

  return (
    <RootDomainDiscoveryContext.Provider value={defaultContext}>
      {children}
    </RootDomainDiscoveryContext.Provider>
  );
};

export const useDomainDiscoveryContext = () => {
  const context = useContext(RootDomainDiscoveryContext);
  if (!context) {
    throw new Error(
      `useDomainDiscoveryContext must be called within DomainDiscoveryContextProvider`
    );
  }
  return context;
};

export default DomainDiscoveryContext;
