import { buildQueryString, convertKeyValueToParam } from './RouteHelper';

import { DomainDiscoveryContextExports } from '@/hooks/DomainDiscoveryContext';
import { EnvVars } from '@/hooks/EnvVars';
import { OrgContextExports } from '@/hooks/OrgContext';
import { addDays } from 'date-fns';
import { stripMinutesFromDate } from './DataHelper';

export interface ApiParams {
  apiHost: string;
  buUid?: string;
  currentUserId?: string;
  selectedOrg?: OrgContextExports['selectedOrg'];
  selectedOrgInfo?: OrgContextExports['selectedOrgInfo'];
  selectedDiscovery?: DomainDiscoveryContextExports['selectedDiscovery'];
  selectedConnector?: string;
  domain?: string;
  id?: string;
  rule?: string;
  source?: string;
}

export type ApiTypes = OrgApiTypes | DiscoveryApiTypes;

export type OrgApiTypes =
  | 'bus'
  | 'createBus'
  | 'createDomain'
  | 'createDomains'
  | 'createOrg'
  | 'createUser'
  | 'feedback'
  | 'findings'
  | 'orgs'
  | 'resendWelcomeEmail'
  | 'resetMfa'
  | 'scanLevels'
  | 'scanLevelsByOrg'
  | 'updateOrg'
  | 'updateUser'
  | 'updateScanLevel'
  | 'users'
  | 'userPreferences';

export type DiscoveryApiTypes =
  | 'answers'
  | 'connectorCounts'
  | 'connectorDetails'
  | 'copyDiscovery'
  | 'createDiscovery'
  | 'createRule'
  | 'deleteDiscovery'
  | 'deleteRule'
  | 'discoveryAnswers'
  | 'discoveryResults'
  | 'discoveryExport'
  | 'discoveryErrors'
  | 'discoveryRules'
  | 'indicators'
  | 'questions'
  | 'rules'
  | 'screenshots'
  | 'seeds'
  | 'testingActivity'
  | 'updateDiscovery'
  | 'updateRule'
  | 'connectorAggregates'
  | 'orgConnectors'
  | 'rerun';

export type ApiCallback = (params: ApiParams) => string;

export type ApiUrlsSignature = { [key in ApiTypes]: ApiCallback };

export const getDateRange = (numDaysInPast: number) => {
  if (numDaysInPast <= 0) return '';
  const endDate = stripMinutesFromDate(new Date());
  const startDate = addDays(endDate, -1 * numDaysInPast);
  return buildQueryString([
    convertKeyValueToParam('start', startDate.toISOString()),
    convertKeyValueToParam('end', endDate.toISOString()),
  ]);
};

export const apiUrls: ApiUrlsSignature = {
  answers: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery &&
      params.selectedDiscovery.uid}/answers`,
  bus: (params: ApiParams) =>
    `${params.apiHost}/orgs/${params.selectedOrgInfo &&
      params.selectedOrgInfo.organizationId}/bus`,
  connectorCounts: (params: ApiParams) =>
    `${params.apiHost}/v2/connectors?limit=0`,
  connectorDetails: (params: ApiParams) =>
    `${params.apiHost}/v2/orgs/${params.selectedOrg}/connectors/${params.selectedConnector}`,
  copyDiscovery: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/copy`,
  createBus: (params: ApiParams) => `${params.apiHost}/orgs/${params.id}/bus`,
  createDiscovery: (params: ApiParams) => `${params.apiHost}/v1/discoveries`,
  createDomain: (params: ApiParams) =>
    `${params.apiHost}/orgs/${params.id}/bus/${params.buUid}/domains/${params.source}`,
  createDomains: (params: ApiParams) =>
    `${params.apiHost}/v1/orgs/${params.id}/domains`,
  createOrg: (params: ApiParams) => `${params.apiHost}/orgs`,
  createRule: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/rules`,
  createUser: (params: ApiParams) => `${params.apiHost}/users`,
  deleteDiscovery: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.id}`,
  deleteRule: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/rules/${params.rule}`,
  discoveryResults: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/results`,
  discoveryExport: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/results/export`,
  discoveryAnswers: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/answers`,
  discoveryErrors: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/errors`,
  discoveryRules: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/rules`,
  indicators: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/indicators`,
  feedback: (params: ApiParams) => `${params.apiHost}/feedback`,
  findings: (params: ApiParams) =>
    `${params.apiHost}/latest/findings-reports/totals`,
  orgs: (params: ApiParams) => `${params.apiHost}/orgs`,
  userPreferences: (params: ApiParams) =>
    `${params.apiHost}/users/${params.currentUserId}/preferences/${EnvVars['REACT_APP_AUTH_CLIENT']}`,
  questions: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/questions`,
  rules: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/rules`,
  resendWelcomeEmail: (params: ApiParams) =>
    `${params.apiHost}/users/${params.id}/emails/verification`,
  resetMfa: (params: ApiParams) =>
    `${params.apiHost}/users/${params.id}/mfa/reset`,
  scanLevels: (params: ApiParams) => `${params.apiHost}/v1/scan-levels`,
  scanLevelsByOrg: (params: ApiParams) =>
    `${params.apiHost}/v1/orgs/${params.id}/scan-levels`,
  screenshots: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/screenshots/${params.id}/image`,
  seeds: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/seeds`,
  testingActivity: (params: ApiParams) =>
    `${params.apiHost}/latest/testing-activities-reports/totals`,
  updateOrg: (params: ApiParams) => `${params.apiHost}/orgs/${params.id}`,
  updateRule: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.selectedDiscovery?.uid}/rules/${params.rule}`,
  updateScanLevel: (params: ApiParams) =>
    `${params.apiHost}/v1/orgs/${params.id}/scan-levels`,
  updateDiscovery: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.id}`,
  updateUser: (params: ApiParams) => `${params.apiHost}/users/${params.id}`,
  users: (params: ApiParams) => `${params.apiHost}/users`,
  connectorAggregates: (params: ApiParams) =>
    `${params.apiHost}/v2/orgs/${params.selectedOrg}/reports/aggregate-connectors-by-cloud`,
  orgConnectors: (params: ApiParams) =>
    `${params.apiHost}/v2/orgs/${params.selectedOrg}/connectors`,
  rerun: (params: ApiParams) =>
    `${params.apiHost}/v1/discoveries/${params.id}/rerun`,
};
