import { createStyles, Theme } from '@material-ui/core';

import { palette } from '@/helpers/MuiThemeHelper';

export const styles = (theme: Theme) =>
  createStyles({
    cardContainer: {
      overflow: 'auto',
      width: '100%',
      padding: theme.spacing(),
    },
    cardInnerContainer: {
      minWidth: 360,
      display: 'flex',
      flexWrap: 'nowrap',
    },
    cardGroup: {
      '&:not(:last-child)': {
        paddingRight: theme.spacing(),
      },
    },
    cardGroupTitle: {
      width: '100%',
      textTransform: 'uppercase',
      borderBottom: '2px solid #000',
      fontSize: 12,
    },
    cardHighlightError: {
      color: theme.palette.error['main'],
    },
    cardHighlightUnanswered: {
      color: theme.palette.error['light'],
    },
    cardHighlightAnswered: {
      color: palette.secondary['700'],
    },
    cardStatus: {
      color: palette.tertiary['700'],
    },
    cardQuestions: {
      color: 'grey',
    },
    cardAnswers: {
      color: palette.secondary['700'],
    },
    cardDomainsHigh: {
      color: palette.secondary['700'],
    },
    cardDomainsLow: {
      color: theme.palette.error['light'],
    },
    cardDomainsNone: {
      color: 'grey',
    },
  });
