import { OrgProps, ServiceLevel } from '@/hooks/OrgContext';
import { countBy, last, mapValues, uniq, values } from 'lodash';

import { Bins } from '@visx/mock-data/lib/generators/genBins';
import { format } from 'date-fns';
import { getPublicSuffix } from 'tldts';
import ip from 'ip';

export const sortAsc = (a, b) => {
  if (a === b) return 0;
  return a > b ? 1 : -1;
};

export const sortDesc = (a, b) => {
  if (a === b) return 0;
  return a > b ? -1 : 1;
};

export const stableSortAsc = (a: object, b: object, orderBy: string) => {
  const aOrderBy =
    typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy];
  const bOrderBy =
    typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy];
  if (!bOrderBy && aOrderBy) return 1;
  if (bOrderBy && !aOrderBy) return -1;
  if (bOrderBy > aOrderBy) {
    return -1;
  }
  if (bOrderBy < aOrderBy) {
    return 1;
  }
  return 0;
};

export const stableSortDesc = (a: object, b: object, orderBy: string) => {
  const aOrderBy =
    typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy];
  const bOrderBy =
    typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy];
  if (!bOrderBy && aOrderBy) return 1;
  if (bOrderBy && !aOrderBy) return -1;
  if (bOrderBy < aOrderBy) {
    return -1;
  }
  if (bOrderBy > aOrderBy) {
    return 1;
  }
  return 0;
};

export const stableSort = (
  array: any[],
  cmp: (a: object, b: object) => number
) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const sortByField = (
  array: Array<any>,
  field: string,
  order: 'asc' | 'desc'
) => {
  const sortMethod = order === 'asc' ? stableSortAsc : stableSortDesc;
  return stableSort(array, (a, b) => sortMethod(a, b, field));
};

export const mergeRefData = (
  refData: any[],
  sourceData: any[],
  refField: string,
  sourceField: string,
  targetField: string
) => {
  if (!sourceData || !refData) return null;
  const mappedRef = refData.reduce((mappedRefPartial, refItem) => {
    const { [refField]: targetData, ...rest } = refItem;
    return { ...mappedRefPartial, [targetData]: { ...rest } };
  }, {});
  return sourceData.map(sourceItem => {
    const targetData = mappedRef[sourceItem[sourceField]];
    return { ...sourceItem, [targetField]: targetData };
  });
};

type RowWithCidr = { cidr?: string };

export const isIpv4 = (address: RowWithCidr) =>
  address && address.cidr && address.cidr.indexOf(':') < 0;

export const isIpv6 = (address: RowWithCidr) =>
  address && address.cidr && address.cidr.indexOf(':') >= 0;

export const calculateAvailableIps = (row: RowWithCidr) => {
  const cidr = row.cidr;
  if (!cidr) return 0;
  const subnet = ip.cidrSubnet(cidr);

  const cidrVal = Number(last(cidr.split('/')));
  let availableCount: number = 0;
  if (isIpv6(row)) {
    availableCount = Math.floor(Math.pow(2, 128 - cidrVal));
  } else {
    availableCount = subnet.numHosts;
  }
  return availableCount;
};

export const calculateIpCounts = (sourceData: any[] | null) => {
  if (!sourceData) return null;
  return sourceData.map(sourceItem => {
    if (!sourceItem) return {};
    return {
      ...sourceItem,
      availableIps: calculateAvailableIps(sourceItem),
    };
  });
};

type RowWithDomain = { domain: string };

export const getTopLevelDomains = (data: RowWithDomain[]) => {
  if (!data || data.length <= 0) return [];
  const countByValues = countBy(data, item => getPublicSuffix(item.domain));
  const mappedValues = values(
    mapValues(countByValues, (value, key) => ({ x: key, y: value }))
  );
  const sortedValues = stableSort(mappedValues, (a, b) =>
    stableSortDesc(a, b, 'y')
  );
  const filteredValues = sortedValues.slice(0, 6);
  const filteredCount = filteredValues.reduce((sum, row) => {
    return sum + row.y;
  }, 0);
  const otherCount: number = data.length - filteredCount;
  if (otherCount === 0) return filteredValues;
  return [...filteredValues, { x: 'Other', y: otherCount }];
};

type RowWithExpiry = {
  domain: string | null | undefined;
  expiry: string | null | undefined;
};

export const getExpiredDomains = (data: RowWithExpiry[]) => {
  if (!data) return [];
  const currentDate = new Date();
  const filteredData = data.reduce(
    (rows: RowWithExpiry[], row: RowWithExpiry) => {
      if (!row.expiry) return rows;
      const date = new Date(row.expiry);
      if (date > currentDate) return rows;
      return [...rows, row];
    },
    []
  );
  return stableSort(filteredData, (a, b) => stableSortDesc(a, b, 'expiry'));
};

export const getExpiringDomains = (
  data: RowWithExpiry[],
  daysDelta: number
) => {
  if (!data) return [];
  const currentDate = new Date();
  const currentDatePlusDelta = new Date();
  currentDatePlusDelta.setDate(currentDatePlusDelta.getDate() + daysDelta);
  const filteredData = data.reduce(
    (rows: RowWithExpiry[], row: RowWithExpiry) => {
      if (!row.expiry) return rows;
      const date = new Date(row.expiry);
      if (date <= currentDate || date > currentDatePlusDelta) return rows;
      return [...rows, row];
    },
    []
  );
  return stableSort(filteredData, (a, b) => stableSortDesc(a, b, 'expiry'));
};

export const getExpiringExport = (data: RowWithExpiry[], daysDelta: number) => {
  if (!data) return [];
  const currentDatePlusDelta = new Date();
  currentDatePlusDelta.setDate(currentDatePlusDelta.getDate() + daysDelta);
  const filteredData = data.reduce(
    (rows: RowWithExpiry[], row: RowWithExpiry) => {
      if (!row.expiry) return rows;
      const date = new Date(row.expiry);
      if (date > currentDatePlusDelta) return rows;
      const { domain, expiry } = row;
      return [...rows, { domain, expiry }];
    },
    []
  );
  return stableSort(filteredData, (a, b) => stableSortAsc(a, b, 'expiry'));
};

export const pluralizeString = (
  str: string,
  count: number,
  ending: string = ''
) => {
  if (count === 1) return `${str}${ending}`;
  return `${str}s${ending}`;
};

export const replaceHeaderRowKey = (key, columns) => {
  if (!columns || columns.length === 0) return key;
  const newKeyObj = columns.find(col => col.dataKey === key);
  return newKeyObj ? newKeyObj.label : key;
};

export const formatDataForExport = value => {
  const dateFromValue = Date.parse(value);
  if (
    !isNaN(dateFromValue) &&
    typeof value !== 'number' &&
    !value.includes('://')
  ) {
    return format(dateFromValue, 'YYYY-MM-DD');
  }
  if (typeof value === 'string' && value.endsWith('\r'))
    return value.slice(0, -1);
  return value;
};

export const jsonFormatReplacer = (key, value, columns) => {
  if (value === null) return '';
  if (typeof value === 'string') {
    return formatDataForExport(value);
  } else if (Array.isArray(value)) {
    const newValue: any[] = [];
    for (const valKey in value) {
      if (Object.hasOwnProperty.call(value, valKey)) {
        const item = value[valKey];
        const newItem = {};
        for (const itemKey in item) {
          const newItemKey = replaceHeaderRowKey(itemKey, columns);
          newItem[newItemKey] = formatDataForExport(item[itemKey]);
        }
        newValue[valKey] = newItem;
      }
    }
    return newValue;
  }
  return value;
};

export const formatAndStringifyJSON = (items, replacer, columns) => {
  const newItems = replacer(0, items, columns);
  return JSON.stringify(newItems);
};

export const jsonToCSV = (items, replacer, columns) => {
  if (!items || items.length <= 0) return '';
  const newItems = replacer(0, items, columns);
  const header = columns
    ? columns.map(col => col.label)
    : Object.keys(newItems[0]);
  let csv = newItems.map(row =>
    header.map(fieldName => JSON.stringify(row[fieldName])).join(',')
  );
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');

  return csv;
};

export const getPageTitle = (title: string) => `${title} | Bishop Fox Admin`;

export const formatDate = (date: Date) => {
  if (!date) return null;
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
};

export const formatDateString = (dateStr: string) => {
  if (!dateStr) return null;
  const date = new Date(dateStr);
  return formatDate(date);
};

export const buildUrlFromSchemeHostPort = (row: any) => {
  if (!row) return null;
  const { scheme, hostname, port } = row;
  const fullScheme = scheme ? `${scheme}://` : '';
  const fullPort = port ? `:${port}` : '';
  return `${fullScheme}${hostname}${fullPort}`;
};

export const uniqueValues = (data: any[] | null | undefined, key: string) => {
  if (!data || data.length <= 0) return [];
  return uniq(data.map(item => item[key]));
};

export const getPercentString = (part, whole) => {
  if (!whole || whole <= 0) return '0';
  const percentValue = ((part / whole) * 100).toPrecision(2);
  return `${addCommas(part)} (${percentValue}%)`;
};

export const stripMinutesFromDate = (sentDate: Date) => {
  var result = new Date(sentDate);
  result.setMinutes(0);
  result.setSeconds(0);
  result.setMilliseconds(0);
  return result;
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const addCommas = (intNum: number | string | null) => {
  if (intNum && intNum.toString().length > 3)
    return (intNum + '').replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  return intNum;
};

export const getHeatmapDates = (data: any) => {
  const baseArray = Array(12).fill('');

  if (!data) return baseArray;

  const dates = data
    .sort((a, b) => sortAsc(a.date, b.date))
    .reduce(
      (acc, cur) => (acc.includes(cur.date) ? acc : [...acc, cur.date]),
      [] as string[]
    );

  if (dates.length < 12) {
    return [...baseArray, ...dates].splice(-12);
  }

  return dates;
};

export const getHeatmapOrgs = (data: any, orgList: OrgProps[]) => {
  if (!data || !orgList) return [''];

  const validServiceLevels = [
    ServiceLevel.Premium,
    ServiceLevel.Enhanced,
    ServiceLevel.Standard,
  ];

  const validOrgs = orgList.filter(({ active, serviceLevel }) => {
    return (
      validServiceLevels.includes(serviceLevel as ServiceLevel) &&
      active === true
    );
  });

  return validOrgs
    .sort((a, b) => sortDesc(a.name, b.name))
    .map(({ name }, id) => ({ orgName: name, id }));
};

export const getHeatmapBinData = (
  data: any[] = [],
  dates: string[],
  orgs: any[]
): Bins[] => {
  const defaultBinData = [{ bin: 0, bins: [{ bin: 0, count: 0 }] }];

  if (!data || !dates || !orgs) return defaultBinData;

  const totalBin = [
    { bin: dates.length, bins: orgs.map((_, idx) => ({ bin: idx, count: 0 })) },
  ];

  return [
    ...dates.map((date, idx) => {
      return {
        bin: idx,
        bins: orgs.map(({ orgName, id }) => {
          const count = data
            .filter(d => d.orgName === orgName && d.date === date)
            .reduce((acc, { total }) => acc + total, 0);

          if (id !== undefined) {
            totalBin[0].bins[id].count += count;
          }

          return {
            bin: id,
            count,
          };
        }),
      };
    }, defaultBinData),
    ...totalBin,
  ];
};
