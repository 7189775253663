import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const OverflowTooltip = props => {
  const textElementRef = useRef();

  useEffect(() => compareSize());

  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  return (
    <Tooltip
      title={<span style={{ fontSize: 15 }}>{props.value}</span>}
      disableHoverListener={!hoverStatus}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
      placement="top-start"
    >
      <div ref={textElementRef}>{props.value}</div>
    </Tooltip>
  );
};

export default OverflowTooltip;
