import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { default as React, useEffect, useState } from 'react';
import TableComponent, { DataFormatProps } from '@/components/Table';

import DomainDiscoverySummary from '@/components/discovery/Summary';
import PageLoader from '@/components/PageLoader';
import StatusIcon from '@/components/discovery/StatusIcon';
import UpdateDiscoveryAnswer from '@/components/discovery/UpdateAnswer';
import capitalize from '@material-ui/core/utils/capitalize';
import { defaultAnswer } from '@/helpers/DiscoveryHelper';
import { styles } from '@/screens/DiscoveryAnswers/styles';
import { useApiData } from '@/hooks/UseApiData';
import { useApiPost } from '@/hooks/UseApiPost';
import { useUnsetOrg } from '@/hooks/OrgContext';
import { useLayoutContext } from '@/App';
import { useDrawer } from '@/hooks/UseDrawer';
import {
  AnsweredQuestion,
  IndicatorType,
  Status,
  TypeDisplay,
} from '@/types/discovery';
import { buildQueryParams } from '@/helpers/RouteHelper';

interface Props extends WithStyles<typeof styles> {}

const DiscoveryAnswers = ({ classes }: Props) => {
  const pageTitle = 'Discovery Answers';

  const [answersList, setAnswersList] = useState<AnsweredQuestion[]>();

  const { selectedDiscovery } = useDomainDiscoveryContext();
  const { setError } = useLayoutContext();
  const [isSidebarOpen, setIsSidebarOpen] = useDrawer();
  const [currentAnswer, setCurrentAnswer] = useState<AnsweredQuestion>(
    defaultAnswer
  );
  const [search, setSearch] = useState<string | null>(null);

  const [postData, setPostData] = useState();
  const [submitAnswers] = useApiPost('answers', setPostData, setError);

  // set index to let us directly update the row changed
  const setList = data => {
    const newAnswerList = data.map((answer, index) => ({ ...answer, index }));
    setAnswersList(newAnswerList);
  };

  useApiData({
    apiType: 'indicators',
    setData: setList,
    setError,
    queryParams: buildQueryParams({
      status: `${Status.approved},${Status.denied}`,
      type: Object.keys(IndicatorType)
        .filter(t => IndicatorType[t] !== IndicatorType.domain)
        .join(','),
      name: search,
    }),
    sourceDataPopulated: !!selectedDiscovery?.uid,
  });

  const handleSubmitAnswer = answer => {
    const answerData = [
      {
        uid: answer.uid,
        answer: answer.status,
      },
    ];
    setCurrentAnswer(answer);
    submitAnswers(answerData, 'json', 'POST');
  };

  // Always unselect org since it doesn't relate to this screen
  useUnsetOrg();

  // Update after successful answers action
  useEffect(() => {
    if (postData) {
      setIsSidebarOpen(false);
    }
  }, [postData]);

  useEffect(() => {
    if (!answersList || !currentAnswer || !answersList[currentAnswer.index]) {
      return;
    }
    answersList[currentAnswer.index].status = Status[currentAnswer.status];
  }, [currentAnswer, answersList]);

  if (!answersList) {
    return <PageLoader pageTitle={pageTitle} />;
  }

  const answerSelectedHandler = rowData => {
    if (!rowData || !rowData.uid) return;
    setCurrentAnswer(rowData);
    setIsSidebarOpen(true);
  };

  const columns: DataFormatProps[] = [
    {
      title: '',
      field: 'status',
      width: 60,
      render: ({ status }) => (
        <Grid
          container
          item
          alignContent="center"
          justify="center"
          className={classes.statusIcon}
        >
          <StatusIcon status={status} />
        </Grid>
      ),
    },
    {
      title: 'Type',
      field: 'questionType',
      render: ({ questionType }) => TypeDisplay[questionType],
      width: 120,
      searchable: true,
    },
    {
      title: 'Name',
      field: 'name',
      width: 160,
      searchable: true,
    },
    {
      title: 'Status',
      field: 'status',
      render: ({ status }) => capitalize(status),
      width: 90,
      searchable: true,
    },
    {
      title: 'Rule',
      field: 'statusReason',
      width: 120,
    },
  ];

  const data = answersList.length > 0 ? answersList : [];

  return (
    <>
      <DomainDiscoverySummary pageTitle={pageTitle} />
      <Grid
        container
        direction="row"
        alignItems="stretch"
        className={classes.answersTable}
      >
        <TableComponent
          data={data}
          dataFormat={columns}
          paginationEnabled
          rowsPerPage={25}
          sortEnabled={true}
          defaultSortOrder={{
            orderBy: 'name',
            order: 'asc',
            orderId: 2,
          }}
          onRowClick={(_event, rowData) => {
            answerSelectedHandler(rowData);
          }}
          search={{
            enabled: true,
            title: 'Search by Name, Type, or Status',
          }}
          searchFormat={columns}
          onSearch={searchTerm => setSearch(searchTerm)}
        />
      </Grid>
      <UpdateDiscoveryAnswer
        currentAnswer={currentAnswer!}
        isOpen={isSidebarOpen}
        setIsOpen={setIsSidebarOpen}
        onUpdateAnswer={answer => {
          handleSubmitAnswer(answer);
        }}
      />
    </>
  );
};

export default withStyles(styles, { withTheme: true })(DiscoveryAnswers);
