import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  WithStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { useLayoutContext } from '@/App';
import { KeyboardArrowRightIcon } from '@/images/Icons';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import RuleForm from '@/components/discovery/RuleDrawer/RuleForm';
import styles from '@/components/discovery/RuleDrawer/styles';
import { useApiPost } from '@/hooks/UseApiPost';
import { useApiDelete } from '@/hooks/UseApiDelete';
import { Rule } from '@/types/discovery';

interface Props extends WithStyles<typeof styles> {
  currentRule: Rule;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  ruleAdded?: (rule: Rule) => void;
  ruleUpdated?: (rule: Rule) => void;
  ruleDeleted?: (rule: Rule) => void;
  theme: Theme;
}

const RulesDrawer = ({
  classes,
  currentRule,
  ruleAdded,
  ruleUpdated,
  ruleDeleted,
  isOpen,
  theme,
  setIsOpen,
}: Props) => {
  const breakpointIsLgPlus = useMediaQuery(theme.breakpoints.up('lg'));
  const { setError } = useLayoutContext();
  const {
    selectedDiscovery,
    setSelectedIndicator,
  } = useDomainDiscoveryContext();

  const [ruleError, setRuleError] = useState();
  const [postCreateData, setPostCreateData] = useState();
  const [postUpdateData, setPostUpdateData] = useState();
  const [postDeleteData, setPostDeleteData] = useState();

  const [updateRule] = useApiPost(
    `updateRule`,
    setPostUpdateData,
    () => {},
    setRuleError
  );
  const [createRule] = useApiPost(
    'createRule',
    setPostCreateData,
    () => {},
    setRuleError
  );
  const [deleteRule] = useApiDelete({
    apiType: 'deleteRule',
    setData: setPostDeleteData,
    setError,
  });

  useEffect(() => {
    if (postCreateData) {
      if (ruleAdded !== undefined) {
        ruleAdded(postCreateData);
      }
      setSelectedIndicator(undefined);
      setIsOpen(false);
    }
  }, [postCreateData]);

  useEffect(() => {
    if (postUpdateData) {
      if (ruleUpdated !== undefined) {
        ruleUpdated(postUpdateData);
      }
      setSelectedIndicator(undefined);
      setIsOpen(false);
    }
  }, [postUpdateData]);

  useEffect(() => {
    if (postDeleteData) {
      setIsOpen(false);
    }
  }, [postDeleteData]);

  useEffect(() => {
    if (ruleError) {
      setError(new Error(ruleError.error));
    }
  }, [ruleError]);

  const method = currentRule.uid ? 'Update' : 'Create';
  return (
    <div className={classes.drawer} data-testid="update-rule">
      <Drawer
        variant={breakpointIsLgPlus ? 'persistent' : 'temporary'}
        open={isOpen}
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                data-testid="component-edit-rule-close-button"
                className={classes.closeButton}
                color="inherit"
                aria-label={`Close Edit Rule`}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.grow}
                align="left"
              >
                {method} Rule
              </Typography>
            </Toolbar>
            <Divider />
            <Grid item xs={12} className={classes.editRuleForm}>
              <RuleForm
                currentRule={currentRule}
                onCreateRule={rule => {
                  const ruleToCreate = {
                    pattern: rule.pattern,
                    ruleType: rule.ruleType,
                    vertexType: rule.vertexType,
                  };
                  createRule(ruleToCreate, 'json', 'POST');
                }}
                onUpdateRule={rule => {
                  const ruleToUpdate = {
                    uid: rule.uid,
                    pattern: rule.pattern,
                    ruleType: rule.ruleType,
                    vertexType: rule.vertexType,
                  };
                  updateRule(ruleToUpdate, 'json', 'PUT', {
                    rule: rule.uid,
                  });
                }}
                onDeleteRule={rule => {
                  deleteRule({
                    selectedDiscovery: selectedDiscovery,
                    rule: rule.uid,
                  });
                  if (ruleDeleted !== undefined) {
                    ruleDeleted(rule);
                  }
                }}
                method={method}
              />
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(RulesDrawer);
