import {
  ApiCallback,
  ApiTypes,
  DiscoveryApiTypes,
  apiUrls,
} from '@/helpers/ApiHelper';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { EnvVars } from './EnvVars';
import { useAuthContext } from './AuthContext';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import { useOrgContext } from './OrgContext';

interface Props {
  apiHost?: string;
  apiType?: ApiTypes;
  setData: Dispatch<SetStateAction<any>>;
  setError?: Dispatch<SetStateAction<any>>;
  sourceDataPopulated?: boolean;
  queryParams?: string;
}

export const useApiData = (props: Props) => {
  const {
    apiHost,
    apiType,
    setData,
    setError,
    queryParams,
    sourceDataPopulated,
  } = props;

  const { accessToken, userInfo } = useAuthContext();
  const { selectedOrg, selectedOrgInfo } = useOrgContext();
  const { selectedDiscovery } = useDomainDiscoveryContext();

  const apiContext = useMemo(() => {
    return (apiType as DiscoveryApiTypes) ? selectedDiscovery : selectedOrg;
  }, [selectedDiscovery, selectedOrg]);

  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    const bytes = [].slice.call(new Uint8Array(buffer));
    let binary = '';

    bytes.forEach((b: any) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  async function getApiData(queryParams) {
    let apiUrlToCall: string | null = null;
    if (apiType) {
      const getUrl: ApiCallback = apiUrls[apiType];
      apiUrlToCall = getUrl({
        apiHost: apiHost || (EnvVars['REACT_APP_API_HOST'] as string),
        selectedOrg,
        selectedOrgInfo,
        selectedDiscovery,
        currentUserId: userInfo.sub!,
      });

      if (queryParams) apiUrlToCall = apiUrlToCall + queryParams;
    }
    if (apiUrlToCall) {
      try {
        const response = await fetch(apiUrlToCall, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!response.ok)
          throw new Error(
            `Error fetching ${apiUrlToCall} ( ${await response.text()} )`
          );
        const contentType = response.headers.get('content-type');

        if (contentType) {
          if (contentType.indexOf('image/png') !== -1) {
            return response.arrayBuffer().then(data => {
              const base64Flag = 'data:image/png;base64,';
              return `${base64Flag}${arrayBufferToBase64(data)}`;
            });
          }
          if (contentType.indexOf('application/json') !== -1) {
            return await response.json();
          }
        }

        if (contentType == null) {
          return [];
        }

        return await response.text();
      } catch (e) {
        if (setError) setError(e);
      }
    }

    return [];
  }

  const refreshData = useCallback(() => {
    if (sourceDataPopulated === false) return;

    let isSubscribed = true;
    if (!accessToken) return;

    getApiData(queryParams).then(data => {
      if (isSubscribed) {
        setData(data);
      }
    });

    return function cleanup() {
      isSubscribed = false;
    };
  }, [accessToken, sourceDataPopulated, queryParams]);

  useEffect(() => {
    return refreshData();
  }, [apiContext, refreshData]);

  return refreshData;
};
