import Paper from '@material-ui/core/Paper';
import { Grid, Typography, WithStyles } from '@material-ui/core';
import TableComponent, { DataFormatProps } from '@/components/Table';
import { default as React } from 'react';
import { styles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { addCommas } from '@/helpers/DataHelper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReverseLookupResult } from '@/types/discovery';

const reverseLookupFormat: DataFormatProps[] = [
  {
    title: '',
    field: 'domain',
  },
];

interface Props extends WithStyles<typeof styles> {
  reverseLookupResult: ReverseLookupResult | undefined;
}

const ReverseLookupTable = ({ reverseLookupResult, classes }: Props) => {
  if (!reverseLookupResult)
    return (
      <div className={classes.progress} data-testid="reverse-lookup-progress">
        <CircularProgress />
      </div>
    );

  return (
    <Paper elevation={1} className={classes.reverseLookup}>
      <Grid
        container
        direction="row"
        alignContent="center"
        className={classes.reverseLookupHeader}
      >
        <Typography
          variant="h6"
          align="left"
          className={classes.reverseLookupHeaderCount}
        >
          {addCommas(reverseLookupResult.totalCount)}
        </Typography>
        <Typography variant="h6" align="left">
          Domains Found
        </Typography>
      </Grid>
      <Grid container direction="row" justify="space-between">
        <Grid item xs={6} className={classes.cardTable}>
          <TableComponent
            data={reverseLookupResult.knownDomains.map(d => ({
              domain: d,
            }))}
            dataFormat={reverseLookupFormat}
            paginationEnabled
            title="Known Domains"
          />
        </Grid>
        <Grid item xs={6} className={classes.cardTable}>
          <TableComponent
            data={reverseLookupResult.newDomains.map(d => ({
              domain: d,
            }))}
            dataFormat={reverseLookupFormat}
            paginationEnabled
            title="New Domains"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(ReverseLookupTable);
