import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { WithStyles } from '@material-ui/styles';
import classNames from 'classnames';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    formControl: {
      minWidth: 120,
    },
  });

interface Props extends WithStyles<typeof styles> {
  className?: string;
  error?: boolean;
  id?: string;
  label?: string;
  menuItems: JSX.Element[];
  onChange: (val) => void;
  showLabel?: boolean;
  value?: string;
}

const CustomizedSelect: React.FC<Props> = ({
  classes,
  className,
  error,
  id = 'select-option',
  label = '',
  menuItems,
  onChange,
  showLabel = true,
  value,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div>
      <FormControl className={classNames(className, classes.formControl)}>
        {showLabel && (
          <InputLabel id={`select-label-${id}`}>{label}</InputLabel>
        )}
        <Select error={error} id={id} value={value} onChange={handleChange}>
          {menuItems}
        </Select>
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(CustomizedSelect);
