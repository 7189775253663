import { ClickAwayListener } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel, {
  TableSortLabelProps,
} from '@material-ui/core/TableSortLabel';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  AutoSizer,
  Column,
  ColumnProps,
  Index,
  Table,
  TableHeaderProps,
  TableProps,
} from 'react-virtualized';
import CheckboxTooltip from '../CheckboxTooltip';
import OverflowTooltip from '../OverflowTooltip';

export interface DataFormatProps extends ColumnProps {
  cellContentRenderer?: (rowData: any) => string | React.ReactNode;
  numeric?: boolean;
  sortHandler?: TableSortLabelProps['onClick'];
}

interface HeaderRendererProps extends TableHeaderProps {
  columnIndex: number | null | undefined;
  sortHandler?: TableSortLabelProps['onClick'];
}

export interface Props extends WithStyles<typeof styles>, TableProps {
  multiSelect: boolean;
  handleRowSelect?: (id: any, checked: any) => void;
  handleHeaderSelect?: (ids: any[]) => void;
  columns: Array<DataFormatProps>;
  headerHeight: number;
  minWidth: number;
  minHeight: number;
  rowHeight: number;
  rowCount: number;
  rowClassName?: string;
  data: Array<object>;
  sortOrderId?: number;
  sortOrder?: 'asc' | 'desc';
  scrollToIndex: number;
  openTooltip?: boolean;
}

export const getRowData = (data: Array<object>) => ({ index }: Index) =>
  data[index];

const styles = (theme: Theme) =>
  createStyles({
    table: {
      fontFamily: theme.typography.fontFamily,
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    tableRow: {
      cursor: 'pointer',
    },
    tableRowErrored: {
      backgroundColor: theme.palette.error.main,
    },
    tableRowHover: {
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
    tableCell: {
      flex: 1,
      borderBottom: '1px solid #9b9b9b',
      paddingRight: 0,
      paddingLeft: 0,
    },
    tableCellNoMultiselect: {
      '&:first-child': {
        paddingLeft: theme.spacing(2),
      },
    },
    tableHeaderCell: {
      borderBottom: '2px solid #4a4a4a',
    },
    tableHeaderCenter: {
      margin: 'auto',
      paddingLeft: 16,
    },
    noClick: {
      cursor: 'initial',
    },
  });

const TableVirtualized = ({
  multiSelect,
  classes,
  columns,
  minWidth = 0,
  minHeight = 0,
  scrollToIndex,
  ...tableProps
}: Props) => {
  const getRowClassName = ({ index }) => {
    const {
      rowClassName,
      onRowClick,
      rowGetter,
      isErroredPredicate = null,
    } = tableProps;
    const rowData = rowGetter({ index });
    const rowIsErrored = isErroredPredicate
      ? isErroredPredicate(rowData)
      : false;

    return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
      [classes.tableRowErrored]: rowIsErrored,
    });
  };

  const {
    handleRowSelect,
    handleHeaderSelect,
    rowHeight,
    rowCount,
    rowIds,
    openTooltip,
  } = tableProps;

  const [openCheckboxTooltip, setOpenCheckboxTooltip] = React.useState(
    openTooltip ? openTooltip : false
  );
  useEffect(() => {
    setOpenCheckboxTooltip(openTooltip ? openTooltip : false);
  }, [openTooltip]);

  const handleCheckboxTooltipClose = () => {
    setOpenCheckboxTooltip(false);
  };

  const initialRowsCheckedState = rowIds.map(id => ({ id, checked: false }));
  const [rowsChecked, setRowsChecked] = useState(initialRowsCheckedState);
  const [headerChecked, setHeaderChecked] = useState(false);
  const [headerIndeterminate, setHeaderIndeterminate] = useState(false);

  useEffect(() => {
    setRowsChecked(initialRowsCheckedState);
    setHeaderChecked(
      initialRowsCheckedState.findIndex(row => row.checked === false) <= -1
    );
    setHeaderIndeterminate(
      initialRowsCheckedState.some(row => row.checked === true) &&
        initialRowsCheckedState.some(row => row.checked === false)
    );
  }, [rowIds.length]);

  const handleHeaderClick = event => {
    const newChecked = event.target.checked && !headerIndeterminate;
    if (!newChecked) {
      setHeaderIndeterminate(false);
    }
    setHeaderChecked(newChecked);
    setRowsChecked(
      rowsChecked.map(row => ({
        id: row.id,
        checked: newChecked,
      }))
    );

    if (handleHeaderSelect) {
      handleHeaderSelect(newChecked ? rowIds : []);
    }

    handleCheckboxTooltipClose();
  };

  let allColumns = columns;

  if (multiSelect) {
    const handleRowClick = rowId => event => {
      let numberChecked = rowsChecked.filter(row => row.checked === true)
        .length;
      numberChecked = event.target.checked
        ? numberChecked + 1
        : numberChecked - 1;
      setRowsChecked(
        rowsChecked.map(row => {
          return row.id === rowId
            ? { id: rowId, checked: event.target.checked }
            : row;
        })
      );
      numberChecked < rowCount && numberChecked > 0
        ? setHeaderIndeterminate(true)
        : setHeaderIndeterminate(false);
      numberChecked === rowCount
        ? setHeaderChecked(true)
        : setHeaderChecked(false);
      handleRowSelect && handleRowSelect(rowId, event.target.checked);
    };

    const checkboxColumn: DataFormatProps[] = [
      {
        cellContentRenderer: ({ rowData }) => {
          const thisChecked = rowsChecked.find(
            row => row.id === rowData.uid
          ) || { checked: false };
          return (
            <TableCell
              className={classNames(classes.tableCell, classes.flexContainer)}
              component="div"
              align="left"
              variant="body"
              style={{
                height: rowHeight,
              }}
            >
              <Checkbox
                color="primary"
                id={rowData.uid}
                onClick={event => event.stopPropagation()}
                onChange={handleRowClick(rowData.uid)}
                checked={thisChecked.checked}
              />
            </TableCell>
          );
        },
        headerRenderer: () => (
          <TableCell
            className={classNames(
              classes.tableCell,
              classes.tableHeaderCell,
              classes.flexContainer
            )}
            component="div"
            align="left"
            variant="head"
            style={{
              padding: '0px',
              height: rowHeight,
            }}
          >
            <ClickAwayListener onClickAway={handleCheckboxTooltipClose}>
              <div>
                <CheckboxTooltip
                  title="Click this checkbox to unselect all"
                  open={openCheckboxTooltip}
                  onClose={handleCheckboxTooltipClose}
                  placement="top-end"
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <Checkbox
                    id="header-checkbox"
                    color="primary"
                    onChange={handleHeaderClick}
                    checked={headerChecked}
                    indeterminate={headerIndeterminate}
                  />
                </CheckboxTooltip>
              </div>
            </ClickAwayListener>
          </TableCell>
        ),
        dataKey: 'multiSelect',
        width: 50,
      },
    ];
    allColumns = checkboxColumn.concat(columns);
  }

  const cellRenderer = ({
    cellData,
    columnIndex,
  }: {
    cellData: React.ReactNode | null;
    columnIndex: number;
  }) => {
    const { rowHeight, onRowClick } = tableProps;

    const isNumeric =
      (columnIndex != null && allColumns[columnIndex || 0].numeric) || false;

    return (
      <TableCell
        component="div"
        className={classNames(
          classes.tableCell,
          classes.flexContainer,
          {
            [classes.noClick]: onRowClick == null,
          },
          !multiSelect && classes.tableCellNoMultiselect
        )}
        variant="body"
        style={{
          height: rowHeight,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          display: 'inline-block',
          textOverflow: 'ellipsis',
          lineHeight: 2.6,
        }}
        align={isNumeric ? 'center' : 'left'}
      >
        <OverflowTooltip value={cellData} />
      </TableCell>
    );
  };

  const headerRenderer = ({
    label,
    columnIndex,
    dataKey,
    sortHandler,
  }: HeaderRendererProps) => {
    const { headerHeight, sortOrder, sortOrderId } = tableProps;
    const isNumeric =
      (columnIndex != null && allColumns[columnIndex].numeric) || false;

    let inner = label;
    if (typeof columnIndex === 'number') {
      const calcColumnIndex = multiSelect ? columnIndex - 1 : columnIndex;
      if (!allColumns[columnIndex].disableSort && sortHandler) {
        inner = (
          <TableSortLabel
            active={calcColumnIndex === sortOrderId}
            direction={sortOrder}
            onClick={sortHandler}
          >
            {label}
          </TableSortLabel>
        );
      }
    }

    return (
      <TableCell
        component="div"
        className={classNames(
          classes.tableCell,
          classes.tableHeaderCell,
          classes.flexContainer,
          classes.noClick,
          !multiSelect && classes.tableCellNoMultiselect
        )}
        variant="body"
        style={{
          height: headerHeight,
        }}
        align={isNumeric ? 'center' : 'left'}
      >
        <div className={classNames(isNumeric && classes.tableHeaderCenter)}>
          {inner}
        </div>
      </TableCell>
    );
  };

  return (
    <AutoSizer disableHeight={!!minHeight}>
      {({ height, width }) => (
        <Table
          className={classes.table}
          height={Math.max(minHeight, height || 112)}
          width={Math.max(minWidth, width || 112)}
          {...tableProps}
          rowClassName={getRowClassName}
          scrollToIndex={scrollToIndex > -1 ? scrollToIndex : 0}
        >
          {allColumns.map(
            (
              {
                cellContentRenderer = null,
                className = '',
                dataKey,
                sortHandler,
                ...other
              },
              index
            ) => {
              let renderer;
              if (cellContentRenderer != null) {
                renderer = cellRendererProps => {
                  if (dataKey === 'multiSelect')
                    return cellContentRenderer(cellRendererProps);
                  return cellRenderer({
                    cellData: cellContentRenderer(cellRendererProps),
                    columnIndex: index,
                  });
                };
              } else {
                renderer = cellRenderer;
              }
              return (
                <Column
                  key={dataKey}
                  headerRenderer={headerProps =>
                    headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                      sortHandler,
                    })
                  }
                  className={classNames(classes.flexContainer, className)}
                  cellRenderer={renderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            }
          )}
        </Table>
      )}
    </AutoSizer>
  );
};

TableVirtualized.defaultProps = {
  headerHeight: 52,
  rowHeight: 52,
};

export default withStyles(styles)(TableVirtualized);
