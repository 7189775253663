import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Cancel, SearchIcon } from '@/images/Icons';

const styles = (theme: Theme) =>
  createStyles({
    searchInput: {
      paddingRight: theme.spacing(0.5),
    },
    searchBar: {
      margin: 0,
    },
  });

interface Props extends WithStyles<typeof styles> {
  searchHandler: (e: { target: { value: string } }) => void;
  title: string;
  disabled?: boolean;
  defaultValue?: string;
  className?: string;
  id?: string;
}
const SearchInput = ({
  classes,
  disabled,
  searchHandler,
  title,
  className = '',
  id,
  defaultValue = '',
}: Props) => {
  const [hasReceivedText, setHasReceivedText] = useState<boolean | ''>(false);
  const textInput: React.RefObject<any> | null = useRef(null);
  return (
    <TextField
      id={id}
      disabled={disabled}
      variant="filled"
      margin="dense"
      autoComplete="off"
      hiddenLabel
      placeholder={title}
      inputRef={textInput}
      defaultValue={defaultValue}
      onChange={e => {
        setHasReceivedText(e.target.value && e.target.value.length > 0);
        searchHandler(e);
      }}
      className={classNames(className, classes.searchBar)}
      inputProps={{
        'aria-label': title,
        'data-testid': id,
      }}
      InputProps={{
        className: classes.searchInput,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
        ...(hasReceivedText
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Clear Search Term"
                    data-testid={`${id}-clear`}
                    onClick={() => {
                      textInput.current.value = '';
                      setHasReceivedText(false);
                      searchHandler({ target: { value: '' } });
                      textInput.current.focus();
                    }}
                  >
                    <Cancel />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {}),
      }}
    />
  );
};

export default withStyles(styles)(SearchInput);
