import {
  AssessmentIcon,
  CloudIcon,
  LocalLibraryIcon,
  LocalParkingIcon,
  SearchIcon,
} from '@/images/Icons';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import {
  generateDiscoveryIdPath,
  routeCloudConnectors,
  routeDomainDiscovery,
  routeDomainDiscoveryAnswers,
  routeDomainDiscoveryQuestions,
  routeDomainDiscoveryResults,
  routeDomainDiscoverySeeds,
  routeFeedback,
  routeOrgLookup,
  routePrivacyPolicy,
  routeRules,
  routeValueMetrics,
} from '@/helpers/RouteHelper';
import { apiUrls } from '@/helpers/ApiHelper';
import { EnvVars } from '../../hooks/EnvVars';

import Divider from '@material-ui/core/Divider';
import DomainIcon from '@material-ui/icons/Domain';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import NavPrimaryItem from './NavPrimaryItem';
import Chip from '@/components/Chip';
import React, { useContext, useEffect } from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Typography from '@material-ui/core/Typography';
import { drawerWidth } from '@/helpers/NavHelper';
import { format } from 'date-fns';
import { useAuthContext } from '@/hooks/AuthContext';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import {
  actions,
  CloudConnectorDispatchContext,
  CloudConnectorStateContext,
} from '@/hooks/CloudConnectorContext';

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  drawerOpen: boolean;
  closeDrawer: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    authStatus: {
      padding: theme.spacing(2),
    },
  });

const subItems = [
  {
    label: 'Feedback',
    icon: () => <LocalLibraryIcon />,
    pathname: routeFeedback(),
  },
  {
    label: 'Privacy Policy',
    icon: () => <LocalParkingIcon />,
    pathname: routePrivacyPolicy(),
    external: true,
  },
];

const NavPrimary = ({ classes, theme, drawerOpen, closeDrawer }: Props) => {
  const { accessToken, isAuthenticated, userInfo } = useAuthContext();
  const { selectedDiscovery } = useDomainDiscoveryContext();
  const { errorCount } = useContext(CloudConnectorStateContext);

  const dispatchOrgsWithCloudConnectors = useContext<any>(
    CloudConnectorDispatchContext
  );

  const getErrors = async (accessToken, dispatchOrgsWithCloudConnectors) => {
    const getUrl = apiUrls['connectorCounts'];
    const apiUrl = getUrl({
      apiHost: EnvVars['REACT_APP_API_HOST'] as string,
    });
    var resp = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(res => res.json())
      .catch(err => err);
    dispatchOrgsWithCloudConnectors({
      type: actions.SET_ERROR_COUNT,
      payload: resp.errored,
    });
  };

  useEffect(() => {
    if (!accessToken) return;

    (async function getErrorsWrapper() {
      await getErrors(accessToken, dispatchOrgsWithCloudConnectors);
    })();
  }, [accessToken]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <nav className={classes.drawer}>
      <Drawer
        variant="temporary"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        onClose={closeDrawer}
      >
        <div>
          <div className={classes.toolbar} />
          <List>
            <>
              <NavPrimaryItem
                label={'Org Management'}
                icon={() => <SearchIcon />}
                pathname={routeOrgLookup()}
                name={'orglookup'}
                key={'orglookup'}
                closeDrawer={closeDrawer}
              />
              {userInfo?.isAdmin && (
                <NavPrimaryItem
                  label={'Value Metrics'}
                  icon={() => <AssessmentIcon />}
                  pathname={routeValueMetrics()}
                  name={'valueMetrics'}
                  key={'valueMetrics'}
                  closeDrawer={closeDrawer}
                />
              )}
              <NavPrimaryItem
                label={'Cloud Connectors'}
                icon={() => <CloudIcon />}
                pathname={routeCloudConnectors()}
                name={'cloudConnectors'}
                key={'cloudConnectors'}
                closeDrawer={closeDrawer}
                Chip={errorCount > 0 ? Chip : null}
                chipProps={{ label: errorCount }}
              />
            </>
          </List>
          <Divider variant="fullWidth" />
          <List>
            <>
              <NavPrimaryItem
                label={'Domain Discovery'}
                icon={() => <DomainIcon />}
                pathname={routeDomainDiscovery()}
                name={'domainDiscovery'}
                key={'domainDiscovery'}
                closeDrawer={closeDrawer}
              />
              {selectedDiscovery?.uid && (
                <NavPrimaryItem
                  label={'Questions'}
                  pathname={generateDiscoveryIdPath(
                    routeDomainDiscoveryQuestions(),
                    selectedDiscovery?.uid
                  )}
                  name={'domainDiscoveryQuestions'}
                  key={'domainDiscoveryQuestions'}
                  closeDrawer={closeDrawer}
                />
              )}
              {selectedDiscovery?.uid && (
                <NavPrimaryItem
                  label={'Answers'}
                  pathname={generateDiscoveryIdPath(
                    routeDomainDiscoveryAnswers(),
                    selectedDiscovery?.uid
                  )}
                  name={'domainDiscoveryAnswers'}
                  key={'domainDiscoveryAnswers'}
                  closeDrawer={closeDrawer}
                />
              )}
              {selectedDiscovery?.uid && (
                <NavPrimaryItem
                  label={'Results'}
                  pathname={generateDiscoveryIdPath(
                    routeDomainDiscoveryResults(),
                    selectedDiscovery?.uid
                  )}
                  name={'domainDiscoveryResults'}
                  key={'domainDiscoveryResults'}
                  closeDrawer={closeDrawer}
                />
              )}
              {selectedDiscovery?.uid && (
                <NavPrimaryItem
                  label={'Rules'}
                  pathname={generateDiscoveryIdPath(
                    routeRules(),
                    selectedDiscovery?.uid
                  )}
                  name={'domainDiscoveryRules'}
                  key={'domainDiscoveryRules'}
                  closeDrawer={closeDrawer}
                />
              )}
              {selectedDiscovery?.uid && (
                <NavPrimaryItem
                  label={'Seed Data'}
                  pathname={generateDiscoveryIdPath(
                    routeDomainDiscoverySeeds(),
                    selectedDiscovery?.uid
                  )}
                  name={'domainDiscoverySeeds'}
                  key={'domainDiscoverySeeds'}
                  closeDrawer={closeDrawer}
                />
              )}
            </>
          </List>
          <Divider variant="fullWidth" />
          <List>
            {subItems.map(({ label, icon, pathname, external }, n) => (
              <NavPrimaryItem
                label={label}
                pathname={pathname}
                icon={icon}
                name={`subitems-${n}`}
                external={external}
                key={`subitems-${n}`}
                closeDrawer={closeDrawer}
              />
            ))}
          </List>
          <Divider variant="fullWidth" />
          {userInfo && (
            <div className={classes.authStatus}>
              <Typography align="left" noWrap variant="caption" component="h5">
                {`Last logged in ${format(
                  new Date(userInfo.updated_at || Date.now()),
                  'MM/DD/YYYY HH:mm:ss'
                )}`}
              </Typography>
            </div>
          )}
        </div>
      </Drawer>
    </nav>
  );
};

export default withStyles(styles, { withTheme: true })(NavPrimary);
