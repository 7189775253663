import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles } from '@material-ui/core/styles';
import { drawerWidth } from '@/helpers/NavHelper';

export const ruleFormStyles = (theme: Theme) =>
  createStyles({
    formControl: {
      display: 'inline-block',
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    formElement: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      width: '100%',
    },
    formLabelLegend: {
      textTransform: 'uppercase',
      textAlign: 'left',
    },
    button: {
      margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
      float: 'right',
    },
    previewButton: {
      margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
      width: '100%',
    },
    matchesList: {
      overflow: 'auto',
      width: '100%',
      maxHeight: 300,
      margin: `${theme.spacing(2)}px 0`,
    },
  });

export default (theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(2),
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeButton: {
      marginRight: theme.spacing(),
    },
    editRuleForm: {
      padding: theme.spacing(2),
    },
  });
