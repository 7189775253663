import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { drawerWidth } from '@/helpers/NavHelper';

export const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(2),
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    grow: {
      flexGrow: 1,
    },
    closeButton: {
      marginRight: theme.spacing(),
    },
    filterForm: {
      padding: theme.spacing(2),
    },
    formControl: {
      margin: `${theme.spacing()}px 0`,
      flexGrow: 1,
      width: '100%',
    },
    formLabelLegend: {
      textTransform: 'uppercase',
      textAlign: 'left',
    },
    grey: {
      color: 'rgba(0,0,0,0.3)',
      borderColor: 'rgba(0,0,0,0.3)',
      textTransform: 'uppercase',
    },
    saveFilterBtn: {
      margin: theme.spacing(2),
    },
  });
