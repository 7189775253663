import {
  CardContent,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  WithStyles,
} from '@material-ui/core';
import { default as React, useState } from 'react';
import { styles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Detail } from '@/types/discovery';

interface Props extends WithStyles<typeof styles> {
  details: Detail[] | undefined;
  media: any | undefined;
}

const ScreenshotContent = ({ details, media, classes }: Props) => {
  const [expanded, setExpanded] = useState<Map<string, boolean>>(
    new Map<string, boolean>()
  );

  const handleExpandDomain = (domain: string) => {
    const newExpanded = new Map(expanded);
    newExpanded.set(domain, !expanded.get(domain));

    setExpanded(newExpanded);
  };

  const screenshotDomains =
    details?.reduce((acc, d) => {
      if (!d.data) return acc;

      const domain = d.data['domain'];
      let domains = acc.get(domain);
      if (!domains) {
        domains = new Array<object>();
        acc.set(domain, domains);
      }
      domains.push(d.data);
      return acc;
    }, new Map<string, object[]>()) || new Map<string, object[]>();

  const requestUriList = (screenshotData: object[]) => (
    <List disablePadding dense>
      {screenshotData
        ?.filter(data => data['uid'])
        .sort()
        .map(data => (
          <ListItem className={classes.nested} key={data['uid']}>
            <ListItemText primary={data['requestUri']} />
          </ListItem>
        ))}
    </List>
  );

  return (
    <Grid container justify="center" className={classes.screenshotContent}>
      <Grid item xs={4}>
        <CardContent>
          <List
            subheader={<ListSubheader>Domains</ListSubheader>}
            className={classes.domainList}
          >
            {[...screenshotDomains.keys()].sort().map(d => (
              <div key={d}>
                <ListItem button onClick={() => handleExpandDomain(d)}>
                  <ListItemText primary={d} />
                  {expanded.get(d) ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expanded.get(d)} timeout="auto" unmountOnExit>
                  {requestUriList(screenshotDomains.get(d) || [])}
                </Collapse>
              </div>
            ))}
          </List>
        </CardContent>
      </Grid>
      <Grid item xs={1}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={7}>
        <div className={classes.fullyCentered}>
          {media ? (
            <CardMedia
              component="img"
              alt="Screenshot"
              image={media}
              className={classes.screenshotImage}
            />
          ) : (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ScreenshotContent);
