import { ApiCallback, apiUrls } from '@/helpers/ApiHelper';

import { EnvVars } from './EnvVars';
import { useAuthContext } from './AuthContext';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import { useOrgContext } from './OrgContext';

export const useApiPost = (
  apiType: ApiTypes,
  setData: (value: any) => void,
  setError?: (error: any) => void,
  setErrorBody?: (value: any) => void
) => {
  const { accessToken, userInfo } = useAuthContext();
  const { selectedOrg, selectedOrgInfo } = useOrgContext();
  const { selectedDiscovery } = useDomainDiscoveryContext();

  async function postApiData(body, bodyType, method, otherUrlParams) {
    const getUrl: ApiCallback = apiUrls[apiType];
    try {
      const apiHost = EnvVars['REACT_APP_API_HOST'];
      const response = await fetch(
        getUrl({
          apiHost,
          selectedOrg,
          selectedOrgInfo,
          currentUserId: userInfo?.sub,
          selectedDiscovery,
          ...otherUrlParams,
        }),
        {
          method,
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: bodyType === 'json' ? JSON.stringify(body) : body,
        }
      );

      if (!response.ok) {
        if (setError && !setErrorBody) {
          throw new Error('API Error');
        }
        if (setErrorBody) {
          const data = await response.json();
          setErrorBody(data);
        }
      }

      const data = await response.json();
      return data;
    } catch (e) {
      if (setError) setError(e);
    }
    return [];
  }

  const submitPostCall = (body, bodyType, method, params?) => {
    if (!accessToken) return;

    const urlParams = params ? params : {};
    postApiData(body, bodyType, method, urlParams).then(data => {
      setData(data);
    });
  };

  return [submitPostCall];
};
