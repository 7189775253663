import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    denied: {
      color: theme.palette.error.main,
    },
    approved: {
      color: theme.palette.secondary['700'],
    },
    unknown: {
      color: theme.palette.grey.A700,
    },
  });
