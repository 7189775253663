export const initializeFilterSettings = (
  pageTitle: string,
  initialActiveFilters: any
) => {
  const itemKey = `${pageTitle} Filters`;
  const localStorageFilters = localStorage.getItem(itemKey);
  if (!localStorageFilters || initialActiveFilters.length > 0)
    return initialActiveFilters;
  return JSON.parse(localStorageFilters);
};

export const initializeSearchSettings = (pageTitle: string) => {
  const itemKey = `${pageTitle} Search`;
  return JSON.parse(localStorage.getItem(itemKey) || '""');
};

export const initializeSortSettings = (
  pageTitle: string,
  defaultSortOrder: any
) => {
  const itemKey = `${pageTitle} Sort`;
  const localStorageSort = localStorage.getItem(itemKey);
  if (!localStorageSort) return defaultSortOrder;
  return JSON.parse(localStorageSort);
};

export const updateStorageAndHandleSearch = (
  pageTitle: string,
  searchHandler
) => (e: any) => {
  const itemKey = `${pageTitle} Search`;
  let localSearch: any = JSON.parse(localStorage.getItem(itemKey) || '""');
  if (e.target.value !== '') {
    localSearch = e.target.value;
    localStorage.setItem(itemKey, JSON.stringify(localSearch));
  } else {
    localStorage.removeItem(itemKey);
  }
  searchHandler(e);
};

export const clearLocalSettings = () => {
  const pagesVisited = JSON.parse(localStorage.getItem('pagesVisited') || '""');
  if (pagesVisited.length > 0) {
    pagesVisited.forEach((page: string) => {
      localStorage.removeItem(`${page} Filters`);
      localStorage.removeItem(`${page} Search`);
      localStorage.removeItem(`${page} Sort`);
    });
  }
  localStorage.removeItem('pagesVisited');
};
