import { onClickHandler as onClickHandlerGtag } from '@/helpers/AnalyticsHelper';
import { useAuthContext } from '@/hooks/AuthContext';
import Button from '@material-ui/core/Button';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Typography from '@material-ui/core/Typography';
import React, { useCallback } from 'react';

const styles = (theme: Theme) =>
  createStyles({
    greeting: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    button: {
      borderRadius: '8px',
      textTransform: 'capitalize',
      color: theme.palette.text.primary,
      borderColor: theme.palette.divider,
    },
  });
interface Props extends WithStyles<typeof styles> {}

const Login = ({ classes }: Props) => {
  const { isAuthenticated, logout, userInfo } = useAuthContext();

  const logoutAction = useCallback(() => {
    logout();
    onClickHandlerGtag({
      event_category: `logout`,
    });
  }, []);

  if (!isAuthenticated) {
    return null;
  }

  if (isAuthenticated && userInfo) {
    return (
      <>
        <Typography
          align="right"
          noWrap
          color="textPrimary"
          variant="body1"
          component="span"
          className={classes.greeting}
        >
          {`Hi, ${userInfo.name}`}
        </Typography>
        <Button
          variant="outlined"
          onClick={logoutAction}
          className={classes.button}
        >
          <Typography variant="body1" component="span">
            Logout
          </Typography>
        </Button>
      </>
    );
  }
  return null;
};

export default withStyles(styles)(Login);
