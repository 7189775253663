import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import { default as React, useState } from 'react';
import TableComponent, { DataFormatProps } from '@/components/Table';

import DomainDiscoverySummary from '@/components/discovery/Summary';
import { styles } from '@/screens/DiscoveryErrors/styles';
import { useApiData } from '@/hooks/UseApiData';
import { useUnsetOrg } from '@/hooks/OrgContext';
import { useLayoutContext } from '@/App';
import PageLoader from '@/components/PageLoader';
import capitalize from '@material-ui/core/utils/capitalize';
import ReactivateButton from '@/components/discovery/ReactivateButton';
import IndicatorList from '@/components/discovery/IndicatorList';
import { ErroredIndicator } from '@/types/discovery';

interface Props extends WithStyles<typeof styles> {}

const DiscoveryErrors = ({ classes }: Props) => {
  const pageTitle = 'Discovery Errors';
  const [erroredIndicators, setErroredIndicators] = useState();

  const { selectedDiscovery, reactivate } = useDomainDiscoveryContext();
  const { setError } = useLayoutContext();

  const prettyCategory = (cat: string): string => {
    const parts = cat.split('_');
    if (parts.length !== 2) {
      return cat;
    }

    return `${capitalize(parts[1])} - ${capitalize(parts[0])}`;
  };

  const setList = data => {
    const newErrorsMap = data.reduce(
      (acc: Map<string, ErroredIndicator[]>, ev: ErroredIndicator) => {
        Object.keys(ev.errors).forEach(category => {
          let catIndicators = acc.get(category);
          if (!catIndicators) catIndicators = new Array<ErroredIndicator>();

          catIndicators.push(ev);
          acc.set(category, catIndicators);
        });

        return acc;
      },
      new Map<string, ErroredIndicator[]>()
    );

    setErroredIndicators(
      Array.from(newErrorsMap, ([category, indicators]) => ({
        category,
        indicators,
      }))
    );
  };

  const refreshDiscoveryErrors = useApiData({
    apiType: 'discoveryErrors',
    setData: setList,
    setError,
    sourceDataPopulated: !!selectedDiscovery?.uid,
  });

  const reactivateAndRefresh = (completed: () => void, category?: string) => {
    reactivate(() => {
      refreshDiscoveryErrors();
      completed();
    }, category);
  };

  // Always unselect org since it doesn't relate to this screen
  useUnsetOrg();

  if (!erroredIndicators) {
    return <PageLoader pageTitle={pageTitle} />;
  }

  const columns: DataFormatProps[] = [
    {
      title: 'Count',
      field: 'count',
      render: ({ indicators }) => indicators.length,
      width: 40,
      searchable: true,
    },
    {
      title: 'Category',
      field: 'category',
      render: ({ category }) => prettyCategory(category),
      width: 120,
      searchable: true,
    },
    {
      title: 'Indicators',
      field: 'indicators',
      render: ({ indicators }) => <IndicatorList indicators={indicators} />,
      width: 240,
      searchable: true,
    },
    {
      title: '',
      field: 'reactivate',
      render: ({ category }) => (
        <ReactivateButton
          reactivate={reactivateAndRefresh}
          category={category}
        />
      ),
      width: 80,
      searchable: false,
    },
  ];

  const data = erroredIndicators.length > 0 ? erroredIndicators : [];

  return (
    <>
      <DomainDiscoverySummary pageTitle={pageTitle} />
      <Grid
        container
        direction="row"
        alignItems="stretch"
        className={classes.errorsTable}
      >
        <TableComponent
          data={data}
          dataFormat={columns}
          paginationEnabled
          rowsPerPage={25}
          sortEnabled={true}
          search={{
            enabled: true,
            title: 'Search by Category',
          }}
          searchFormat={columns}
          headerActions={<ReactivateButton reactivate={reactivateAndRefresh} />}
          renderOnNoData={
            <Typography component="div" variant="body1" align="center">
              No errors are present for this discovery
            </Typography>
          }
        />
      </Grid>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(DiscoveryErrors);
