import Typography from '@material-ui/core/Typography';
import { Grid, WithStyles } from '@material-ui/core';
import { default as React } from 'react';
import { styles } from '@/components/PageHeader/styles';
import { withStyles } from '@material-ui/core/styles';

interface Props extends WithStyles<typeof styles> {
  pageTitle: string;
}

const PageHeader = (props: Props) => {
  const { classes, pageTitle } = props;
  return (
    <Grid className={classes.pageHeader}>
      <Typography component="h1" variant="h6" align="left">
        {pageTitle}
      </Typography>
    </Grid>
  );
};

export default withStyles(styles)(PageHeader);
