import { onClickHandler as onClickHandlerGtag } from '@/helpers/AnalyticsHelper';
import { stableSort, stableSortDesc } from '@/helpers/DataHelper';
import { TableSortLabelProps } from '@material-ui/core/TableSortLabel';
import { useMemo, useState } from 'react';

export type CreateSortHandler = (
  property: string,
  id: number
) => TableSortLabelProps['onClick'];

export interface OrderOptions {
  order: 'asc' | 'desc';
  orderBy: string | null;
  orderId: number | null;
}

export interface UseSorting extends OrderOptions {
  createSortHandler: CreateSortHandler;
  sortedData: any[];
}

const defaultOrder: OrderOptions = {
  order: 'asc',
  orderBy: null,
  orderId: null,
};

export const useSorting = (
  data: object[] | null | undefined,
  defaultOrderOverride?: OrderOptions,
  paginationEnabled?: boolean
) => {
  const [orderOptions, setOrderOptions] = useState<OrderOptions>(
    defaultOrderOverride ? defaultOrderOverride : defaultOrder
  );

  const onRequestSort = (
    event,
    property: OrderOptions['orderBy'],
    id: OrderOptions['orderId']
  ) => {
    const orderBy: OrderOptions['orderBy'] = property;
    let order: OrderOptions['order'] = 'asc';
    if (
      orderOptions &&
      orderOptions.orderBy === property &&
      orderOptions.order === 'asc'
    ) {
      order = 'desc';
    }
    setOrderOptions({ order, orderBy, orderId: id });
    onClickHandlerGtag({
      event_category: `sort_column`,
      event_label: order,
      content_type: property || undefined,
    });
  };

  const createSortHandler: CreateSortHandler = (property, id) => event => {
    onRequestSort(event, property, id);
  };

  const getSorting = (
    order: OrderOptions['order'],
    orderBy: OrderOptions['orderBy']
  ) => {
    if (!orderBy) return null;
    return order === 'desc'
      ? (a, b) => stableSortDesc(a, b, orderBy)
      : (a, b) => -stableSortDesc(a, b, orderBy);
  };

  const sortedData = useMemo(() => {
    if (paginationEnabled) return data;

    const sorting =
      orderOptions && getSorting(orderOptions.order, orderOptions.orderBy);
    if (!data) return [];
    if (!sorting) return data;
    return stableSort(data, sorting);
  }, [data, orderOptions]);

  return {
    sortedData,
    createSortHandler,
    orderOptions,
    ...orderOptions,
  };
};
