import { default as React, useState } from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { ErroredIndicator } from '@/types/discovery';
import { styles } from '@/components/discovery/IndicatorList/styles';

interface Props extends WithStyles<typeof styles> {
  indicators: ErroredIndicator[];
}

const IndicatorList = ({ classes, indicators }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  if (!indicators) {
    return <></>;
  }

  if (indicators.length === 1) {
    return <ListItemText primary={indicators[0].name} />;
  }

  return (
    <>
      <List component="div" disablePadding>
        <ListItem key={indicators[0].uid} button onClick={handleClick}>
          <ListItemIcon>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
          <ListItemText primary={indicators[0].name} />
        </ListItem>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {indicators.slice(1, indicators.length).map((i: ErroredIndicator) => (
            <ListItem key={i.uid} className={classes.nested}>
              <ListItemText primary={i.name} />
            </ListItem>
          ))}
        </Collapse>
      </List>
    </>
  );
};

export default withStyles(styles)(IndicatorList);
