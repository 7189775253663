import { Redirect, Route, RouteProps } from 'react-router-dom';
import {
  generateOrgIdPath,
  routeLoggedOut,
  routeOrgLookup,
} from '@/helpers/RouteHelper';

import AnalyticsScreenView from '@/components/AnalyticsScreenView';
import React from 'react';
import get from 'lodash/get';
import { useAuthContext } from '@/hooks/AuthContext';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import { useOrgContext } from '@/hooks/OrgContext';

interface EnforceAuthRouteProps extends RouteProps {
  exact?: boolean;
  shouldBeAuthenticated?: boolean;
  shouldBeConsultant?: boolean;
}

const EnforceAuthRoute = (props: EnforceAuthRouteProps) => {
  const {
    shouldBeAuthenticated = true,
    shouldBeConsultant = false,
    ...rest
  } = props;

  const { isAuthenticated, loginWithRedirect, userInfo } = useAuthContext();
  const { urlPathOrg, setSelectedOrg, orgList } = useOrgContext();
  const {
    setSelectedDiscoveryFromUid,
    discoveryList,
  } = useDomainDiscoveryContext();
  let routeOverrides = {};

  if (shouldBeAuthenticated && !isAuthenticated) {
    routeOverrides = {
      render: () => (
        <Redirect data-testid="redirect-to-logged-out" to={routeLoggedOut()} />
      ),
      component: null,
    };
  } else if (!shouldBeAuthenticated && isAuthenticated) {
    routeOverrides = {
      render: () => (
        <Redirect
          data-testid="redirect-to-homepage"
          to={generateOrgIdPath(routeOrgLookup(), urlPathOrg)}
        />
      ),
      component: null,
    };
  } else if (shouldBeConsultant && userInfo && userInfo.isCustomer === true) {
    routeOverrides = {
      render: () => (
        <Redirect
          data-testid="redirect-to-customer"
          to={generateOrgIdPath(routeOrgLookup(), urlPathOrg)}
        />
      ),
      component: null,
    };
  }
  const { component, ...routeProps } = {
    ...rest,
    ...routeOverrides,
  };

  return (
    <Route
      {...routeProps}
      render={props => {
        const { match } = props;

        if (!isAuthenticated) {
          loginWithRedirect({ appState: { targetUrl: routeProps.path } });
        }

        const orgUrlParam = get(match, 'params.orgId');
        if (orgList && orgUrlParam) {
          setSelectedOrg(orgUrlParam);
        }

        const discoveryUrlParam = get(match, 'params.discoveryId');
        setSelectedDiscoveryFromUid(
          discoveryList && discoveryUrlParam ? discoveryUrlParam : ''
        );

        return (
          <AnalyticsScreenView>
            <Route component={component} {...routeProps} />
          </AnalyticsScreenView>
        );
      }}
    />
  );
};

export default EnforceAuthRoute;
