import { ActiveFilter, FilterConfig } from '@/helpers/FilterHelpers';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { OrderOptions, useSorting } from '@/hooks/UseSorting';
import React, {
  ReactChild,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SearchOptions, useSearch } from '@/hooks/UseSearch';
import TableVirtualized, {
  DataFormatProps,
  getRowData,
  Props as TableVirtualizedProps,
} from '@/components/TableVirtualized';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import {
  initializeFilterSettings,
  initializeSearchSettings,
  initializeSortSettings,
  updateStorageAndHandleSearch,
} from '@/helpers/LocalSettingsHelper';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ExportDropdown from '@/components/ExportDropdown';
import Filters from '@/components/Filters';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowLeftIcon } from '@/images/Icons';
import NoData from '@/components/NoData';
import PageLoader from '@/components/PageLoader';
import Paper from '@material-ui/core/Paper';
import PlainTextCopyColumn from '@/components/PlainTextCopyColumn';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import SearchInput from '@/components/SearchInput';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { convertKeyValueListToQueryString } from '@/helpers/RouteHelper';
import { onClickHandler as onClickHandlerGtag } from '@/helpers/AnalyticsHelper';
import { pick } from 'lodash';
import { pluralizeString } from '@/helpers/DataHelper';
import useFilters from '@/hooks/UseFilters';
import useLocalSettings from '@/hooks/UseLocalSettings';
import { useDrawer } from '@/hooks/UseDrawer';
import { LinearProgress } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      padding: theme.spacing(2),
      paddingBottom: 0,
      background: '#ffffff',
    },
    headerTitle: {
      '&:only-child': {
        paddingBottom: theme.spacing(3),
      },
    },
    foundCount: {
      lineHeight: '3em',
      paddingRight: theme.spacing(4),
      flexShrink: 0,
    },
    formControl: {
      padding: `0 ${theme.spacing()}px`,
      [theme.breakpoints.up('md')]: {
        padding: `0 ${theme.spacing(2)}px`,
      },
    },
    radioGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: 56,
    },
    tableOuterContainer: { flex: '1 1 0' },
    tableContainer: {
      overflowX: 'auto',
      width: '100%',
      display: 'flex',
      flex: '1 1 auto',
      [theme.breakpoints.down('xl')]: {
        overflowY: 'hidden',
        paddingBottom: theme.spacing(2.5),
      },
    },
    textViewWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
    },
    graphViewWrapper: { overflowY: 'auto', width: '100%' },
    filterButton: {
      marginLeft: 10,
    },
    tableUtilsLeft: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    filterButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
      },
    },
    filterButtonWrapperEmpty: {
      [theme.breakpoints.up('sm')]: {
        padding: 0,
      },
    },
    exportButton: {
      paddingLeft: theme.spacing(2),
      textAlign: 'right',
      display: 'inline-block',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    viewSelectLabel: {
      fontSize: 15,
    },
    searchInput: {
      width: '100%',
    },
    headerLabel: {
      textTransform: 'uppercase',
    },
    backButtonContainer: {
      color: theme.palette.common.black,
    },
    backButton: {},
    '@media print': {
      backButton: {
        display: 'none',
      },
    },
  });

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  columns: DataFormatProps[];
  multiSelect?: boolean;
  rowClickHandler?: TableVirtualizedProps['onRowClick'];
  openTooltip?: boolean;
  filterOptionsHandler?: (any: any[]) => void;
  backButtonProps?: {
    onClick: void;
    link: string;
  } | null;
  scrollToRow?: string;
  data?: object[] | null;
  error?: string | null;
  pageTitle: string;
  tableTitleOverride?: string | ReactElement | null;
  dataTitle?: string;
  CardList?: ReactChild;
  HeaderButtons?: ReactChild;
  rowsToUpdate?: (any: string[]) => void;
  testId: string;
  plaintextField?: string;
  plaintextTransform?: (any: object) => string | null;
  allowExport?: boolean;
  minWidth?: number;
  minHeight?: number;
  name?: string;
  filterConfig?: FilterConfig;
  initialActiveFilters?: ActiveFilter;
  defaultActiveFilters?: ActiveFilter;
  defaultSortOrder?: OrderOptions;
  GraphView?: () => ReactElement;
  activeViewDefault?: 'table' | 'text' | 'graph';
  search?: SearchOptions;
  searchFormat?: Array<{
    title: string;
    field: string;
    searchable?: boolean | undefined;
  }>;
  tableControlChildren?: ReactChild;
  handleAddButton?: () => void;
  isLoading?: boolean;
  isErroredPredicate?: (rowData: any) => boolean;
}

const TableWithCards = (props: Props) => {
  const {
    classes,
    columns: columnsSource,
    multiSelect,
    rowClickHandler,
    openTooltip,
    filterOptionsHandler,
    backButtonProps,
    scrollToRow,
    data,
    error,
    pageTitle,
    tableTitleOverride,
    dataTitle,
    CardList,
    HeaderButtons,
    rowsToUpdate,
    testId,
    plaintextField,
    plaintextTransform = row => {
      if (!plaintextField) return row;
      return row[plaintextField];
    },
    allowExport = false,
    minWidth = 730,
    minHeight = 0,
    name,
    filterConfig,
    initialActiveFilters,
    defaultActiveFilters,
    defaultSortOrder,
    history,
    GraphView,
    activeViewDefault = 'table',
    search = { enabled: false, title: 'Search' },
    searchFormat = [],
    handleAddButton,
    tableControlChildren,
    isLoading = false,
    isErroredPredicate,
  } = props;
  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const [activeView, setActiveView] = useState<'table' | 'text' | 'graph'>(
    activeViewDefault
  );
  const [filtersOpen, setFiltersOpen] = useDrawer();

  useEffect(() => {
    // close filters drawer when the table is unmounted
    return () => setFiltersOpen(false);
  }, []);

  const initialSearchValue = initializeSearchSettings(pageTitle);

  const { searchHandler, searchedData } = useSearch(
    data ? [...data] : [],
    searchFormat,
    search.enabled,
    initialSearchValue
  );
  const { createSortHandler, sortedData, order, orderBy, orderId } = useSorting(
    searchedData,
    initializeSortSettings(pageTitle, defaultSortOrder)
  );

  const handleDrawerToggle = () => {
    setFiltersOpen(!filtersOpen);
    onClickHandlerGtag({
      event_category: `toggle_filter_drawer`,
      event_label: filtersOpen ? 'close' : 'open',
    });
  };

  const handleRadioGroupChange = event => {
    setActiveView(event.target.value);
    onClickHandlerGtag({
      event_category: `select_template_view`,
      event_label: event.target.value,
    });
  };

  const {
    filteredData,
    activeFilters,
    activeDisplayedFilters,
    updateActiveFilters,
    allFilterOptions,
  } = useFilters({
    data: sortedData,
    defaultActiveFilters,
    filters: filterConfig,
    initialActiveFilters: initializeFilterSettings(
      pageTitle,
      initialActiveFilters
    ),
    name,
  });

  useEffect(() => {
    filterOptionsHandler && filterOptionsHandler(allFilterOptions);
  }, [allFilterOptions]);

  const rowIsCheckedButHidden = (rowId: string) => {
    return (
      filteredData.findIndex(fRow => fRow.uid === rowId) === -1 &&
      checkedRows.findIndex(cRowId => cRowId === rowId) > -1
    );
  };

  useEffect(() => {
    if (data) {
      let visibleRowIds = checkedRows;
      let hiddenCheckedRowsFound = false;
      data.forEach((row: any) => {
        if (rowIsCheckedButHidden(row.uid)) {
          visibleRowIds = visibleRowIds.filter(id => id !== row.uid);
          hiddenCheckedRowsFound = true;
        }
      });
      if (hiddenCheckedRowsFound) {
        setCheckedRows(visibleRowIds);
      }
    }
  }, [filteredData]);

  let scrollToUid = '';
  if (scrollToRow && filteredData.length > 0) {
    const scrollToElement = filteredData.find(
      data => Object.values(data).indexOf(scrollToRow) > -1
    );
    if (scrollToElement) scrollToUid = scrollToElement.uid;
  }

  useEffect(() => {
    const newSearch = convertKeyValueListToQueryString(
      activeFilters,
      'groupDataKey',
      'optionDataKey'
    );
    history.replace({ search: newSearch });
  }, [activeFilters]);

  const columns = columnsSource.map((item, index) => ({
    ...item,
    ...(!item.disableSort
      ? {
          sortHandler: createSortHandler(item.dataKey, index),
        }
      : {}),
  }));

  const exportData = useMemo(() => {
    if (!filteredData) return [];
    const cols = columns.reduce(
      (rows: any[], row) => [...rows, row.dataKey],
      []
    );
    return filteredData.map(item => pick(item, cols));
  }, [filteredData]);

  const displaySearch = activeView === 'text' || activeView === 'table';

  useLocalSettings(pageTitle, order, orderBy, orderId, activeFilters);

  if (!data) {
    return <PageLoader pageTitle={pageTitle} />;
  }

  let filterButtonText = 'Filters';
  if (activeDisplayedFilters && activeDisplayedFilters.length > 0) {
    const pipeCount =
      activeDisplayedFilters
        .reduce((str: string, filter) => str + filter.optionDataKey, '')
        .split('|').length - 1;
    const numActiveDisplayedFilters = activeDisplayedFilters.length + pipeCount;
    filterButtonText = `${filterButtonText} (${numActiveDisplayedFilters})`;
  }
  const hasMultipleViews = GraphView || plaintextField;

  const handleRowSelect = (rowId: any, checked: boolean) => {
    if (checked) {
      setCheckedRows(checkedRows.concat(rowId));
      rowsToUpdate && rowsToUpdate(checkedRows.concat(rowId));
    } else {
      setCheckedRows(checkedRows.filter(id => id !== rowId));
      rowsToUpdate && rowsToUpdate(checkedRows.filter(id => id !== rowId));
    }
  };
  const handleHeaderSelect = (rowIds: any) => {
    setCheckedRows(rowIds);
    rowsToUpdate && rowsToUpdate(rowIds);
  };

  return (
    <div className={classes.root} data-testid={`screen-${testId}`}>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={12} className={classes.header}>
          <Typography
            component="h1"
            variant="h6"
            align="left"
            className={classes.headerTitle}
          >
            {backButtonProps && (
              <Link
                to={backButtonProps.link}
                onClick={() => backButtonProps.onClick}
                className={classes.backButtonContainer}
              >
                <IconButton
                  data-testid={`screen-${testId}-back-button`}
                  color="inherit"
                  aria-label="Back To Previous Screen"
                  className={classes.backButton}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Link>
            )}
            {tableTitleOverride || pageTitle}
          </Typography>
          {CardList}
        </Grid>
      </Grid>
      {CardList && <Divider />}
      {!data && (
        <NoData
          data={data}
          error={error}
          dataMessage={`Currently, there are no ${dataTitle} associated to this Org.`}
          errorMessage={`There was an error fetching ${dataTitle} for this Org.`}
        />
      )}
      {data && (
        <>
          <Paper elevation={0}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} md={6} className={classes.tableUtilsLeft}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  display="block"
                  align="left"
                  className={classNames(classes.foundCount)}
                  noWrap
                >
                  {multiSelect && checkedRows.length > 0
                    ? `${checkedRows.length} ${pluralizeString(
                        'item',
                        checkedRows.length
                      )} selected`
                    : `${filteredData.length} of ${
                        data.length
                      } ${pluralizeString('item', data.length)} found`}
                </Typography>
                {checkedRows.length === 0 &&
                  displaySearch &&
                  search &&
                  search.enabled && (
                    <SearchInput
                      searchHandler={updateStorageAndHandleSearch(
                        pageTitle,
                        searchHandler
                      )}
                      title={search.title}
                      className={classes.searchInput}
                      defaultValue={initialSearchValue}
                    />
                  )}
                {checkedRows.length > 0 && HeaderButtons}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className={classNames(classes.filterButtonWrapper, {
                  [classes.filterButtonWrapperEmpty]: !(
                    hasMultipleViews ||
                    filterConfig ||
                    allowExport
                  ),
                })}
              >
                {hasMultipleViews && (
                  <FormControl className={classes.formControl}>
                    <RadioGroup
                      aria-label="View Type"
                      name="view type"
                      className={classes.radioGroup}
                      value={activeView}
                      onChange={handleRadioGroupChange}
                    >
                      {GraphView && (
                        <FormControlLabel
                          className={classes.headerLabel}
                          classes={{
                            label: classes.viewSelectLabel,
                          }}
                          value="graph"
                          control={<Radio color="primary" />}
                          label="Graph View"
                          aria-label={`Switch ${pageTitle} to Graph View`}
                        />
                      )}
                      <FormControlLabel
                        className={classes.headerLabel}
                        classes={{
                          label: classes.viewSelectLabel,
                        }}
                        value="table"
                        control={<Radio color="primary" />}
                        label="Table"
                        aria-label={`Switch ${pageTitle} to Table View`}
                      />
                      {plaintextField && (
                        <FormControlLabel
                          className={classes.headerLabel}
                          classes={{
                            label: classes.viewSelectLabel,
                          }}
                          value="text"
                          control={<Radio color="primary" />}
                          label="Text"
                          aria-label={`Switch ${pageTitle} to Text View`}
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                )}
                {!!tableControlChildren && tableControlChildren}
                <div>
                  {filterConfig && (
                    <Button
                      data-testid="filters-toggle-button"
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.filterButton}
                      onClick={handleDrawerToggle}
                      disabled={checkedRows.length > 0}
                      aria-label="Open list of filters"
                    >
                      {filterButtonText}
                    </Button>
                  )}
                  {!!handleAddButton && (
                    <Button
                      data-testid="filters-toggle-button"
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.filterButton}
                      onClick={handleAddButton}
                      aria-label="Open list of filters"
                      endIcon={<AddIcon />}
                    >
                      Add
                    </Button>
                  )}
                  {allowExport && (
                    <div className={classes.exportButton}>
                      <ExportDropdown
                        data={exportData}
                        columns={columnsSource.map(col => ({
                          label: col.label as string,
                          dataKey: col.dataKey,
                        }))}
                        csvEnabled
                        jsonEnabled
                        fileName={testId}
                        testId={`${testId}-tablewithcards-exportdata`}
                      />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
          <Grid
            container
            direction="column"
            item
            xs={12}
            className={classes.tableOuterContainer}
          >
            <Divider />
            {isLoading && <LinearProgress />}
            {activeView === 'table' && (
              <Grid
                item
                xs={12}
                data-testid={`${testId}-table-view`}
                className={classNames(classes.tableContainer)}
              >
                {filteredData && filteredData.length ? (
                  <TableVirtualized
                    rowHeight={52}
                    minWidth={minWidth}
                    minHeight={minHeight}
                    rowCount={filteredData.length}
                    rowIds={filteredData.map(data => data.uid)}
                    rowGetter={getRowData(filteredData)}
                    sortOrder={order}
                    sortOrderId={orderId}
                    columns={columns}
                    multiSelect={multiSelect ? multiSelect : false}
                    handleRowSelect={handleRowSelect}
                    handleHeaderSelect={handleHeaderSelect}
                    onRowClick={rowClickHandler}
                    scrollToIndex={filteredData
                      .map(data => data.uid)
                      .indexOf(scrollToUid)}
                    openTooltip={openTooltip}
                    isErroredPredicate={isErroredPredicate}
                  />
                ) : (
                  <NoData
                    data={filteredData}
                    dataMessage={`No ${(
                      dataTitle || 'records'
                    ).toLowerCase()} found.`}
                  />
                )}
              </Grid>
            )}
            {activeView === 'text' && plaintextField && (
              <Grid
                item
                xs={12}
                className={classNames(classes.textViewWrapper)}
              >
                <PlainTextCopyColumn
                  data={filteredData.map(plaintextTransform)}
                  dataKey={plaintextField}
                />
              </Grid>
            )}
            {activeView === 'graph' && GraphView && (
              <Grid
                item
                xs={12}
                className={classNames(classes.graphViewWrapper)}
              >
                {GraphView()}
              </Grid>
            )}
          </Grid>
        </>
      )}
      {filterConfig && (
        <Filters
          filters={filterConfig}
          isOpen={filtersOpen}
          setIsOpen={setFiltersOpen}
          activeFilter={activeFilters}
          handleChangeFilters={updateActiveFilters}
          tableName={name || ''}
          disable={checkedRows.length > 0}
        />
      )}
    </div>
  );
};
export default withStyles(styles)(withRouter(TableWithCards));
