import { default as React, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import QuestionList from '@/components/discovery/QuestionList';
import { buildQueryParams } from '@/helpers/RouteHelper';
import { styles } from '@/screens/DiscoveryParents/styles';
import { useApiData } from '@/hooks/UseApiData';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import { useLayoutContext } from '@/App';
import { useUnsetOrg } from '@/hooks/OrgContext';
import { WithStyles, withStyles } from '@material-ui/core';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

const DiscoveryParents = (props: Props) => {
  const { history } = props;
  const {
    reverseLookup,
    fetchIndicatorDetails,
    selectedDiscovery,
    reactivate,
    summary,
    selectedIndicator,
    setSelectedIndicator,
  } = useDomainDiscoveryContext();
  const { setError } = useLayoutContext();
  const [questions, setQuestions] = useState();

  const sortQuestions = (q1, q2) => {
    return (
      q1.questionType
        .toLowerCase()
        .localeCompare(q2.questionType.toLowerCase()) ||
      q1.name.toLowerCase().localeCompare(q2.name.toLowerCase())
    );
  };

  const refreshData = useApiData({
    apiType: 'indicators',
    setData: setQuestions,
    setError,

    queryParams: useMemo(() => {
      if (!selectedIndicator) return '';

      const params = selectedIndicator.uid
        ? {
            outputUid: selectedIndicator.uid,
          }
        : {};

      return buildQueryParams(params);
    }, [selectedIndicator]),
    sourceDataPopulated: !!(
      selectedDiscovery?.uid &&
      (selectedIndicator?.uid || selectedIndicator?.indicatorType)
    ),
  });

  // Always unselect org since it doesn't relate to this screen
  useUnsetOrg();

  const pageTitle = 'Parent Questions';

  useEffect(() => {
    let params = history.location.search;
    if (params) {
      params = params.slice(1);
      params = JSON.parse(
        '{"' +
          decodeURI(params)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      if (
        params.indicatorUid &&
        params.indicatorUid !== selectedIndicator?.uid
      ) {
        setSelectedIndicator({
          uid: params.indicatorUid,
          name: params.indicatorName,
          indicatorType: params.indicatorType,
          uidType: 'output',
        });
      }
    }
  }, [history.location]);

  return (
    <QuestionList
      pageTitle={pageTitle}
      questions={questions}
      inputName={selectedIndicator?.name}
      inputType={selectedIndicator?.indicatorType}
      reverseLookup={reverseLookup}
      fetchIndicatorDetails={fetchIndicatorDetails}
      reactivate={reactivate}
      setAnswersRecorded={() => {
        setQuestions(null);
        refreshData();
      }}
      questionSortFunc={selectedIndicator?.uid ? null : sortQuestions}
      autoExpandFirst={!selectedIndicator?.uid}
      maxQuestions={50}
      discoveryErrors={!!summary?.errorStatus}
      setSelectedIndicator={setSelectedIndicator}
      defaultAnswer={null}
      showTypeSelect={false}
    />
  );
};

export default withStyles(styles, { withTheme: true })(
  withRouter(DiscoveryParents)
);
