import { palette } from '@/helpers/MuiThemeHelper';
import { Switch, withStyles } from '@material-ui/core';

const ToggleSwitch = withStyles({
  switchBase: {
    color: 'rgba(0,0,0,0.3)',
    '&$checked': {
      color: palette.primary['A500'],
    },
    '&$checked + $track': {
      backgroundColor: palette.primary['A500'],
    },
  },
  checked: {},
  track: {},
})(Switch);

export default ToggleSwitch;
