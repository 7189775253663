import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { useDocumentTitle } from '@/App';

interface Props {
  pageTitle: string;
}

const PageLoader = ({ pageTitle }: Props) => {
  useDocumentTitle(pageTitle);

  return (
    <>
      <LinearProgress />
    </>
  );
};

export default PageLoader;
