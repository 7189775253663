import { default as React, useEffect, useState } from 'react';
import {
  Grid,
  MenuItem,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { useUnsetOrg } from '@/hooks/OrgContext';
import { styles } from '@/screens/DiscoverySeeds/styles';
import { useApiPost } from '@/hooks/UseApiPost';
import { DataFormatProps } from '@/components/TableVirtualized';
import DomainDiscoverySummary from '@/components/discovery/Summary';
import { useLayoutContext } from '@/App';
import { CustomSeed, Seed, SeedType, TypeDisplay } from '@/types/discovery';
import CustomSeedInput from '@/screens/DiscoverySeeds/CustomSeedInput';
import SeedInput from '@/screens/DiscoverySeeds/SeedInput';
import SeedTable from '@/screens/DiscoverySeeds/SeedTable';
import capitalize from '@material-ui/core/utils/capitalize';

interface Props extends WithStyles<typeof styles> {}

const DiscoverySeeds = ({ classes }: Props) => {
  const pageTitle = 'Seed Data';

  const { setError } = useLayoutContext();

  const [seedData, setSeedData] = useState<Array<Seed>>(Array<Seed>());
  const [customSeedData, setCustomSeedData] = useState<Array<CustomSeed>>(
    Array<CustomSeed>()
  );

  const [selectedType, setSelectedType] = useState(SeedType.domain);

  const [postData, setPostData] = useState();
  const [seedError, setSeedError] = useState();
  const [submitSeedData] = useApiPost(
    'seeds',
    setPostData,
    () => {},
    setSeedError
  );
  const [customPostData, setCustomPostData] = useState();
  const [submitCustomSeedData] = useApiPost(
    'indicators',
    setCustomPostData,
    () => {},
    setSeedError
  );

  const handleSubmit = () => {
    if (!seedData?.length) return;
    submitSeedData(seedData, 'json', 'POST');
  };

  const handleSubmitCustom = () => {
    if (!customSeedData?.length) return;
    submitCustomSeedData(customSeedData, 'json', 'POST');
  };

  // Always unselect org since it doesn't relate to this screen
  useUnsetOrg();

  // Update after successful create seeds action
  useEffect(() => {
    if (!postData) {
      return;
    }

    setSeedData(new Array<Seed>());
  }, [postData]);

  // Update after successful custom create seeds action
  useEffect(() => {
    if (!customPostData) {
      return;
    }

    setCustomSeedData(new Array<CustomSeed>());
  }, [customPostData]);

  useEffect(() => {
    if (seedError) {
      setError(new Error(seedError.error));
    }
  }, [seedError]);

  const seedForm = (
    <Grid
      container
      className={classes.seedForm}
      spacing={3}
      alignItems="flex-start"
    >
      <Grid item xs={2} className={classes.seedFormItem}>
        <TextField
          id="filled-select-type"
          select
          label="Type"
          value={selectedType}
          variant="outlined"
          onChange={event => setSelectedType(event.target.value as SeedType)}
          inputProps={{ 'data-testid': 'seed-input-type' }}
          fullWidth
        >
          {Object.keys(SeedType).map(t => {
            const seedType = SeedType[t];
            return (
              <MenuItem key={t} value={t}>
                {TypeDisplay[seedType]}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      {SeedType[selectedType] === SeedType.custom ? (
        <CustomSeedInput
          seedData={customSeedData}
          setSeedData={setCustomSeedData}
        />
      ) : (
        <SeedInput
          selectedType={selectedType}
          seedData={seedData}
          setSeedData={setSeedData}
        />
      )}
    </Grid>
  );

  const seedColumns: DataFormatProps[] = [
    {
      label: 'Type',
      dataKey: 'type',
      cellContentRenderer: ({ rowData }) => {
        return TypeDisplay[rowData.type];
      },
      flexGrow: 1,
      width: 80,
    },
    {
      label: 'Name',
      dataKey: 'name',
      flexGrow: 4,
      width: 160,
    },
  ];

  const customSeedColumns: DataFormatProps[] = [
    {
      label: 'Name',
      dataKey: 'name',
      flexGrow: 2,
      width: 80,
    },
    {
      label: 'Domains',
      dataKey: 'domains',
      cellContentRenderer: ({ rowData }) => {
        return rowData.domains.join(', ');
      },
      flexGrow: 4,
      width: 160,
    },
    {
      label: 'Confidence',
      dataKey: 'confidence',
      cellContentRenderer: ({ rowData }) => {
        return capitalize(rowData.confidence.toString());
      },
      flexGrow: 2,
      width: 80,
    },
    {
      label: 'Raw Evidence',
      dataKey: 'rawEvidence',
      cellContentRenderer: ({ rowData }) => (
        <Typography className={classes.json} variant="caption">
          {JSON.stringify(rowData.rawEvidence, null, 2)}
        </Typography>
      ),
      flexGrow: 8,
      width: 160,
    },
  ];

  return (
    <>
      <DomainDiscoverySummary pageTitle={pageTitle} />
      <Grid container> {seedForm}</Grid>
      <SeedTable
        seedData={seedData}
        handleSubmit={handleSubmit}
        setSeedData={setSeedData}
        columns={seedColumns}
        title="New Seeds"
      />
      <SeedTable
        seedData={customSeedData}
        handleSubmit={handleSubmitCustom}
        setSeedData={setCustomSeedData}
        columns={customSeedColumns}
        title="Custom Seeds"
      />
    </>
  );
};

export default withStyles(styles, { withTheme: true })(DiscoverySeeds);
