import React, { useEffect, useState } from 'react';

import EventListener from 'react-event-listener';
import Message from '@/components/Message';
import { Snackbar } from '@material-ui/core';
import { onClickHandler as onClickHandlerGtag } from '@/helpers/AnalyticsHelper';
import { useAuthContext } from '@/hooks/AuthContext';

export const defaultLogoutDelay = 1000 * 60 * 30;
export const defaultWarningDelay = 1000 * 60 * 29;

export const logoutWarningMessage =
  'User will be logged out in one minute due to inactivity.';
export const logoutErrorMessage = 'User has been logged out due to inactivity.';

export const userHasBeenLoggedOut = () => {
  localStorage.setItem('sessionExpired', 'true');
};

interface Props {
  logoutDelay?: number;
  warningDelay?: number;
}

const AutoLogout = (props: Props) => {
  const {
    logoutDelay = defaultLogoutDelay,
    warningDelay = defaultWarningDelay,
  } = props;
  const { isAuthenticated, logout } = useAuthContext();
  const [appActive, setAppActive] = useState<Date>();
  const [logoutWarning, setLogoutWarning] = useState<boolean>(false);
  const [logoutError, setLogoutError] = useState<boolean>(false);

  const resetAppActive = () => {
    setAppActive(new Date());
  };
  const closeError = () => {
    setLogoutError(false);
  };
  const handleMouseClick = () => {
    resetAppActive();
  };

  useEffect(() => {
    localStorage.removeItem('sessionExpired');
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;
    const logoutHandler = setTimeout(() => {
      userHasBeenLoggedOut();
      logout();
    }, logoutDelay);

    return () => {
      clearTimeout(logoutHandler);
    };
  }, [appActive, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) return;
    const warningHandler = setTimeout(() => {
      setLogoutWarning(true);
    }, warningDelay);

    return () => {
      setLogoutWarning(false);
      clearTimeout(warningHandler);
    };
  }, [appActive, isAuthenticated]);

  return (
    <>
      <EventListener target={document} onClickCapture={handleMouseClick} />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={logoutWarning}
      >
        <Message
          variant="warning"
          message={logoutWarningMessage}
          onClose={() => {
            resetAppActive();
            onClickHandlerGtag({
              event_category: `close_snackback`,
              event_label: 'logout warning message',
            });
          }}
        />
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={logoutError}
      >
        <Message
          variant="error"
          message={logoutErrorMessage}
          onClose={() => {
            closeError();
            onClickHandlerGtag({
              event_category: `close_snackback`,
              event_label: 'logout error message',
            });
          }}
        />
      </Snackbar>
    </>
  );
};

export default AutoLogout;
