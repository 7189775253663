import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(),
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
  });
