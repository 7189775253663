import { useEffect, useState } from 'react';

import { GetDataForPage } from '@/helpers/PaginationHelper';
import { OrderOptions } from './UseSorting';
import { useAuthContext } from '@/hooks/AuthContext';
import { useDebounce } from './useDebounce';

export interface PaginationOptions {
  data?: Array<any>;
  defaultRowsPerPage: number;
  getDataForPage?: GetDataForPage;
  onChangePage?: (page: number) => void;
  searchTerm?: string;
  orderOptions?: OrderOptions;
}

export const usePagination = (options: PaginationOptions) => {
  const {
    data,
    defaultRowsPerPage = 10,
    getDataForPage,
    onChangePage,
    searchTerm,
    orderOptions,
  } = options;

  const [paginatedData, setPaginatedData] = useState();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(defaultRowsPerPage);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [isLoading, setIsLoading] = useState(false);

  const { accessToken } = useAuthContext();

  const handleChangePage = (event, page) => {
    setPage(page);

    if (typeof onChangePage === 'function') {
      onChangePage(page);
    }
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (!getDataForPage || !accessToken) return;

    setIsLoading(true);

    getDataForPage({
      page,
      perPage,
      accessToken,
      searchTerm,
      orderOptions,
    }).then(({ data, total }) => {
      setPaginatedData(data);
      setTotalRecords(+total);
      setIsLoading(false);
    });
  }, [
    accessToken,
    debouncedSearchTerm,
    getDataForPage,
    orderOptions,
    page,
    perPage,
  ]);

  // reset page to beginning if search is triggered
  useEffect(() => {
    if (!getDataForPage || !searchTerm) return;

    if (getDataForPage && searchTerm?.length === 3) {
      setPage(0);
    }
  }, [searchTerm]);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  // If all data was passed in directly
  useEffect(() => {
    if (!data || getDataForPage) return;

    const start = page * rowsPerPage;
    const end = Math.min((page + 1) * rowsPerPage, data.length);

    if (data && data.length >= end && start >= 0) {
      setPaginatedData(data.slice(start, end));
      setTotalRecords(data.length);
    }
  }, [data, getDataForPage, page, rowsPerPage]);

  return {
    paginatedData,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    isLoading,
    setPerPage,
    totalRecords,
  };
};
