import '@/Fonts.css';

import * as serviceWorker from '@/serviceWorker';

import App from '@/App';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { loadEnvVars } from '@/hooks/EnvVars';
import theme from '@/helpers/MuiThemeHelper';

loadEnvVars();

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <React.Fragment>
      <CssBaseline />
      <Router>
        <App />
      </Router>
    </React.Fragment>
  </MuiThemeProvider>,
  document.getElementById('root')
);

/** If you want your app to work offline and load faster, you can change
     unregister() to register() below. Note this comes with some pitfalls.
     Learn more about service workers: https://bit.ly/CRA-PWA */
serviceWorker.unregister();
