import {
  Button,
  FormControl,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  WithStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';

import DeleteIcon from '@material-ui/icons/Delete';
import FormLabel from '@material-ui/core/FormLabel';
import SelectOption from '@/components/SelectOption';
import { onClickHandler as onClickHandlerGtag } from '@/helpers/AnalyticsHelper';
import { ruleFormStyles } from '@/components/discovery/RuleDrawer/styles';
import { withStyles } from '@material-ui/core/styles';
import { Rule } from '@/types/discovery';

export enum VertexTypes {
  name = 'Name',
  email = 'Email',
  tlsorganization = 'Organization',
  nameserver = 'Name Server',
}

interface Props extends WithStyles<typeof ruleFormStyles> {
  currentRule: Rule;
  onUpdateRule: (rule: Rule) => void;
  onCreateRule: (rule: Rule) => void;
  onDeleteRule: (rule: Rule) => void;
  method: String;
}

const RuleForm = ({
  classes,
  currentRule,
  onCreateRule,
  onUpdateRule,
  onDeleteRule,
  method,
}: Props) => {
  const [rule, setRule] = useState(currentRule);
  const [nameError, setNameError] = useState();
  const [matches, setMatches] = useState();
  const { ruleMatches } = useDomainDiscoveryContext();

  const handleUpdateRule = update => {
    setRule({ ...rule, ...update });
  };

  const validateForm = () => {
    let hasError = false;
    hasError =
      (!rule.pattern
        ? (setNameError(true), true)
        : (setNameError(false), false)) || hasError;

    return !hasError;
  };

  useEffect(() => {
    if (!currentRule) {
      return;
    }

    setMatches(undefined);
    setRule(currentRule);
  }, [currentRule]);

  const RuleMatches = (matches: string[]) => {
    if (!matches || matches.length === 0) {
      return (
        <FormLabel
          className={classes.formLabelLegend}
          component={'legend' as 'label'}
        >
          <Typography variant="body2">No Rule Matches Found</Typography>
        </FormLabel>
      );
    }

    return (
      <>
        <FormLabel
          className={classes.formLabelLegend}
          component={'legend' as 'label'}
        >
          <Typography variant="body2">
            Rule Matches ( {matches.length} )
          </Typography>
        </FormLabel>
        <List dense className={classes.matchesList}>
          {matches
            ?.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
            .map(m => (
              <ListItem key={m}>
                <ListItemText primary={m} />
              </ListItem>
            ))}
        </List>
      </>
    );
  };

  return (
    <FormControl
      key="add-rule"
      component={'fieldset' as 'div'}
      className={classes.formControl}
    >
      <FormLabel
        className={classes.formLabelLegend}
        component={'legend' as 'label'}
      >
        <Typography variant="body2">Rule Info</Typography>
      </FormLabel>
      <TextField
        id="pattern"
        label="Pattern"
        error={nameError}
        onChange={({ target: { value } }) =>
          handleUpdateRule({ pattern: value })
        }
        value={rule.pattern}
        className={classes.formElement}
        inputProps={{ 'data-testid': 'pattern-input' }}
      />
      <SelectOption
        id="type"
        label="Type"
        key="vertexType"
        value={rule.vertexType}
        error={nameError}
        className={classes.formElement}
        onChange={value => {
          handleUpdateRule({ vertexType: value });
        }}
        menuItems={[
          ...Object.entries(VertexTypes)?.map(vertexType => (
            <MenuItem value={`${vertexType[0]}`} key={`${vertexType[0]}`}>
              {vertexType[1]}
            </MenuItem>
          )),
        ]}
      />
      <Button
        data-testid="show-matches-button"
        variant="outlined"
        color="primary"
        className={classes.previewButton}
        onClick={() => {
          ruleMatches(rule, setMatches);
        }}
        aria-label={`update Rule in auth provider`}
      >
        Preview Rule
      </Button>
      {rule.pattern && matches !== undefined && RuleMatches(matches)}
      <Button
        data-testid="update-rule-button"
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => {
          if (!validateForm()) return;

          if (method === 'Create') {
            onCreateRule(rule);
            setRule(currentRule);
            onClickHandlerGtag({
              event_category: `create_rule`,
            });
          } else {
            onUpdateRule(rule);
            onClickHandlerGtag({
              event_category: `update_rule`,
            });
          }
        }}
        aria-label={`update Rule in auth provider`}
      >
        {method} Rule
      </Button>

      {method !== 'Create' && (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<DeleteIcon />}
          onClick={() => {
            onDeleteRule(rule);
            onClickHandlerGtag({
              event_category: `delete_rule`,
            });
          }}
        >
          Delete
        </Button>
      )}
    </FormControl>
  );
};

export default withStyles(ruleFormStyles, { withTheme: true })(RuleForm);
