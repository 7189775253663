import {
  ActiveFilter,
  FilterClickOptions,
  FilterConfig,
} from '@/helpers/FilterHelpers';
import { Grid, IconButton, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { KeyboardArrowRightIcon } from '@/images/Icons';
import OptionsCheckboxes from './OptionsCheckboxes';
import OptionsRadio from './OptionsRadio';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import find from 'lodash/find';
import get from 'lodash/get';
import { onClickHandler as onClickHandlerGtag } from '@/helpers/AnalyticsHelper';
import { useApiPost } from '@/hooks/UseApiPost';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { styles } from '@/components/Filters/styles';

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  filters: FilterConfig;
  activeFilter: ActiveFilter;
  handleChangeFilters: (
    activeFilter: ActiveFilter,
    options?: FilterClickOptions
  ) => void;
  tableName: string;
  disable: boolean;
}

const Filters = ({
  classes,
  theme,
  isOpen,
  setIsOpen,
  filters,
  activeFilter,
  handleChangeFilters,
  tableName,
  disable,
}: Props) => {
  const breakpointIsLgPlus = useMediaQuery(theme.breakpoints.up('lg'));
  const [, setPostData] = useState();
  const [, setPostError] = useState();

  const [updateUserPreferences] = useApiPost(
    'userPreferences',
    setPostData,
    setPostError
  );

  return (
    <div className={classes.drawer} data-testid="component-filters">
      <Drawer
        variant={breakpointIsLgPlus ? 'persistent' : 'temporary'}
        open={isOpen}
        anchor="right"
        onClose={() => {
          setIsOpen(false);
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                data-testid="component-filters-close-button"
                className={classes.closeButton}
                color="inherit"
                aria-label="Close Filters"
                onClick={() => {
                  setIsOpen(false);
                }}
                disabled={disable}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={classNames(classes.grow, disable && classes.grey)}
                align="left"
              >
                Filters
              </Typography>
              <Button
                data-testid="component-filters-clear-button"
                variant="outlined"
                color="primary"
                onClick={() => {
                  handleChangeFilters(undefined);
                  onClickHandlerGtag({
                    event_category: `clear_filter`,
                  });
                }}
                aria-label="Reset all active filters to default"
                disabled={disable}
              >
                Default
              </Button>
            </Toolbar>
            <Divider />
          </Grid>
          <Grid item xs={12} className={classes.filterForm}>
            {filters &&
              filters.map(
                ({
                  dataKey: groupDataKey,
                  groupLabel,
                  options: groupOptions,
                  display,
                  multiSelect,
                }) => {
                  if (!display) {
                    return null;
                  }
                  const optionDataKey = !activeFilter
                    ? undefined
                    : get(
                        find(activeFilter, {
                          groupDataKey: groupDataKey,
                        }) || {},
                        'optionDataKey'
                      );
                  const OptionsElement = multiSelect
                    ? OptionsCheckboxes
                    : OptionsRadio;
                  return (
                    <FormControl
                      key={groupDataKey}
                      component={'fieldset' as 'div'}
                      className={classes.formControl}
                    >
                      <FormLabel
                        className={classNames(
                          classes.formLabelLegend,
                          disable && classes.grey
                        )}
                        component={'legend' as 'label'}
                      >
                        <Typography variant="body2">{groupLabel}</Typography>
                      </FormLabel>
                      <OptionsElement
                        groupOptions={groupOptions}
                        groupLabel={groupLabel}
                        groupDataKey={groupDataKey}
                        optionDataKey={optionDataKey}
                        handleChangeFilters={handleChangeFilters}
                        disable={disable}
                      />
                    </FormControl>
                  );
                }
              )}
          </Grid>
        </Grid>
        <Button
          data-testid="component-filters-save-button"
          variant="outlined"
          color="primary"
          className={classes.saveFilterBtn}
          onClick={() => {
            updateUserPreferences(
              { filters: { [tableName]: activeFilter } },
              'json',
              'PATCH'
            );
            onClickHandlerGtag({
              event_category: `save_filter`,
            });
            setIsOpen(false);
          }}
          aria-label="Save current filter selections as default"
          disabled={disable}
        >
          Save Selection
        </Button>
      </Drawer>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Filters);
