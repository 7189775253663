import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { styles } from '@/components/discovery/StatusIcon/styles';

interface Props extends WithStyles<typeof styles> {
  status: string;
}

const StatusIcon = (props: Props) => {
  const { classes, status } = props;

  // tolerant to various data scenarios
  const statusToIconMap = {
    approved: <CheckCircleIcon className={classes.approved} />,
    denied: <CancelIcon className={classes.denied} />,
    pending: <HelpIcon className={classes.unknown} />,
  };

  const normalizedStatus = status.toLowerCase();

  if (statusToIconMap[normalizedStatus]) {
    return statusToIconMap[normalizedStatus];
  }
  return <HelpIcon className={classes.unknown} />;
};

export default withStyles(styles)(StatusIcon);
