import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    seedForm: {
      padding: `0 ${theme.spacing()}px`,
      margin: theme.spacing(),
    },
    seedFormItem: {
      padding: `0 ${theme.spacing(4)}px`,
      minWidth: '120px',
    },
    seedTable: {
      padding: theme.spacing(),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: '800px',
      background: theme.palette.background.default,
    },
    seedTableInner: {
      padding: theme.spacing(),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    json: {
      fontFamily: 'monospace',
    },
  });
