import { IconButton, Menu, MenuItem } from '@material-ui/core';
import FileSaver from 'file-saver';
import React, { MouseEvent, useState } from 'react';
import { onClickHandler as onClickHandlerGtag } from '@/helpers/AnalyticsHelper';
import {
  formatAndStringifyJSON,
  jsonFormatReplacer,
  jsonToCSV,
} from '@/helpers/DataHelper';
import { useOrgContext } from '@/hooks/OrgContext';
import { MoreVertIcon } from '@/images/Icons';

interface ExportDropdownProps {
  csvEnabled?: boolean;
  jsonEnabled?: boolean;
  data?: object[];
  columns?: object[];
  fileName: string;
  testId?: string;
}

type OptionTypes = 'csv' | 'json';
const optionLabels = {
  csv: 'Export as CSV',
  json: 'Export as JSON',
};

const ExportDropdown = (props: ExportDropdownProps) => {
  const {
    csvEnabled = false,
    jsonEnabled = false,
    data,
    columns,
    fileName,
    testId,
  } = props;
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);

  const { selectedOrgInfo } = useOrgContext();
  const currentDateTime = new Date().toJSON().replace(':', '-');
  let fullFileName = `${fileName}-${currentDateTime}`;
  if (selectedOrgInfo) {
    fullFileName = `${selectedOrgInfo.organizationId}-${fullFileName}`;
  }

  let options: OptionTypes[] = [];
  if (csvEnabled) options = [...options, 'csv'];
  if (jsonEnabled) options = [...options, 'json'];

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
    onClickHandlerGtag({
      event_category: `toggle_export_menu`,
      event_label: !open ? 'open' : 'close',
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!data) return null;

  const createSelectHandler = (selected: OptionTypes) => (
    event: MouseEvent
  ) => {
    if (selected === 'csv') {
      const str = jsonToCSV(data, jsonFormatReplacer, columns);
      const blob = new Blob([str], {
        type: 'text/plain;charset=utf-8',
      });
      FileSaver.saveAs(blob, `${fullFileName}.csv`);
    } else if (selected === 'json') {
      const str = formatAndStringifyJSON(data, jsonFormatReplacer, columns);
      const blob = new Blob([str], {
        type: 'text/plain;charset=utf-8',
      });
      FileSaver.saveAs(blob, `${fullFileName}.json`);
    }
    onClickHandlerGtag({
      event_category: `export_file`,
      event_label: fileName,
      content_type: selected,
    });
    handleClose();
  };
  const menuId = `export-menu-${testId}`;

  return (
    <div>
      <IconButton
        aria-label={open ? 'View export options' : 'Hide export options'}
        aria-owns={open ? menuId : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        data-testid={`export-dropdown-${testId}`}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id={menuId} anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map(option => (
          <MenuItem
            key={option}
            onClick={createSelectHandler(option)}
            data-testid={`export-option-${testId}-${option}`}
            aria-label={`Export current dataset as a ${option} file`}
          >
            {optionLabels[option]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ExportDropdown;
