import {
  Button,
  Grid,
  MenuItem,
  TextField,
  WithStyles,
} from '@material-ui/core';
import { default as React, Dispatch, SetStateAction, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '@/screens/DiscoverySeeds/styles';
import { Confidence, CustomSeed } from '@/types/discovery';
import AddIcon from '@material-ui/icons/Add';
import capitalize from '@material-ui/core/utils/capitalize';

interface Props extends WithStyles<typeof styles> {
  seedData: CustomSeed[];
  setSeedData: Dispatch<SetStateAction<CustomSeed[]>>;
}

const CustomSeedInput = ({ seedData, setSeedData, classes }: Props) => {
  const [confidenceInput, setConfidenceInput] = useState<Confidence>(
    Confidence.Low
  );
  const [nameInput, setNameInput] = useState<string>('');
  const [domainsInput, setDomainsInput] = useState<string>();
  const [rawEvidenceInput, setRawEvidenceInput] = useState<string>();

  const handleAdd = () => {
    if (!domainsInput || !nameInput || !confidenceInput) return;

    const domains = domainsInput
      .split(/\r?\n/)
      .map(s => s.trim())
      .filter(s => s.length);

    const rawEvidenceJSON = JSON.parse(rawEvidenceInput || '{}');

    const newSeed = {
      name: nameInput,
      domains: domains,
      confidence: confidenceInput,
      rawEvidence: rawEvidenceJSON,
    } as CustomSeed;

    setSeedData(seedData.concat(newSeed));
    setDomainsInput('');
    setNameInput('');
    setRawEvidenceInput('');
  };

  return (
    <>
      <Grid item xs={1} className={classes.seedFormItem}>
        <TextField
          id="filled-select-confidence"
          select
          label="Confidence"
          value={confidenceInput}
          variant="outlined"
          inputProps={{ 'data-testid': 'seed-input-confidence' }}
          onChange={event =>
            setConfidenceInput(event.target.value as Confidence)
          }
          fullWidth
        >
          {Object.keys(Confidence).map(t => {
            const confidence = Confidence[t];
            return (
              <MenuItem key={confidence} value={confidence}>
                {capitalize(confidence)}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={2} className={classes.seedFormItem}>
        <TextField
          id="name-textarea"
          placeholder="Name"
          variant="outlined"
          value={nameInput}
          onChange={event => setNameInput(event.target.value)}
          inputProps={{ 'data-testid': 'seed-input-name' }}
          fullWidth
        />
      </Grid>
      <Grid item xs={3} className={classes.seedFormItem}>
        <TextField
          id="domains-textarea"
          placeholder="Domains"
          variant="outlined"
          value={domainsInput}
          onChange={event => setDomainsInput(event.target.value)}
          inputProps={{ 'data-testid': 'seed-input-domains' }}
          rows={2}
          rowsMax={25}
          multiline
          fullWidth
        />
      </Grid>
      <Grid item xs={3} className={classes.seedFormItem}>
        <TextField
          id="evidence-textarea"
          placeholder="Evidence JSON"
          variant="outlined"
          value={rawEvidenceInput}
          onChange={event => setRawEvidenceInput(event.target.value)}
          inputProps={{ 'data-testid': 'seed-input-evidence' }}
          rows={2}
          rowsMax={25}
          multiline
          fullWidth
        />
      </Grid>
      <Grid item xs={1} className={classes.seedFormItem}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={handleAdd}
        >
          Add
        </Button>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CustomSeedInput);
