import get from 'lodash/get';
import React, { ReactChild, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { screenViewHandler } from '@/helpers/AnalyticsHelper';

interface Props extends RouteComponentProps {
  children: ReactChild;
}

const AnalyticsScreenView = (props: Props) => {
  const { location, children } = props;
  const { pathname } = location;
  useEffect(() => {
    screenViewHandler({
      screen_name: pathname,
      page_title: get(
        window.document.getElementsByTagName('title'),
        '[0].innerText',
        ''
      ),
    });
  }, [pathname]);
  return <React.Fragment>{children}</React.Fragment>;
};

export default withRouter(AnalyticsScreenView);
