import { useLayoutContext } from '@/App';
import { EnvVars } from '@/hooks/EnvVars';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    footerContainer: {},
    footer: {
      background: '#ffffff',
      [theme.breakpoints.up('sm')]: {
        height: 56,
        padding: `0 ${theme.spacing(3)}px`,
      },
      [theme.breakpoints.up('md')]: {
        height: 64,
        paddingLeft: theme.spacing(3.75),
        paddingRight: theme.spacing(3.75),
      },
    },
    footerHasRightDrawer: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(3.75),
        paddingRight: theme.spacing(3.75),
      },
    },
    footerContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
      },
    },
    textLink: {
      color: theme.palette.primary.dark,
    },
    [theme.breakpoints.up('sm')]: {
      footerContentLt: {
        justifyContent: 'flex-start',
      },
      footerContentRt: {
        justifyContent: 'flex-end',
      },
    },
    '@media print': {
      footerContainer: {
        display: 'none',
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  version?: string | undefined;
  year: number;
}

const Footer = ({ classes, year }: Props) => {
  const version = EnvVars['REACT_APP_VERSION'];

  const { rightDrawerOpen } = useLayoutContext();
  return (
    <div className={classes.footerContainer}>
      <Divider />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classNames(classes.footer, {
          [classes.footerHasRightDrawer]: rightDrawerOpen,
        })}
      >
        <Grid
          item
          xs={12}
          sm={9}
          className={`${classes.footerContent} ${classes.footerContentLt}`}
        >
          <Typography
            variant="subtitle1"
            component="span"
            className={classes.legal}
          >
            {`© ${year} `}
            <a className={classes.textLink} href="https://www.bishopfox.com/">
              Bishop Fox
            </a>
            {`, All rights reserved`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          className={`${classes.footerContent} ${classes.footerContentRt}`}
        >
          <Typography variant="subtitle1" component="span">
            {`Version ${version}`}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

Footer.defaultProps = {
  year: new Date().getFullYear(),
};

export default withStyles(styles)(Footer);
