import { Button, Grid, TextField, WithStyles } from '@material-ui/core';
import {
  ChangeEvent,
  default as React,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '@/screens/DiscoverySeeds/styles';
import { Seed, SeedType } from '@/types/discovery';
import AddIcon from '@material-ui/icons/Add';

interface Props extends WithStyles<typeof styles> {
  selectedType: SeedType;
  seedData: Seed[];
  setSeedData: Dispatch<SetStateAction<Seed[]>>;
}

const SeedInput = ({ selectedType, seedData, setSeedData, classes }: Props) => {
  const [inputValue, setInputValue] = useState<String>();

  const handleSeedData = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleAdd = () => {
    if (!inputValue) return;

    const newSeeds = inputValue
      .split(/\r?\n/)
      .map(s => s.trim())
      .filter(s => s.length)
      .map(
        s =>
          ({
            type: selectedType,
            name: s,
          } as Seed)
      );

    const seedHash = {};
    const dedupedSeeds = seedData.concat(newSeeds).filter(({ type, name }) => {
      const key = `${type}${name}`;
      const duplicate = !(key in seedHash);

      seedHash[key] = true;
      return duplicate;
    });

    setSeedData(dedupedSeeds);
    setInputValue('');
  };

  return (
    <>
      <Grid item xs={8} className={classes.seedFormItem}>
        <TextField
          id="value-textarea"
          placeholder="Seed Data"
          variant="outlined"
          value={inputValue}
          onChange={handleSeedData}
          rows={2}
          rowsMax={25}
          inputProps={{ 'data-testid': 'seed-input' }}
          fullWidth
          multiline
        />
      </Grid>
      <Grid
        item
        container
        xs
        alignItems="flex-start"
        justify="flex-end"
        direction="row"
        className={classes.seedFormItem}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={handleAdd}
        >
          Add
        </Button>
      </Grid>
    </>
  );
};

export default withStyles(styles)(SeedInput);
