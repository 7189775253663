import { useHistory } from 'react-router';
import { useLayoutContext } from '@/App';

export const useRouteToPage = () => {
  const history = useHistory();
  const { setRightDrawerOpen, setLeftDrawerOpen } = useLayoutContext();

  const routeToPage = (path: string) => {
    setRightDrawerOpen(false);
    setLeftDrawerOpen(false);
    history.push(path);
  };

  return [routeToPage];
};
