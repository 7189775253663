import { onClickHandler as onClickHandlerGtag } from '@/helpers/AnalyticsHelper';
import {
  Button,
  FormControl,
  FormControlLabel,
  TextField,
  WithStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import { answerFormStyles } from '@/components/discovery/UpdateAnswer/styles';
import capitalize from '@material-ui/core/utils/capitalize';
import ToggleSwitch from '@/components/ToggleSwitch';
import { AnsweredQuestion, Status } from '@/types/discovery';

interface Props extends WithStyles<typeof answerFormStyles> {
  currentAnswer: AnsweredQuestion;
  onUpdateAnswer: (question: AnsweredQuestion) => void;
}

const AnswerForm = ({ classes, currentAnswer, onUpdateAnswer }: Props) => {
  const [answer, setAnswer] = useState(currentAnswer);

  // update right nav when a different row is clicked
  useEffect(() => {
    if (answer.uid === currentAnswer.uid) {
      return;
    }
    setAnswer(currentAnswer);
  }, [currentAnswer]);

  // updates internal answer object state
  const handleUpdateAnswer = update => {
    setAnswer({ ...answer, ...update });
  };

  return (
    <FormControl
      key="add-discovery"
      component={'fieldset' as 'div'}
      className={classes.formControl}
    >
      <FormLabel
        className={classes.formLabelLegend}
        component={'legend' as 'label'}
      >
        {capitalize(currentAnswer.questionType)}
      </FormLabel>

      <TextField
        id="name"
        label="Name"
        value={currentAnswer.name}
        disabled
        className={classes.formElement}
        inputProps={{ 'data-testid': 'name-input' }}
      />

      <FormControlLabel
        control={
          <ToggleSwitch
            inputProps={{ 'data-testid': 'status-toggle' }}
            checked={answer.status === Status.approved}
            onChange={event => {
              const newStatus = event.target.checked
                ? Status.approved
                : Status.denied;
              handleUpdateAnswer({ status: newStatus });
            }}
            value={answer.status}
          />
        }
        label="Accepted"
        labelPlacement="start"
        className={classes.formElement}
      />
      <Button
        data-testid="update-answer-button"
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => {
          onUpdateAnswer(answer);
          onClickHandlerGtag({
            event_category: `update_answer`,
          });
        }}
        aria-label={`update Answer in auth provider`}
      >
        Update Answer
      </Button>
    </FormControl>
  );
};

export default withStyles(answerFormStyles, { withTheme: true })(AnswerForm);
