import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import * as clipboard from 'clipboard-polyfill/build/clipboard-polyfill.promise';
import React, { useState } from 'react';
import { onClickHandler as onClickHandlerGtag } from '@/helpers/AnalyticsHelper';
import { CloseIcon } from '@/images/Icons';
interface Props extends WithStyles<typeof styles> {
  data: Array<string>;
  height?: number | string;
  dataKey: string;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      margin: theme.spacing(2),
    },
    paper: { width: '100%', textAlign: 'left' },
    dataWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        height: 'auto !important',
      },
    },
    data: {
      border: 'none',
      flex: '1 1 0',
      overflow: 'auto',
      textAlign: 'left',
      margin: 0,
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
      ...theme.typography.body2,
    },
    close: {
      padding: theme.spacing(0.5),
    },
  });

const PlainTextCopyColumn = ({ classes, data, height, dataKey }: Props) => {
  let preEl;
  const [snackbarActive, setSnackbarActive] = useState<boolean>(false);

  const writeToClipboard = () => {
    var dataTransfer = new clipboard.DT();
    dataTransfer.setData('text/plain', preEl.innerHTML);
    clipboard.write(dataTransfer).then(() => {
      setSnackbarActive(true);
    });
    onClickHandlerGtag({
      event_category: 'clipboard_write',
    });
  };
  const closeSnackbar = () => {
    setSnackbarActive(false);
    onClickHandlerGtag({
      event_category: 'clipboard_write',
    });
  };

  return (
    <div data-testid="plaintext-text-view" className={classes.container}>
      <Paper elevation={0} className={classes.paper}>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={writeToClipboard}
          aria-label={`Copy ${dataKey} from current dataset data to clipboard`}
        >
          Copy All
        </Button>
      </Paper>
      <Divider />
      <div className={classes.dataWrapper}>
        <textarea
          className={classes.data}
          ref={el => {
            preEl = el;
          }}
          defaultValue={data.join('\n')}
        />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarActive}
        onClose={closeSnackbar}
        autoHideDuration={3000}
        action={[
          <IconButton
            data-testid="snackbar-close-button"
            key="close"
            aria-label="Close message"
            color="inherit"
            className={classes.close}
            onClick={() => {
              closeSnackbar();
              onClickHandlerGtag({
                event_category: `close_snackback`,
                event_label: 'clipboard write message',
              });
            }}
          >
            <CloseIcon />
          </IconButton>,
        ]}
        ContentProps={{
          'aria-describedby': 'plain-text-copy-all-message-id',
        }}
        message={
          <span id="plain-text-copy-all-message-id">{`${dataKey} written to clipboard`}</span>
        }
      />
    </div>
  );
};

export default withStyles(styles)(PlainTextCopyColumn);
