import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { EnvVars } from './EnvVars';
import { History } from 'history';
import createAuth0Client from '@auth0/auth0-spa-js';
import jwt from 'jsonwebtoken';

export interface AuthContext {
  accessToken: string;
  isAuthenticated: boolean;
  userInfo: UserInfo;
  loading: boolean;
  getIdTokenClaims: Function;
  loginWithRedirect: Function;
  logout: Function;
}

export interface UserInfo {
  email?: string;
  email_verified?: boolean;
  sub?: string;
  isAdmin: boolean;
  isCustomer: boolean;
  isOperator: boolean;
  name?: string;
  nickname?: string;
  picture?: string;
  updated_at?: string;
}

export interface AuthContextOverrideExports {
  accessToken?: AuthContext['accessToken'];
  isAuthenticated?: AuthContext['isAuthenticated'];
  userInfo?: AuthContext['userInfo'];
  loading?: AuthContext['loading'];
  getIdTokenClaims?: AuthContext['getIdTokenClaims'];
  loginWithRedirect?: AuthContext['loginWithRedirect'];
  logout?: AuthContext['logout'];
}

interface Props extends RouteComponentProps {
  children: React.ReactChild;
  defaultIsAuthenticated?: boolean;
  defaultAuthObj?: any;
  history: History;
  overrideAuthContextCalls?: AuthContextOverrideExports;
}

export const fallbackAuthContextCalls = {
  isAuthenticated: false,
  loginWithRedirect: () => {},
  logout: () => {},
  authObj: {
    logout: () => {},
    isAuthenticated: () => {},
    handleRedirectCallback: () => {},
    getUser: () => {},
    getIdTokenClaims: () => {},
  },
  userInfo: {},
};

export const testAuthContextValues = { isAuthenticated: true };

export const RootAuthContext = React.createContext(
  (false as unknown) as AuthContext
);

export const AuthProvider: React.FC<Props> = ({
  children,
  history,
  overrideAuthContextCalls,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [userInfo, setUserInfo] = useState();
  const [accessToken, setAccessToken] = useState();
  const [auth0Client, setAuth0Client] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const abort = new AbortController();

    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        domain: EnvVars['REACT_APP_AUTH_DOMAIN'] || '',
        client_id: EnvVars['REACT_APP_AUTH_CLIENT'] || '',
        leeway: 240,
        redirect_uri: (EnvVars['REACT_APP_AUTH_REDIRECT_URI'] || '').replace(
          './callback',
          '/callback'
        ),
        scope: 'openid profile email',
        audience: 'https://guardian',
      });
      if (abort.signal.aborted) {
        return;
      }
      setAuth0Client(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();

        history.replace(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        );
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      if (abort.signal.aborted) {
        return;
      }
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        const accessToken = await auth0FromHook.getTokenSilently();

        if (accessToken) {
          const newUserInfo = jwt.decode(accessToken);
          const roles =
            newUserInfo && newUserInfo['https://bishopfox.com/resources'];
          const isCustomer =
            roles?.[0]?.includes('role/customer') ||
            roles?.[0]?.includes('role/customer-admin');
          const isOperator = !!roles?.filter(r => r.includes('role/operator'))
            ?.length;
          const isAdmin = !!roles?.filter(r => r.includes('role/admin'))
            ?.length;

          if (abort.signal.aborted) {
            return;
          }
          setUserInfo({ ...user, isAdmin, isCustomer, isOperator });
          setAccessToken(accessToken);
        }

        if (abort.signal.aborted) {
          return;
        }
        setLoading(false);
      } else {
        await auth0FromHook.loginWithRedirect();
      }
    };

    initAuth0();

    return () => abort.abort();
  }, []);

  if (
    !isAuthenticated &&
    !(overrideAuthContextCalls && overrideAuthContextCalls.isAuthenticated)
  ) {
    return null;
  }

  return (
    <RootAuthContext.Provider
      value={{
        accessToken,
        isAuthenticated,
        userInfo,
        loading,
        getIdTokenClaims: (options: getIdTokenClaimsOptions) =>
          auth0Client && auth0Client.getIdTokenClaims(options),
        loginWithRedirect: (options: RedirectLoginOptions) =>
          auth0Client && auth0Client.loginWithRedirect(options),
        logout: (options: LogoutOptions) =>
          auth0Client &&
          auth0Client.logout({
            returnTo: window.location.origin,
            ...options,
          }),
        ...overrideAuthContextCalls,
      }}
    >
      {children}
    </RootAuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(RootAuthContext);
  if (!context) {
    throw new Error(`useAuthContext must be called within AuthContextProvider`);
  }
  return context;
};

export default withRouter(AuthProvider);
