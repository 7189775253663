import React, { useReducer } from 'react';

const defaultCloudConnectorStateContext = {
  orgsWithCloudConnectors: [],
  isLoadingCloudConnectorAggregates: false,
  isSubscribed: true,
  errorCount: 0,
};
const CloudConnectorStateContext = React.createContext(
  defaultCloudConnectorStateContext
);
const CloudConnectorDispatchContext = React.createContext();
const actions = {
  APPEND: 'APPEND',
  UPDATE_IS_LOADING: 'UPDATE_IS_LOADING',
  UPDATE_IS_SUBSCRIBED: 'UPDATE_IS_SUBSCRIBED',
  SET_ERROR_COUNT: 'SET_ERROR_COUNT',
};

const reducer = (state = defaultCloudConnectorStateContext, action) => {
  switch (action.type) {
    case actions.APPEND: {
      return {
        ...state,
        orgsWithCloudConnectors: [
          ...state.orgsWithCloudConnectors,
          ...action.payload,
        ],
      };
    }

    case actions.UPDATE_IS_LOADING: {
      return {
        ...state,
        isLoadingCloudConnectorAggregates: action.payload,
      };
    }

    case actions.UPDATE_IS_SUBSCRIBED: {
      return {
        ...state,
        isSubscribed: action.payload,
      };
    }

    case actions.SET_ERROR_COUNT: {
      return {
        ...state,
        errorCount: action.payload,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}​​​​​​​​`);
    }
  }
};

const CloudConnectorContextProvider = ({ children }) => {
  const [cloudConnectorState, dispatchToCloudConnectorState] = useReducer<any>(
    reducer,
    defaultCloudConnectorStateContext
  );

  return (
    <CloudConnectorStateContext.Provider value={cloudConnectorState}>
      <CloudConnectorDispatchContext.Provider
        value={dispatchToCloudConnectorState}
      >
        {children}​​​​​​​​
      </CloudConnectorDispatchContext.Provider>
    </CloudConnectorStateContext.Provider>
  );
};

interface StateContext {
  orgsWithCloudConnectors: Array<any>;
  isLoadingCloudConnectorAggregates: Boolean;
  isSubscribed: Boolean;
}

interface DispatchContext {
  dispatchContext: Function;
}

export interface CloudConnectorContextExports {
  stateContext: StateContext;
  dispatchContext?: DispatchContext;
}

export interface CloudConnectorOverrideExports {
  stateContext?: CloudConnectorContextExports['stateContext'];
  dispatchContext?: CloudConnectorContextExports['dispatchContext'];
}

export {
  CloudConnectorStateContext,
  CloudConnectorDispatchContext,
  CloudConnectorContextProvider,
  actions,
  defaultCloudConnectorStateContext,
};
