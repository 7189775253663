import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  WithStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { KeyboardArrowRightIcon } from '@/images/Icons';
import { styles } from '@/components/discovery/UpdateAnswer/styles';
import AnswerForm from '@/components/discovery/UpdateAnswer/AnswerForm';
import { AnsweredQuestion } from '@/types/discovery';

interface Props extends WithStyles<typeof styles> {
  currentAnswer: AnsweredQuestion;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  theme: Theme;
  onUpdateAnswer: (answer: AnsweredQuestion) => void;
}

const UpdateAnswer = ({
  classes,
  currentAnswer,
  isOpen,
  setIsOpen,
  theme,
  onUpdateAnswer,
}: Props) => {
  const breakpointIsLgPlus = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <div className={classes.drawer} data-testid="update-answer">
      <Drawer
        variant={breakpointIsLgPlus ? 'persistent' : 'temporary'}
        open={isOpen}
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                data-testid="component-edit-answer-close-button"
                className={classes.closeButton}
                color="inherit"
                aria-label={`Close Edit Answer`}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.grow}
                align="left"
              >
                Update Answer
              </Typography>
            </Toolbar>
            <Divider />
            <Grid item xs={12} className={classes.editAnswerForm}>
              <AnswerForm
                currentAnswer={currentAnswer}
                onUpdateAnswer={onUpdateAnswer}
              />
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(UpdateAnswer);
