import { ArrowDownwardIcon, ArrowUpwardIcon, SaveAlt } from '@/images/Icons';
import {
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { default as React, useState } from 'react';
import TableComponent, { DataFormatProps } from '@/components/Table';

import DomainDiscoverySummary from '@/components/discovery/Summary';
import PageLoader from '@/components/PageLoader';
import capitalize from '@material-ui/core/utils/capitalize';
import { styles } from '@/screens/DiscoveryResults/styles';
import { useApiData } from '@/hooks/UseApiData';
import { useDomainDiscoveryContext } from '@/hooks/DomainDiscoveryContext';
import { useUnsetOrg } from '@/hooks/OrgContext';
import { useLayoutContext } from '@/App';
import FileSaver from 'file-saver';
import { Confidence, IndicatorType, Result } from '@/types/discovery';

interface Props extends WithStyles<typeof styles> {}

const DiscoveryResults = ({ classes }: Props) => {
  const pageTitle = 'Domain Results';

  const [results, setResults] = useState<Result[]>();
  const [exportList, setExportList] = useState();
  const [menuAnchor, setMenuAnchor] = useState();
  const [menuCsvAnchor, setMenuCsvAnchor] = useState();
  const [highConf, setHighConf] = useState(false);
  const [lowConf, setLowConf] = useState(false);
  const [noConf, setNoConf] = useState(false);
  const [anyExports, setAnyExports] = useState(false);

  const {
    selectedDiscovery,
    setSelectedIndicator,
  } = useDomainDiscoveryContext();
  const { setError } = useLayoutContext();

  const setExportListResults = data => {
    if (!data || data.length === 0) {
      return;
    }
    // these drive whether export options are disabled
    setAnyExports(true);
    setHighConf(!!data.find(i => i.confidence === 'high'));
    setLowConf(!!data.find(i => i.confidence === 'low'));
    setNoConf(!!data.find(i => i.confidence === null));
    setExportList(data);
  };

  useApiData({
    apiType: 'discoveryResults',
    setData: setResults,
    setError,
    sourceDataPopulated: !!selectedDiscovery?.uid,
  });

  useApiData({
    apiType: 'discoveryExport',
    setData: setExportListResults,
    setError,
    sourceDataPopulated: !!selectedDiscovery?.uid,
  });

  // Always unselect org since it doesn't relate to this screen
  useUnsetOrg();

  const handleResultSelected = rowData => {
    if (!rowData || !rowData.domainUid) return;

    setSelectedIndicator({
      uid: rowData.domainUid,
      name: rowData.domainName,
      indicatorType: IndicatorType.domain,
      uidType: 'output',
    });
  };

  const confidenceIcon = (confidence: Confidence) => {
    if (confidence === Confidence.Low) {
      return <ArrowDownwardIcon className={classes.lowConfidence} />;
    }

    if (confidence === Confidence.High) {
      return <ArrowUpwardIcon className={classes.highConfidence} />;
    }

    return <></>;
  };

  const saveJSONFile = (exportedList, filename) => {
    const blob = new Blob([JSON.stringify(exportedList)], {
      type: 'text/plain;charset=utf-8',
    });
    FileSaver.saveAs(blob, filename);
  };

  const saveCSVFile = (exportedList, filename) => {
    const csvList = exportedList.map(exportedList => {
      return exportedList.domain;
    });
    const blob = new Blob([csvList.join('\n')], {
      type: 'text/plain;charset=utf-8',
    });
    FileSaver.saveAs(blob, filename);
  };

  const handleExport = (confidence, format) => {
    setMenuAnchor(null);
    setMenuCsvAnchor(null);
    const cleanName = selectedDiscovery?.name
      ?.toLowerCase()
      .replace(/[^a-z0-9 ]/g, '')
      .replace(/ /g, '-');
    const filename = `discovery-${cleanName}`;

    const filter = confidence === Confidence.None ? null : confidence;
    const filteredExport =
      confidence === 'all'
        ? exportList
        : exportList.filter(i => i.confidence === filter);
    filteredExport.sort((a, b) => a.domain.localeCompare(b.domain));

    if (format === 'json') {
      saveJSONFile(filteredExport, `${filename}-${confidence}.json`);
    } else if (format === 'csv') {
      saveCSVFile(filteredExport, `${filename}-${confidence}.csv`);
    }
  };

  const confidenceIndicators = ({ confidence, acceptedVertices }) => {
    const arr = acceptedVertices
      .filter(v => v.confidenceImpact === confidence)
      .map(v => {
        return JSON.stringify({
          vertexType: v.vertexType,
          vertexName: v.vertexName,
          evidenceType: v.evidenceType,
        });
      });
    const sortedVertices = arr
      .filter((v, i) => arr.indexOf(v) === i)
      .map(v => JSON.parse(v))
      .reduce((acc, v) => {
        if (!acc.get(v.evidenceType))
          acc.set(v.evidenceType, new Array<object>());
        acc.get(v.evidenceType).push(v);
        return acc;
      }, new Map<string, object[]>());

    return (
      <List dense disablePadding>
        {Array.from(sortedVertices.keys()).map(evidenceType => {
          const vertices = sortedVertices.get(evidenceType);
          return (
            <ListItem disableGutters key={evidenceType as string}>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography>
                    {capitalize((evidenceType as string).replace(/-/g, ' '))}
                  </Typography>
                </Grid>
                <Grid item className={classes.vertexChipContainer}>
                  {vertices.map(v => (
                    <Chip
                      key={v.vertexName}
                      size="small"
                      className={
                        confidence === Confidence.High
                          ? classes.vertexChipHighConfidence
                          : classes.vertexChipLowConfidence
                      }
                      variant="outlined"
                      label={
                        <Grid container>
                          {v.vertexName}
                          <Divider
                            orientation="vertical"
                            flexItem
                            className={
                              confidence === Confidence.High
                                ? classes.vertexChipDividerHighConfidence
                                : classes.vertexChipDividerLowConfidence
                            }
                          />
                          {capitalize(v.vertexType)}
                        </Grid>
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const confidenceTooltipTitle = (
    <Tooltip
      arrow
      placement="bottom"
      title={
        <span style={{ fontSize: 12 }}>
          <strong>High Confidence</strong>
          <List dense>
            <ListItem>
              Domain's DNS lookup leads to an approved nameserver
            </ListItem>
            <ListItem>
              Domain's current TLS certificate is minted by an approved TLS Org
            </ListItem>
            <ListItem>Domain's screenshot is approved</ListItem>
          </List>
          <strong>Low Confidence</strong>
          <List dense>
            <ListItem>Domain has other approved evidence</ListItem>
          </List>
        </span>
      }
    >
      <Typography>Confidence</Typography>
    </Tooltip>
  );

  const exportControls = (
    <div>
      <Button
        startIcon={<SaveAlt />}
        aria-controls="export-menu-json"
        aria-haspopup="true"
        data-testid="export-button-json"
        variant="contained"
        color="primary"
        onClick={event => {
          setMenuAnchor(event.currentTarget);
        }}
        aria-label="Export JSON"
      >
        Export JSON
      </Button>
      <Menu
        id="export-menu-json"
        keepMounted
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={_ => {
          setMenuAnchor(null);
        }}
      >
        <MenuItem
          data-testid="all-export-json"
          disabled={!anyExports}
          onClick={_ => {
            handleExport('all', 'json');
          }}
        >
          All Domains
        </MenuItem>
        <MenuItem
          data-testid="high-export-json"
          disabled={!highConf}
          onClick={_ => {
            handleExport(Confidence.High, 'json');
          }}
        >
          High Confidence
        </MenuItem>
        <MenuItem
          data-testid="low-export-json"
          disabled={!lowConf}
          onClick={_ => {
            handleExport(Confidence.Low, 'json');
          }}
        >
          Low Confidence
        </MenuItem>
        <MenuItem
          data-testid="none-export-json"
          disabled={!noConf}
          onClick={_ => {
            handleExport(Confidence.None, 'json');
          }}
        >
          No Confidence
        </MenuItem>
      </Menu>
      <Button
        className={classes.exportButton}
        startIcon={<SaveAlt />}
        aria-controls="export-menu-csv"
        aria-haspopup="true"
        data-testid="export-button-csv"
        variant="contained"
        color="primary"
        onClick={event => {
          setMenuCsvAnchor(event.currentTarget);
        }}
        aria-label="Export CSV"
      >
        Export CSV
      </Button>
      <Menu
        id="export-menu-csv"
        keepMounted
        anchorEl={menuCsvAnchor}
        open={Boolean(menuCsvAnchor)}
        onClose={_ => {
          setMenuCsvAnchor(null);
        }}
      >
        <MenuItem
          data-testid="all-export-csv"
          disabled={!anyExports}
          onClick={_ => {
            handleExport('all', 'csv');
          }}
        >
          All Domains
        </MenuItem>
        <MenuItem
          data-testid="high-export-csv"
          disabled={!highConf}
          onClick={_ => {
            handleExport(Confidence.High, 'csv');
          }}
        >
          High Confidence
        </MenuItem>
        <MenuItem
          data-testid="low-export-csv"
          disabled={!lowConf}
          onClick={_ => {
            handleExport(Confidence.Low, 'csv');
          }}
        >
          Low Confidence
        </MenuItem>
        <MenuItem
          data-testid="none-export-csv"
          disabled={!noConf}
          onClick={_ => {
            handleExport(Confidence.None, 'csv');
          }}
        >
          No Confidence
        </MenuItem>
      </Menu>
    </div>
  );

  const columns: DataFormatProps[] = [
    {
      title: confidenceTooltipTitle,
      field: 'confidence',
      width: 60,
      render: ({ confidence }) => (
        <Grid
          container
          item
          alignContent="center"
          justify="center"
          className={classes.confidenceIcon}
        >
          {confidenceIcon(confidence)}
        </Grid>
      ),
    },
    {
      title: 'Name',
      field: 'domainName',
      width: 120,
      searchable: true,
    },
    {
      title: 'Confidence Indicators',
      field: 'acceptedVertices',
      width: 200,
      render: confidenceIndicators,
      searchable: true,
      sortable: false,
    },
  ];

  if (!results) {
    return <PageLoader pageTitle={pageTitle} />;
  }

  return (
    <>
      <DomainDiscoverySummary pageTitle={pageTitle} />
      <TableComponent
        headerActions={exportControls}
        data={results}
        dataFormat={columns}
        paginationEnabled
        rowsPerPage={25}
        onRowClick={(_e, rowData) => {
          handleResultSelected(rowData);
        }}
        sortEnabled={true}
        defaultSortOrder={{
          orderBy: 'confidence',
          order: 'asc',
          orderId: 0,
        }}
        search={{
          enabled: true,
          title: 'Search by Name',
        }}
        searchFormat={columns}
      />
    </>
  );
};

export default withStyles(styles, { withTheme: true })(DiscoveryResults);
