import {
  PaletteOptions,
  SimplePaletteColorOptions,
} from '@material-ui/core/styles/createPalette';

import { Color } from '@material-ui/core';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { createMuiTheme } from '@material-ui/core/styles';

interface BfpColor extends Color {
  A300: string;
  A500: string;
  A600: string;
}

export type BfpColorPartial = Partial<BfpColor>;

interface BfpPaletteOptions extends PaletteOptions {
  primary: SimplePaletteColorOptions | BfpColorPartial;
  secondary: SimplePaletteColorOptions | BfpColorPartial;
  tertiary: SimplePaletteColorOptions | BfpColorPartial;
}

export const palette: BfpPaletteOptions = {
  primary: {
    main: '#663399',
    50: '#ede7f3',
    100: '#d1c2e0',
    200: '#b399cc',
    300: '#9470b8',
    400: '#7d52a8',
    500: '#663399',
    600: '#5e2e91',
    700: '#532786',
    800: '#49207c',
    900: '#38146b',
    A100: '#F1EBF5',
    A200: '#D5C6E2',
    A300: '#9D7DB9',
    A400: '#73479C',
    A500: '#4B2A71',
    A600: '#2E1846',
    A700: '#0E0B11',
  },
  secondary: {
    main: '#58C1B3',
    50: '#ebf8f6',
    100: '#cdece8',
    200: '#ace0d9',
    300: '#8ad4ca',
    400: '#71cabe',
    500: '#58c1b3',
    600: '#50bbac',
    700: '#47b3a3',
    800: '#3dab9a',
    900: '#2d9e8b',
    A100: '#EFF8F8',
    A200: '#D2ECE8',
    A300: '#95D4CC',
    A400: '#66C6B9',
    A500: '#408D83',
    A600: '#295852',
    A700: '#0C1413',
  },
  tertiary: {
    main: '#6683C1',
    50: '#edf0f8',
    100: '#d1daec',
    200: '#b3c1e0',
    300: '#94a8d4',
    400: '#7d96ca',
    500: '#6683c1',
    600: '#5e7bbb',
    700: '#5370b3',
    800: '#4966ab',
    900: '#38539e',
    A100: '#F1F3FA',
    A200: '#D6DDEE',
    A300: '#9DB1D7',
    A400: '#738EC6',
    A500: '#4A618D',
    A600: '#2F3C58',
    A700: '#0E0F13',
  },
  error: {
    main: '#FFE6E7',
    light: '#FFAE5C',
    lightV2: '#ED6868',
  },
  background: {
    default: '#F7F8F8',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: '#4a4a4a',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
};

export const typography: TypographyOptions = {
  htmlFontSize: 10,
  fontFamily: '"Proxima Nova Regular", Arial, sans-serif',
  fontWeightLight: 'normal',
  fontWeightMedium: 'normal',
  fontWeightRegular: 'normal',
  h1: {
    letterSpacing: -1.5,
    fontFamily: '"Proxima Nova Light", Arial, sans-serif',
    fontSize: 102,
  },
  h2: {
    letterSpacing: -0.5,
    fontSize: 64,
  },
  h3: {
    letterSpacing: 0,
    fontSize: 51,
  },
  h4: {
    letterSpacing: 0.25,
    fontSize: 36,
  },
  h5: {
    letterSpacing: 0,
    fontSize: 26,
  },
  h6: {
    letterSpacing: 0.25,
    fontSize: 21,
  },
  body1: {
    letterSpacing: 0.5,
    fontSize: 17,
  },
  body2: {
    letterSpacing: 0.25,
    fontSize: 15,
  },
  subtitle1: {
    letterSpacing: 0.15,
    fontSize: 17,
  },
  subtitle2: {
    letterSpacing: 0.1,
    fontSize: 15,
  },
  button: {
    letterSpacing: 1.25,
    fontSize: 15,
    textTransform: 'uppercase',
  },
  caption: {
    letterSpacing: 0.4,
    fontSize: 13,
    color: '#525354;',
  },
  overline: {
    letterSpacing: 2,
    lineHeight: 1.25,
    fontSize: 13,
    textTransform: 'uppercase',
  },
};

export default createMuiTheme({
  palette,
  typography,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    // Name of the component ⚛️ / style sheet
    MuiButton: {
      outlined: {
        color: palette.primary && palette.primary['main'],
        '&:hover': {
          backgroundColor: 'rgba(84, 58, 114, .08)',
        },
        '&:focus': {
          backgroundColor: 'rgba(84, 58, 114, .08)',
        },
      },
      containedSizeSmall: {
        paddingTop: 4,
        paddingBottom: 2,
        '&:hover': {
          backgroundColor: palette.primary['A300'],
        },
        '&:focus': {
          backgroundColor: palette.primary['A300'],
        },
      },
      outlinedSizeSmall: {
        paddingTop: 4,
        paddingBottom: 2,
      },
    },
    MuiDrawer: {
      root: {
        // @ts-ignore - need specific override
        zIndex: '1050 !important',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: 4,
      },
    },
    MuiFormHelperText: {
      root: {
        ...typography.caption,
        color: palette.primary && palette.primary['main'],
        '&$error': {
          color: palette.error && palette.error['main'],
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: palette.primary && palette.primary['main'],
      },
    },
    MuiListItem: {
      root: {
        '&$selected, &$selected:hover, &$selected:focus': {
          backgroundColor: 'rgba(84, 58, 114, .14)',
        },
      },
      button: {
        '&:hover': {
          backgroundColor: 'rgba(84, 58, 114, .08)',
        },
        '&:focus': {
          backgroundColor: 'none',
        },
        '&:active': {
          backgroundColor: 'none',
        },
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#FFF',
        color: '#000',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#f5f5f5',
      },
    },
    MuiTableSortLabel: {
      root: {
        fontWeight: 600,
        '&:hover': {
          color: palette.text && palette.text.primary,
          '& $icon': {
            opacity: 1,
            color: palette.text && palette.text.secondary,
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: `4px 24px 4px 24px`,
      },
      body: {
        color: '#4a4a4a',
        fontSize: '17px',
        letterSpacing: 0,
        lineHeight: 1,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 16,
        letterSpacing: 0,
        '&$focused': {
          color: palette.text && palette.text.secondary,
        },
      },
      focused: {},
    },
  },
});
