import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    resultsTable: {
      padding: `0 ${theme.spacing()}px`,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: '100%',
    },
    lowConfidence: {
      color: theme.palette.error.light,
    },
    highConfidence: {
      color: theme.palette.success.main,
    },
    confidenceIcon: {
      padding: theme.spacing(),
      height: '100%',
    },
    exportButton: {
      marginLeft: theme.spacing(),
    },
    vertexChipLowConfidence: {
      borderColor: theme.palette.error.light,
    },
    vertexChipDividerLowConfidence: {
      margin: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
      backgroundColor: theme.palette.error.light,
    },
    vertexChipHighConfidence: {
      borderColor: theme.palette.success.main,
    },
    vertexChipDividerHighConfidence: {
      margin: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
      backgroundColor: theme.palette.success.main,
    },
    vertexChipContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  });
