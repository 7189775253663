import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    errorsTable: {
      padding: `0 ${theme.spacing()}px`,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: '100%',
    },
    statusIcon: {
      padding: theme.spacing(),
      height: '100%',
    },
  });
