import { generatePath } from 'react-router';
import get from 'lodash/get';

export const routeLoggedOut = () => '/';

export const routeLoginCallback = () => '/callback';
export const routeOrgLookup = () => '/orgs';
export const routeValueMetrics = () => '/value-metrics';
export const routeCloudConnectors = () => '/cloud-connectors';
export const routeCloudConnectorDetail = () => '/cloud-connectors/:orgUid';

export const routeDomainDiscovery = () => '/discoveries';
export const routeDomainDiscoveryRedirect = () => '/discoveries/:sub([a-z]+)';
export const routeDomainDiscoverySeeds = () =>
  '/discoveries/seeds/:discoveryId';
export const routeDomainDiscoveryAnswers = () =>
  '/discoveries/answers/:discoveryId';
export const routeDomainDiscoveryParents = () =>
  '/discoveries/parents/:discoveryId';
export const routeDomainDiscoveryQuestions = () =>
  '/discoveries/questions/:discoveryId';
export const routeDomainDiscoveryResults = () =>
  '/discoveries/results/:discoveryId';
export const routeDomainDiscoveryErrors = () =>
  '/discoveries/errors/:discoveryId';
export const routeRules = () => '/discoveries/rules/:discoveryId';

export const routeFeedback = () => '/feedback';
export const routeHomepage = () => '/org/:orgId?';
export const routePrivacyPolicy = () =>
  'https://www.bishopfox.com/privacy-statement/';

export const convertKeyValueToParam = (key, value) => {
  if (!key || !value) return null;
  return `${key}=${value}`;
};

export const buildQueryString = params => {
  const starter = '?';
  const queryString = params.reduce((queryString, param) => {
    if (!param) return queryString;
    if (!queryString) return `${starter}${param}`;
    return `${queryString}&${param}`;
  }, false);
  return queryString || '';
};

export const convertKeyValueListToQueryString = (
  keyValueList,
  keyParam,
  valueParam
) => {
  if (!keyValueList || keyValueList.length <= 0) return '';
  const paramList = keyValueList.map(keyvalue =>
    convertKeyValueToParam(keyvalue[keyParam], keyvalue[valueParam])
  );
  return buildQueryString(paramList);
};

export const generateOrgIdPath = (pathname, orgId?, match?) => {
  if (match) {
    orgId = get(match, 'params.orgId');
  }
  if (!orgId) {
    return pathname.replace(/\/:orgId(\??)$/, '');
  }
  return generatePath(pathname, { orgId });
};

export const generateUserIdPath = (pathname, userId) => {
  if (!userId) {
    return pathname.replace(/\/:orgId(\??)$/, '');
  }
  return generatePath(pathname, { userId });
};

export const generateDiscoveryIdPath = (
  pathname,
  discoveryId?,
  indicatorUid?,
  match?
) => {
  if (match) {
    discoveryId = get(match, 'params.discoveryId');
  }
  if (!discoveryId) {
    return pathname.replace(/\/:discoveryId(\??)$/, '');
  }

  return generatePath(pathname, { discoveryId });
};

export const buildQueryParams = (params: object): string => {
  const query = Object.keys(params)
    .filter(k => !!params[k])
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');

  return query && `?${query}`;
};

export const generatePortalUrl = (orgUid: string) => {
  const isPreview = window.location.host.includes('preview');
  const isDev =
    window.location.host.includes('local') ||
    window.location.host.includes('dev');

  let env = '';
  if (isDev) env = 'dev.';
  if (isPreview) env = 'preview.';

  return `https://${env}portal.bishopfox.com/dashboard/${orgUid}`;
};
