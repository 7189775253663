import { Radio } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import get from 'lodash/get';
import React from 'react';
import { onClickHandler as onClickHandlerGtag } from '@/helpers/AnalyticsHelper';
import { ActiveFilter, FilterClickOptions } from '@/helpers/FilterHelpers';

interface Props extends WithStyles<typeof styles> {
  groupOptions: any;
  groupLabel?: string;
  groupDataKey?: string;
  optionDataKey?: string;
  handleChangeFilters: (
    activeFilter: ActiveFilter,
    options?: FilterClickOptions
  ) => void;
  disable: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    radioGroup: {
      padding: `${theme.spacing()}px 0`,
    },
    radioLabel: {
      marginRight: theme.spacing(0.5),
      display: 'flex',
      justifyContent: 'space-between',
    },
    checkboxLabel: {
      fontSize: 15,
      lineHeight: 3.25,
      letterSpacing: 0.25,
    },
    dividerRoot: {
      '&$dividerInset': {
        marginLeft: theme.spacing(2),
      },
    },
    dividerInset: {},
  });

const ReactDiv = ({ children, ...props }) => children(props); // absorbs inputRef prop (html div can't)

const OptionsRadio = ({
  classes,
  groupOptions,
  groupLabel,
  groupDataKey,
  optionDataKey,
  handleChangeFilters,
  disable,
}: Props) => {
  const handleRadioGroupChange = groupDataKey => event => {
    const optionDataKey = get(event, 'target.value', '');
    handleChangeFilters([{ groupDataKey, optionDataKey }]);
    onClickHandlerGtag({
      event_category: `select_filter`,
      event_label: `${groupDataKey} - ${optionDataKey}`,
    });
  };

  return (
    <RadioGroup
      aria-label={groupLabel}
      name={groupDataKey}
      className={classes.radioGroup}
      value={optionDataKey || false}
      onChange={handleRadioGroupChange(groupDataKey)}
    >
      {groupOptions.map(({ label, dataKey }) => {
        return (
          <ReactDiv key={dataKey} value={dataKey}>
            {props => (
              <>
                <FormControlLabel
                  classes={{
                    label: classes.checkboxLabel,
                  }}
                  className={classes.radioLabel}
                  labelPlacement="start"
                  control={<Radio color="primary" />}
                  label={label}
                  disabled={disable}
                  {...props}
                />
                <Divider
                  classes={{
                    root: classes.dividerRoot,
                    inset: classes.dividerInset,
                  }}
                  variant="inset"
                />
              </>
            )}
          </ReactDiv>
        );
      })}
    </RadioGroup>
  );
};

export default withStyles(styles, { withTheme: true })(OptionsRadio);
