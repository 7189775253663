import { useEffect, useState } from 'react';
import { useLayoutContext } from '@/App';

interface Props {
  otherDrawerOpen?: boolean;
  setOtherDrawerOpen?: (o: boolean) => void;
}

export const useDrawer = ({
  otherDrawerOpen,
  setOtherDrawerOpen,
}: Props = {}): [boolean, (o: boolean) => void] => {
  const [isOpen, setIsOpen] = useState(false);
  const { rightDrawerOpen, setRightDrawerOpen } = useLayoutContext();

  if (otherDrawerOpen && isOpen) setIsOpen(false);

  useEffect(() => {
    if (!rightDrawerOpen && isOpen) setIsOpen(false);
  }, [rightDrawerOpen]);

  const setDrawerOpen = (o: boolean) => {
    if (o && setOtherDrawerOpen) setOtherDrawerOpen(false);
    setRightDrawerOpen(o);
    setIsOpen(o);
  };

  return [isOpen, setDrawerOpen];
};
