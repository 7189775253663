import { EnvVars } from '@/hooks/EnvVars';

interface LocalGtag extends Gtag.Gtag {
  (command: 'js', date: Date): void;
}
interface screenViewHandlerParams extends Gtag.CustomParams {
  screen_name: string;
  page_title?: string;
}

const _dataLayer: Array<any> = window['dataLayer'] || [];

const gtag: LocalGtag = function() {
  _dataLayer.push(arguments);
};

export const APP_NAME = 'Bishop Fox Admin';

export var TRACKING_ID: string = '';

export const init = () => {
  const trackingId = EnvVars['REACT_APP_GA_TRACKING_ID'];
  const scriptId = 'ga-gtag';
  TRACKING_ID = trackingId;

  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement('script');
  script.id = scriptId;
  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  head.insertBefore(script, head.firstChild);

  window['dataLayer'] = _dataLayer;

  gtag('js', new Date());
  gtag('config', trackingId, { app_name: APP_NAME });
};

export const sendEvent = (
  eventName: Gtag.EventNames | string,
  eventParams: Gtag.EventParams | Gtag.CustomParams = {
    event_category: 'general',
  }
) => {
  gtag('event', eventName, eventParams);
};

export const onClickHandler = (eventParams: Gtag.EventParams) => {
  sendEvent('on_click', eventParams);
};

export const screenViewHandler = (
  screenViewParams: screenViewHandlerParams
) => {
  const { page_title } = screenViewParams;
  sendEvent('screen_view', screenViewParams);
  gtag('config', EnvVars['REACT_APP_GA_TRACKING_ID'], {
    page_path: screenViewParams.screen_name,
    ...(page_title && page_title.length
      ? { page_title }
      : { page_title: APP_NAME }),
  });
};

export default gtag;
