import { default as React, useState } from 'react';
import { Button, WithStyles, withStyles } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { styles } from '@/components/discovery/ReactivateButton/styles';

interface Props extends WithStyles<typeof styles> {
  reactivate: (completed: () => void, category?: string) => void;
  category?: string;
}

const ReactivateButton = ({ classes, reactivate, category }: Props) => {
  const [reactivationInProgress, setReactivationInProgress] = useState(false);

  const triggerReactivation = () => {
    setReactivationInProgress(true);
    reactivate(() => {
      setReactivationInProgress(false);
    }, category);
  };

  return (
    <Button
      data-testid="reactivate-button"
      variant="outlined"
      className={classes.root}
      onClick={triggerReactivation}
      aria-label="Reactivate Errors"
      startIcon={<ReplayIcon />}
      disabled={reactivationInProgress}
    >
      Errors
    </Button>
  );
};

export default withStyles(styles)(ReactivateButton);
