declare var __VERSION__: string;

interface IEnvVars {
  [key: string]: string | boolean;
}

export const EnvVars: IEnvVars = {};

export async function loadEnvVars(): Promise<IEnvVars> {
  const envVars = {
    HTTPS: process.env.HTTPS,
    REACT_APP_API_HOST: process.env.REACT_APP_API_HOST,
    REACT_APP_AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
    REACT_APP_AUTH_CLIENT: process.env.REACT_APP_AUTH_CLIENT,
    REACT_APP_AUTH_REDIRECT_URI: process.env.REACT_APP_AUTH_REDIRECT_URI,
    REACT_APP_GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID,
    REACT_APP_VERSION: __VERSION__,
  };

  const transformedEnvVars = Object.keys(envVars).reduce((acc, key) => {
    const currentKey = key;

    // Ensure we have the base without (.admin) included
    if (key === 'REACT_APP_API_HOST') {
      acc[key] = envVars['REACT_APP_API_HOST']?.replace('.admin', '');

      if (!envVars['REACT_APP_LOGAN_HOST']) {
        acc['REACT_APP_LOGAN_HOST'] = acc[key];
      }
    }

    switch (envVars[currentKey]) {
      case 'true':
        acc[currentKey] = true;
        break;
      case 'false':
        acc[currentKey] = false;
        break;
      default:
        acc[currentKey] = envVars[currentKey];
    }

    return acc;
  }, {});

  window['envs'] = transformedEnvVars;
  Object.assign(EnvVars, transformedEnvVars);

  return transformedEnvVars;
}
