import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React, { ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { onClickHandler as onClickHandlerGtag } from '@/helpers/AnalyticsHelper';
import { isSelected } from '@/helpers/NavHelper';
interface Props extends RouteComponentProps {
  label: string;
  icon?: () => ReactElement;
  pathname: string;
  external?: boolean;
  name: string;
  closeDrawer: () => void;
  Chip?: () => ReactElement | null;
  chipProps?: { label: string };
}

const NavPrimaryItem = ({
  label,
  icon,
  pathname,
  external,
  name,
  history,
  location,
  closeDrawer,
  Chip = null,
  chipProps = null,
}: Props) => {
  return (
    <ListItem
      button
      data-testid={`navprimaryitem-${name}`}
      selected={isSelected(pathname, location.pathname)}
      {...(external
        ? {
            component: 'a',
            href: pathname,
            target: '_blank',
            onClick: () => {
              closeDrawer();
              onClickHandlerGtag({
                event_category: `primary_nav`,
                event_label: label,
              });
            },
          }
        : {
            onClick: () => {
              closeDrawer();
              history.push({
                pathname,
              });
              onClickHandlerGtag({
                event_category: `primary_nav`,
                event_label: label,
              });
            },
          })}
    >
      {icon && <ListItemIcon>{icon()}</ListItemIcon>}
      <ListItemText primary={label} {...(icon ? {} : { inset: true })} />
      {Chip && <Chip {...chipProps} />}
    </ListItem>
  );
};

export default withRouter(NavPrimaryItem);
