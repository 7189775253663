export interface Discovery {
  name?: string;
  createdAt?: string;
  ownerEmail?: string;
  public?: boolean;
  uid?: string;
}

export interface Rule {
  index: number;
  uid: string;
  graphUid?: string;
  pattern: string;
  ruleType: string;
  vertexType: string;
  createdAt?: string;
}

export interface AnsweredQuestion extends Indicator {
  index: number;
}

export type Answer = 'approved' | 'denied' | undefined;

export enum Status {
  approved = 'approved',
  denied = 'denied',
  pending = 'pending',
}

export interface Indicator {
  name: string;
  questionType: string;
  uid: string;
  status: Status;
  statusReason?: string;
}

export enum MostQuestions {
  mostQuestions = 'mostQuestions',
}

export enum TypeDisplay {
  domain = 'Domain',
  name = 'Name',
  email = 'Email',
  tlsorganization = 'Organization',
  nameserver = 'Name Server',
  custom = 'Custom',

  screenshot = 'Screenshot',
  mostQuestions = 'Most Questions',
}

export interface QuestionContext {
  uid?: string;
  name?: string;
  indicatorType: IndicatorType | MostQuestions;
  uidType?: string;
}

export interface Detail {
  name: string;
  alias: string;
  originator: string;
  retrievalTime: string;
  evidenceUid: string;
  rank: number;
  data?: SubsidiaryData | object;
}

export interface SubsidiaryData {
  googleSearch: string;
  cite: string;
  parent: string;
  name: string;
  'cite-confidence': string;
  sites: Site[];
}

export interface Site {
  title: string;
  url: string;
  summary: string;
}

export interface DiscoverySummary {
  questionStatus: QuestionStatus;
  processingStatus: ProcessingStatus;
  errorStatus?: number;
  domainStatus: DomainStatus;
}

export interface QuestionStatus {
  new?: number;
  approved?: number;
  denied?: number;
  pending?: number;
  total?: number;
  pending_screenshot?: number;
}

export interface ProcessingStatus {
  total: number;
}

export interface DomainStatus {
  none: number;
  high: number;
  low: number;
}

export interface ReverseLookupResult {
  knownDomains: string[];
  newDomains: string[];
  totalCount: number;
}

export interface ErroredIndicator {
  errors: Map<string, string>;
  name: string;
  questionType: string;
  uid: string;
}

export enum IndicatorType {
  domain = 'domain',
  name = 'name',
  email = 'email',
  tlsorganization = 'tlsorganization',
  nameserver = 'nameserver',
  custom = 'custom',

  screenshot = 'screenshot',
}

export enum SeedType {
  domain = 'domain',
  name = 'name',
  email = 'email',
  tlsorganization = 'tlsorganization',
  nameserver = 'nameserver',
  custom = 'custom',
}

export interface Seed {
  name: string;
  type: SeedType;
}

export interface CustomSeed {
  name: string;
  domains: string[];
  confidence: Confidence;
  rawEvidence: object;
}

export enum Confidence {
  High = 'high',
  Low = 'low',
  None = 'none',
}

export interface AcceptedVertex {
  uid: string;
  evidenceType: string;
  tier: number;
  vertexType: string;
  vertexName: string;
  alias: string;
  confidenceImpact: string;
}

export interface Result {
  domainUid: string;
  domainName: string;
  confidence: Confidence;
  acceptedVertices: AcceptedVertex[];
}
