import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    header: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
    headerLabel: {
      paddingRight: theme.spacing(4),
    },
    questions: {
      padding: `0 ${theme.spacing(2)}px`,
    },
    searchInput: {
      width: '100%',
      maxWidth: '480px',
    },
    filterButton: {
      marginRight: theme.spacing(),
    },
  });
