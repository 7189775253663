import { useEffect, useMemo, useState } from 'react';
import { DataFormatProps, DataRowProps, TableProps } from '@/components/Table';

export type SearchOptions = {
  enabled: boolean;
  paginated?: boolean;
  title: string;
};

export const useSearch = (
  data: TableProps['data'],
  dataFormat: DataFormatProps[],
  enabled: boolean,
  initialSearch?: string,
  paginated?: boolean
) => {
  const [searchTerm, setSearchTerm] = useState(initialSearch || null);

  useEffect(() => {
    if (typeof initialSearch === 'string') setSearchTerm(initialSearch);
  }, [initialSearch]);

  const onRequestSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const searchHandler = e => {
    onRequestSearch(e.target.value);
  };

  const searchMethod = (row: DataRowProps) => {
    if (!dataFormat.length) {
      return row;
    }

    return dataFormat
      .filter(columnDef => columnDef.searchable)
      .some(columnDef => {
        if (columnDef.field) {
          const value = row[columnDef.field];
          if (value) {
            return value
              .toString()
              .toUpperCase()
              .includes(searchTerm?.toUpperCase());
          }
        }
        return false;
      });
  };

  const searchedData = useMemo(() => {
    if (!searchTerm || !enabled || paginated) return data;
    return data?.filter(searchMethod);
  }, [data, searchTerm]);

  return {
    searchHandler,
    searchedData,
    searchTerm,
  };
};
